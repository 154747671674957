import styles from './СalculatorBuilder.module.scss';
import { ActionType } from './СalculatorBuilder';

import { ReactComponent as IcReport } from 'assets/icons/formBuilder/ic_report.svg';
import { ReactComponent as IcEdit } from 'assets/icons/form/ic_edit.svg';
import { ReactComponent as IcDelete } from 'assets/icons/general/ic_close.svg';
import { Checkbox, Radio, Select, TextArea, TextField } from 'components/UI';

const FieldQuestion = ({ field, key, dispatchFields, openEditDialog, openReportDialog }: any) => {
	const { element, title, position, options, label } = field;
	return (
		<li key={key}>
			<div className={styles.headerQuestion}>
				{title}
				<div className={styles.actions}>
					<IcReport className={styles.buttonIcon} onClick={() => openReportDialog(field)} />
					<IcEdit className={styles.buttonIcon} onClick={() => openEditDialog(field)} />
					<IcDelete
						className={styles.buttonIcon}
						onClick={() => {
							dispatchFields({
								type: ActionType.DELETE_FIELD,
								payload: {
									position: position,
								},
							});
						}}
					/>
				</div>
			</div>
			{element === 'TextArea' && <TextArea value={label} disabled={true} />}
			{element === 'Email' && <TextField placeholder='Ivanov@gmail.com' disabled={true} />}
			{element === 'Phone' && <TextField placeholder='+7 (999) 999-99-99' disabled={true} />}
			{element === 'Date' && <TextField placeholder='мм/дд/гггг' disabled={true} />}
			{element === 'Tags' && <TextField placeholder='#тэг1, #тэг2' disabled={true} />}
			{element === 'FileAtachment' && <TextField placeholder='Добавить файл' disabled={true} />}
			{element === 'Select' && (
				<Select
					options={options}
					placeholder='Список вопросов'
					value={{ label: 'Список вопросов', value: 'Список вопросов' }}
					disabled={true}
				/>
			)}
			{element === 'Decrement' && (
				<div className={styles.decrement}>
					<span>-</span>
					<span>1</span>
					<span>+</span>
				</div>
			)}
			<ul className={styles.options}>
				{options?.map((option: any, index: number) => {
					if (element === 'Radios') {
						return (
							<Radio
								key={index}
								label={option.label}
								name={`subjects[${index}].active`}
								onChange={() => console.log('!!!')}
							/>
						);
					}
					if (element === 'Checkboxes') {
						return (
							<Checkbox
								key={index}
								label={option.label}
								name={`subjects[${index}].active`}
								onChange={() => console.log('!!!')}
							/>
						);
					}
				})}
			</ul>
		</li>
	);
};

export default FieldQuestion;
