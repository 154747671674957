import { getParticipantWord } from 'utils/wordFunctions';

export type TInfoModalState = {
	type: 'confirmation' | 'result' | 'hidden' | 'loading';
	count: number;
};

export const getModalContent = (info: TInfoModalState) => {
	if (info.type === 'confirmation')
		return {
			title: 'Произвести рассылку?',
			description: 'Вы уверены что хотите разослать это письмо всем участникам?',
		};

	if (info.type === 'result') {
		if (info.count === 0) {
			return {
				title: 'Неудачная отправка писем',
				description:
					'Письма не удалось отправить.<br/>Возможно это связано с отсутствием пользователей в списке.',
			};
		} else {
			return {
				title: 'Письма отправлены',
				description:
					info.count === 1
						? 'Письмо успешно отправлено 1 участнику.'
						: `Письма успешно отправлены ${info.count} ${getParticipantWord(info.count)}.`,
			};
		}
	}

	return null;
};
