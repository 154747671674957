import ReactDOM from 'react-dom';
import { createContext } from 'react';
import { HttpClient } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import { IRootStore } from 'interfaces/stores/IRootStore';
import App from './App';

process.env.SENTRY_DSN &&
	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [
					'https://admin.rabota-sakhalin.ru',
					/^https:\/\/back\.rabota-sakhalin\.ru/,
				],
			}),
			new Sentry.Replay(),
			new HttpClient(),
		],
		// Fetch Errors
		sendDefaultPii: true,
		ignoreErrors: ['404'],

		// Performance Monitoring
		// Capture 100% of the transactions, reduce in production!
		tracesSampleRate: 0.2,

		// Session Replay
		// This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysSessionSampleRate: 0,
		// If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		replaysOnErrorSampleRate: 1.0,
	});

export const Context = createContext<IRootStore>({} as IRootStore);

ReactDOM.render(<App />, document.getElementById('root'));
