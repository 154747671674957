import { FC } from 'react';
import clsx from 'clsx';

import { TTextHistoryItemProps } from './types';

import styles from './MainItem.module.scss';

const MainItem: FC<TTextHistoryItemProps> = ({ type, item }) => {
	const itemDescription = item[type];

	const itemClasses = clsx(styles.item, {
		[styles.change]: type && item.newValue && item.oldValue && item.oldValue !== item.newValue,
		[styles.remove]: type === 'oldValue' && item.oldValue && !item.newValue,
		[styles.add]: type === 'newValue' && item.newValue && !item.oldValue,
	});

	if (!itemDescription) {
		return null;
	}

	return (
		<li className={type ? itemClasses : styles.item}>
			<li className={styles.item} key={item.title}>
				<span>{item.title}</span>
				<p>{itemDescription}</p>
			</li>
		</li>
	);
};

export default MainItem;
