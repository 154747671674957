import { ListStore } from 'store/general/List/lIstStore';
import { SupportMeasureService } from 'API';
import { ISupportMeasure } from 'interfaces/entities/ISupportMeasure';

class SupportMeasureStore extends ListStore<ISupportMeasure, FormData> {
	constructor() {
		super({
			APIFetchItems: SupportMeasureService.fetchSupportMeasures,
			APIFetchItem: SupportMeasureService.fetchSupportMeasure,
			APICreateItem: SupportMeasureService.createSupportMeasure,
			APIUpdateItem: SupportMeasureService.updateSupportMeasure,
			APIRemoveItem: SupportMeasureService.deleteSupportMeasure,
		});
	}
}

export default SupportMeasureStore;
