import { TableHeader, THeaderField } from 'components/Layouts';

import styles from './AdditionalApplications.module.scss';

const AdditionalApplicationsTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: null, hasSorting: true },
		{ label: 'Email', name: 'email', type: null, hasSorting: true },
		{ label: 'Номер телефона', name: 'phone', type: null, hasSorting: true },
		{ label: 'Дата запроса', name: 'created_at', type: null, hasSorting: true },
	];

	return <TableHeader fields={tableFields} className={styles.row} />;
};

export default AdditionalApplicationsTableHeader;
