import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import SchoolResume from './SchoolResume';
import SchoolResumesTableHeader from './SchoolResumesTableHeader';

import styles from './SchooolResumes.module.scss';

const SchoolResumes: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.schoolResumes}
			title='Анкеты'
			tableHeader={SchoolResumesTableHeader}
			tableClassName={styles.table}
			element={SchoolResume}
			hasDelete={false}
			hasCreate={false}
		/>
	);
});

export default SchoolResumes;
