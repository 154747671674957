import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser';

import { useStore } from 'hooks/useStore';
import { ApprovePage } from 'components/Layouts';
import { Switch } from 'components/UI';

const EmployerUpdate: FC = observer(() => {
	const store = useStore();
	const [isLoading, setLoading] = useState(true);
	const { id } = useParams();
	const item = store.employers.element;

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			if (id !== undefined) {
				await store.employers.fetchItem(id);
			}
			setLoading(false);
		};
		fetchData();
	}, [id]);

	const onChangeApproved = (value: boolean | null) => {
		store.employers.updateEmployerApprovement(item.id.toString(), value);
	};

	const onChangeARCHK = (value: boolean) => {
		store.employers.updateEmployerARCHK(item.id.toString(), value);
	};

	const onChangeKRSO = (value: boolean) => {
		store.employers.updateEmployerKRSO(item.id.toString(), value);
	};

	const onChangeInvestmentProject = (value: boolean) => {
		onChangeKRSO(false);
		store.employers.updateEmployerInvestmentProject(item.id.toString(), value);
	};

	const mainInfo = [
		{ title: 'ID компании', text: item.id },
		{ title: 'Название компании', text: item.employer_name },
		{ title: 'Дата создания', text: item.created_at },
		{ title: 'Дата обновления', text: item.updated_at },
		{ title: 'Сфера', text: item.sphere?.name },
		{ title: 'Офис', text: item.employer_address },
		{
			title: 'Физ. лицо',
			text: item.last_name + ' ' + item.first_name + ' ' + `${item.patronymic_name ?? ''}`,
		},
		{ title: 'Телефонный номер', text: item.phone },
		{ title: 'Email', text: item.email },
		{ title: 'Веб-сайт', text: item.website },
		{ title: 'Инвестиционный проект', text: item.is_investment_project ? 'Да' : 'Нет' },
	];

	const secondaryInfo = [
		{
			header: 'Дополнительно',
			blocks: [
				[
					{
						title: 'О компании',
						text: parse(item.about ?? '') as string,
					},
				],
			],
		},
	];

	const additionalButtons = (
		<>
			<Switch
				checked={item.is_investment_project}
				onChange={(e) => onChangeInvestmentProject(e.target.checked)}
				label='Инвест. проект'
			/>
			{item.is_investment_project && (
				<Switch
					checked={item.is_krso}
					onChange={(e) => onChangeKRSO(e.target.checked)}
					label='КРСО'
				/>
			)}
			<Switch
				checked={item.is_ARCHK_partner}
				onChange={(e) => onChangeARCHK(e.target.checked)}
				label='Партнер АРЧК'
			/>
		</>
	);

	return (
		<ApprovePage
			isLoading={isLoading}
			title={'Компании / Одобрение'}
			mainInfo={mainInfo}
			secondaryInfo={secondaryInfo}
			onUpdate={onChangeApproved}
			currentValue={item.is_approved}
			additionalButtons={additionalButtons}
		/>
	);
});

export default EmployerUpdate;
