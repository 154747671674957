import { THistoryItem } from '../Sections/MainItem/types';

type TItemWithId<T> = T & {
	id: number;
};

type TCash<T> = {
	old: T | null;
	current: T | null;
};

type TCashObject<T> = {
	[key: number]: TCash<T>;
};

export type TSerializeArgs<T> = {
	old: T | null;
	current: T | null;
};

// собирает элементы с одним id и закидывает в callback
export function serializeSecondaryItems<T>(
	oldItems: TItemWithId<T>[],
	currentItems: TItemWithId<T>[],
	callback: (args: TSerializeArgs<T>) => THistoryItem[],
): THistoryItem[][] {
	const cash: TCashObject<TItemWithId<T>> = {};

	oldItems.forEach((item) => {
		cash[item.id] = {
			old: item,
			current: null,
		};
	});

	currentItems.forEach((item) => {
		if (cash[item.id] && cash[item.id].old) {
			cash[item.id].current = item;
		} else {
			cash[item.id] = {
				old: null,
				current: item,
			};
		}
	});

	return Object.values(cash).map(callback);
}
