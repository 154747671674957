import { isApprovedOptions, TableHeader, THeaderField } from 'components/Layouts';

import styles from './Employers.module.scss';

const EmployersTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Название компании', name: 'employer_name', type: 'textField', hasSorting: true },
		{ label: 'Одобрена', name: 'is_approved', type: 'select', options: isApprovedOptions, hasSorting: true },
		{ label: 'Сфера', name: 'sphere_name', type: 'textField', hasSorting: true },
		{ label: 'Дата обновления', name: '', type: null },
		{ label: '', name: '', type: null },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default EmployersTableHeader;
