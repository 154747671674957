import { TPracticeStudent } from 'interfaces/entities/IPracticeStudent';

export interface PracticeStudentProps {
	item: TPracticeStudent;
	isSelected: boolean;
	selectElement: (id: number) => void;
}


export const professionalEducationTypes = [
	{
		label: 'Среднее профессиональное образование',
		value: 'Среднее профессиональное образование'
	},
	{
		label: 'Высшее профессиональное образование',
		value: 'Высшее профессиональное образование'
	}
];

export const Courses = [
	{
		label: '1',
		value: '1'
	},
	{
		label: '2',
		value: '2'
	},
	{
		label: '3',
		value: '3'
	},
	{
		label: '4',
		value: '4'
	},
	{
		label: '5',
		value: '5'
	}
];

export const practiceTypes = [
	{
		label: 'Учебная практика',
		value: 'Учебная практика'
	},
	{
		label: 'Производственная практика',
		value: 'Производственная практика'
	},
	{
		label: 'Преддипломная практика',
		value: 'Преддипломная практика'
	}
];
