import { IStatusCardRead } from 'interfaces/entities/IStatusCard';

export interface StatusCardProps {
	item: IStatusCardRead;
	openDeleteModal: (id: number) => void;
}

export const tabs = [
	{
		value: 'Услуги',
		label: 'Услуги',
	},
	{
		value: 'Успех',
		label: 'Успех',
	},
];
