/* eslint-disable */
import makeRequest from 'API/makeRequest';

class QuestionService {
	async fetchQuestions(id: string | number) {
		const response = await makeRequest<any>({
			url: `statuses/${id}/questions `,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.questions,
			meta: response.data.meta,
		};
	}

	async fetchQuestionsCalculators(id: string | number) {
		const response = await makeRequest<any>({
			url: `calculators/${id}/questions `,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.questions,
			meta: response.data.meta,
		};
	}

	async fetchConditions(id: string | number) {
		const response = await makeRequest<any>({
			url: `calculators/${id}/answer_ways`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			data: response.data,
		};
	}

	async deleteCondition(calculatorId: any, id: number) {
		const response = await makeRequest<any>({
			url: `calculators/${calculatorId}/answer_ways/${id}`,
			method: 'delete',
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		// return {
		//   responseTemplate: response.data.status_test_response_template,
		// };
	}

	async postConditions(items: any, id: number) {
		const response = await makeRequest<any>({
			url: `calculators/${id}/answer_ways`,
			method: 'post',
			auth: true,
			data: items,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.questions,
		};
	}

	async updateConditions(items: any, id: number, conditionId: any) {
		const response = await makeRequest<any>({
			url: `calculators/${id}/answer_ways/${conditionId}`,
			method: 'put',
			auth: true,
			data: items,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.questions,
		};
	}

	async createQuestions(items: any, id: number) {
		const response = await makeRequest<any>({
			url: `statuses/${id}/questions`,
			method: 'post',
			auth: true,
			data: items,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.questions,
		};
	}

	async createQuestionsCalculators(items: any, id: number) {
		const response = await makeRequest<any>({
			url: `calculators/${id}/questions`,
			method: 'post',
			auth: true,
			data: items,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.questions,
		};
	}

	async fetchStatuses() {
		const response = await makeRequest<any>({
			url: 'form_builder/statuses',
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			statuses: response.data.statuses,
		};
	}

	async fetchPagesList() {
		const response = await makeRequest<any>({
			url: 'calculators',
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			calculators: response.data.calculators,
		};
	}

	async createPage(data: any) {
		const response = await makeRequest<any>({
			url: `calculators`,
			method: 'post',
			auth: true,
			data: data,
		});

		if ('errors' in response) {
			throw response;
		}

		// return {
		//   responseTemplate: response.data.status_test_response_template,
		// };
	}

	async deletePage(id: number) {
		const response = await makeRequest<any>({
			url: `calculators/${id}`,
			method: 'delete',
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		// return {
		//   responseTemplate: response.data.status_test_response_template,
		// };
	}

	async fetchSettingsForm(id: number) {
		const response = await makeRequest<any>({
			url: `statuses/${id}/response_templates`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			responseTemplate: response.data.status_test_response_template,
		};
	}

	async postSettingsForm(data: any) {
		const response = await makeRequest<any>({
			url: `statuses/${data.id}/response_templates`,
			method: 'post',
			auth: true,
			data: data.data,
		});

		if ('errors' in response) {
			throw response;
		}

		// return {
		//   responseTemplate: response.data.status_test_response_template,
		// };
	}
}

export default new QuestionService();
