import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/UI';

import { IAdviceCardProps } from './types';

import { ReactComponent as IcDelete } from 'assets/icons/general/ic_trash.svg';
import { ReactComponent as IcPlug } from 'assets/icons/general/ic_card-plug.svg';
import styles from './AdviceCard.module.scss';

const SupportMeasureCard: FC<IAdviceCardProps> = ({ item, openDeleteModal }) => {
	const navigate = useNavigate();

	const onLinkToUpdate = () => {
		navigate(`${item.id}/update`);
	};

	return (
		<li className={styles.item}>
			<div className={styles.imageWrapper}>
				{item.image_url ? (
					<img
						src={`${process.env.REACT_APP_BACKEND_URL}/${item.image_url}`}
						alt='image'
						className={styles.image}
					/>
				) : (
					<IcPlug className={styles.imagePlug} />
				)}
			</div>

			<span className={styles.title}>{item.name}</span>
			<p className={styles.description}>{item.description}</p>

			<div className={styles.btns}>
				<Button theme='filled' onClick={onLinkToUpdate} size='small' className={styles.edit}>
					Редактировать
				</Button>
				<Button
					theme='danger'
					onClick={() => openDeleteModal(item.id)}
					size='small'
					className={styles.delete}
				>
					<IcDelete />
				</Button>
			</div>
		</li>
	);
};

export default SupportMeasureCard;
