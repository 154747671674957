import React, {FC, useEffect, useState} from 'react';
import { useFormik } from 'formik';

import { useStore } from 'hooks/useStore';
import { Button, TextField, Validation } from 'components/UI';
import { AsyncButton, File } from 'components/Form';

import { DocumentFormProps } from './types';
import { APIError } from 'interfaces/api/IAPI';
import { GetUserDocumentResponse} from 'interfaces/api/UserResponse';
import { globalFormikConfig } from 'config/globalFormikConfig';

import styles from './Documents.module.scss';

const DocumentForm: FC<DocumentFormProps> = ({ userId, document, type, onClose }) => {
	const store = useStore();
	const usersStore = store.users;

	const [isRequesting, setIsRequesting] = useState(false);

	const formik = useFormik({
		...globalFormikConfig,
		initialValues: {
			name: document?.name ?? '',
			url: document?.url ?? '',
			document: {} as File,
		},

		enableReinitialize: true,

		onSubmit: async (values) => {
			const formData = new FormData();
			formData.append('name', values.name);
			formData.append('type', type);

			if (values.document && typeof values.document.name === 'string') {
				formData.append('document', values.document);
			} else if (document) {
				formData.append('has_file', values.url ? '1' : '0');
			}

			let response: APIError | GetUserDocumentResponse;

			setIsRequesting(true);
			if (document) {
				response = await usersStore.updateDocument(userId, document.id.toString(), formData);
				console.log(await usersStore.updateDocument(userId, document.id.toString(), formData));
				setIsRequesting(false);
			} else {
				response = await usersStore.createDocument(userId, formData);
				setIsRequesting(false);
			}

			if ('errors' in response) {
				formik.setErrors(response.errors);
			} else {
				onClose();
			}
		},
	});

	useEffect(()=> {
		console.log(isRequesting);
	},[isRequesting]);

	const uploadFile = (file: File) => {
		formik.setFieldValue('document', file);
	};

	const onDeleteFile = () => {
		formik.setFieldValue('document', null);
		formik.setFieldValue('url', '');
	};

	return (
		<form onSubmit={formik.handleSubmit} className={styles.form}>
			{' '}
			<div className={styles.field}>
				<TextField
					name='name'
					label='Название файла'
					onChange={formik.handleChange}
					value={formik.values.name}
				/>
				<Validation touched={formik.touched.name} errors={formik.errors.name} />
			</div>
			<div className={styles.field}>
				<File
					wrapperClassname={styles.field}
					url={formik.values.url}
					onChange={uploadFile}
					onDelete={onDeleteFile}
					extensions={/./i}
				/>
				<Validation touched={formik.touched.document} errors={formik.errors.document} />
			</div>
			<div className={styles.btns}>
				<AsyncButton isLoading={isRequesting} type='submit' size='small'>
					Сохранить
				</AsyncButton>
				<Button theme='dark' onClick={onClose} size='small' className={styles.cancel}>
					Отменить
				</Button>
			</div>
		</form>
	);
};

export default DocumentForm;
