import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import ListPage from 'components/Layouts/ListPage/ListPage';
import AnswersTableHeader from './AnswersTableHeader';
import Answer from './Answer';

import styles from './Answers.module.scss';

const IApplicantAnswers: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.answers}
			title='Ответы запросов'
			tableHeader={AnswersTableHeader}
			tableClassName={styles.table}
			element={Answer}
			hasDelete={false}
			hasCreate={false}
		/>
	);
});

export default IApplicantAnswers;
