import Loader from 'components/UI/Loader/Loader';
import { FC } from 'react';

import styles from './LoaderPage.module.scss';

const LoaderPage: FC = () => {
	return (
		<section className={styles.loaderPage}>
			<Loader />
		</section>
	);
};

export default LoaderPage;
