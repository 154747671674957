import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { LinkBtn, Loader } from 'components/UI';
import { PageHeader } from 'components/Layouts';
import AsideInfo from './AsideInfo';
import DocumentsBlock from './DocumentsBlock';

import { TDocumentsConfig} from './types';

import styles from './Documents.module.scss';

const Documents: FC = observer(() => {
	const store = useStore();
	const usersStore = store.users;
	const { id: userId } = useParams();
	const item = store.users.element;
	const isApplicant = store.users.element.role === 'applicant';

	if (usersStore.fetchItem === undefined) return null;
	if (!userId) return null;

	useEffect(() => {
		usersStore.fetchItem(userId);
		usersStore.fetchDocuments(userId);
		}, [userId]);

	const backLink = -1 as unknown as string;

	const config: TDocumentsConfig = {
		employer: [
			{
				type: 'Соискатели',
				title: 'Соискатели',
				documents: usersStore.applicantsList,
				textCreateButton: 'Добавить файл',
				maxNumberElements: 100,
				isHide: false,
			}
		],
		applicant: [
			{
				type: 'Карьерный план',
				title: 'Карьерный план',
				documents: usersStore.careerPlansList,
				textCreateButton: 'Добавить план',
				maxNumberElements: 1,
				isHide: false,
			},
			{
				type: 'Повышение квалификации',
				title: 'Повышение квалификации',
				documents: usersStore.trainingsList,
				textCreateButton: 'Добавить квалификацию',
				maxNumberElements: null,
				isHide: false,
			},
			{
				type: 'Сертификат',
				title: 'Сертификаты',
				documents: usersStore.certificatesList,
				textCreateButton: 'Добавить сертификат',
				maxNumberElements: null,
				isHide: usersStore.applicantProfileStatus !== 'Школьник',
			},
		],
	};

	if (usersStore.isLoading) {
		return (
			<section className={styles.documents}>
				<PageHeader title={'Пользователи / Документы'} backLink={backLink} />
				<Loader />
			</section>
		);
	}

	return (
		<section className={styles.documents}>
			<PageHeader title={'Пользователи / Документы'} backLink={backLink} />

			<div className={styles.container}>
				<div className={styles.left}>
					{item.role && config[item.role].map((block) => (
						<DocumentsBlock userId={userId} config={block} key={block.type} />
					))}
				</div>

				<AsideInfo isApplicant={isApplicant} id={item.id} user={item} />
			</div>

			<div className={styles.btns}>
				<LinkBtn to={backLink} theme='filled'>
					Закрыть
				</LinkBtn>
			</div>
		</section>
	);
});

export default Documents;
