import { isApprovedOptions, TableHeader, THeaderField } from 'components/Layouts';

import styles from './Courses.module.scss';

const CoursesTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Название курса', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Компания', name: 'employer_name', type: 'textField', hasSorting: true },
		{ label: 'Одобрена', name: 'is_approved', type: 'select', options: isApprovedOptions, hasSorting: true },
		{ label: 'Дата обновления', name: '', type: null },
		{ label: '', name: '', type: null },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default CoursesTableHeader;
