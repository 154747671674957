import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import Speciality from './Speciality';
import SpecialitiesTableHeader from './SpecialitiesTableHeader';

import styles from './Specialities.module.scss';

const Specialities: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.specialities}
			title='Специальности'
			tableHeader={SpecialitiesTableHeader}
			tableClassName={styles.table}
			element={Speciality}
		/>
	);
});

export default Specialities;
