import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import PersonalQualitiesGroup from './PersonalQuality';
import PersonalQualitiesTableHeader from './PersonalQualitiesTableHeader';

import styles from './PersonalQualities.module.scss';

const PersonalQualities: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.personalQualities}
			title='Личностные качества'
			tableHeader={PersonalQualitiesTableHeader}
			tableClassName={styles.table}
			element={PersonalQualitiesGroup}
			hasDelete={false}
		/>
	);
});

export default PersonalQualities;
