import { FC, useState } from 'react';

import CareerPracticeQrForm from './CareerPracticeQrForm';

import { DocumentItemProps } from './types';

import { ReactComponent as IcClose } from 'assets/icons/general/ic_close.svg';
import { ReactComponent as IcEdit } from 'assets/icons/form/ic_edit.svg';
import styles from './CareerPracticeQrs.module.scss';

const CareerPracticeQrItem: FC<DocumentItemProps> = ({ qr, onDelete }) => {
	const [isEditing, setIsEditing] = useState(false);

	const onEdit = () => setIsEditing(true);
	const onFinish = () => setIsEditing(false);

	if (isEditing) {
		return <CareerPracticeQrForm qr={qr} onClose={onFinish} />;
	}
	return (
		<li className={styles.item}>
			<span className={styles.title}>{qr.link}</span>

			<div className={styles.btns}>
				{!isEditing && (
					<button onClick={onEdit}>
						<IcEdit />
					</button>
				)}
				<button onClick={onDelete}>
					<IcClose />
				</button>
			</div>
		</li>
	);
};

export default CareerPracticeQrItem;
