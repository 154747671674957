import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { SingleValue } from 'react-select';

import { useStore } from 'hooks/useStore';
import { Button, Loader, Select, TextField, TextFieldWithPrefix, Validation } from 'components/UI';
import { AsyncButton, File } from 'components/Form';
import { PageHeader } from 'components/Layouts';

import { StatusCardService } from 'API';
import { tabs } from './types';

import { TStatus } from 'interfaces/entities/IStatusCard';
import { IOption } from 'components/UI/Select/types';

import { ReactComponent as IcClose } from 'assets/icons/general/ic_close.svg';
import styles from './StatusCard.module.scss';
import { globalFormikConfig } from 'config/globalFormikConfig';

const StatusCardCreate: FC = observer(() => {
	const store = useStore();
	const statusCardsStore = store.statusCards;

	const [isRequesting, setIsRequesting] = useState(false);
	const [statuses, setStatuses] = useState([] as TStatus[]);

	const location = useLocation();
	const navigate = useNavigate();

	const backLink = location.pathname.slice(0, -7);

	const formik = useFormik({
		...globalFormikConfig,
		initialValues: {
			status_ids: [] as SingleValue<{ value: number; label: string }>[],
			name: '',
			description: '',
			link: '',
			image: {} as File,
			tab_names: [] as IOption[],
		},
		onSubmit: async (values) => {
			const formData = new FormData();

			values.status_ids.forEach((status) =>
				formData.append('status_ids[]', status!.value.toString()),
			);
			values.tab_names.forEach((tab) => formData.append('tab_names[]', tab.value as string));
			formData.append('name', values.name);
			formData.append('description', values.description);
			formData.append('link', values.link);

			if (values.image && typeof values.image.name === 'string') {
				formData.append('image', values.image);
			}

			setIsRequesting(true);
			const response = await statusCardsStore.createItem(formData);
			setIsRequesting(false);

			if ('data' in response) {
				navigate(backLink);
			} else {
				formik.setErrors(response.errors);
			}
		},
	});

	const onStatusSelectChange = (event: IOption) => {
		const newStatuses = [event, ...formik.values.status_ids];
		formik.setFieldValue('status_ids', newStatuses);
	};

	const onTabSelectChange = (event: IOption) => {
		const newTabs = [event, ...formik.values.tab_names];
		formik.setFieldValue('tab_names', newTabs);
	};

	const onStatusDelete = (statusId: number) => {
		const newStatuses = formik.values.status_ids.filter((item) => item!.value !== statusId);
		formik.setFieldValue('status_ids', newStatuses);
	};

	const onTabDelete = (tabName: string) => {
		const newTabs = formik.values.tab_names.filter((item) => item!.value !== tabName);
		formik.setFieldValue('tab_names', newTabs);
	};

	const statusesList = statuses
		.filter(
			(statusItem) => formik.values.status_ids.findIndex((s) => s!.value === statusItem.id) === -1,
		)
		.map((statusItem) => {
			return { value: statusItem.id, label: statusItem.name };
		});

	const tabsList = tabs.filter(
		(tabItem) => formik.values.tab_names.findIndex((t) => t.value === tabItem.value) === -1,
	);

	const getStatuses = async () => {
		const response = await StatusCardService.fetchStatuses();
		if ('data' in response) {
			setStatuses(response.data.statuses);
		}
	};

	useEffect(() => {
		getStatuses();
	}, []);

	const uploadFile = (file: File) => {
		formik.setFieldValue('image', file);
	};

	const onDeleteFile = () => {
		formik.setFieldValue('image', null);
	};

	if (statusCardsStore.isLoading) {
		return (
			<section className={styles.section}>
				<PageHeader title={'Карточки услуг / Создание'} backLink={backLink} />

				<Loader />
			</section>
		);
	}

	return (
		<section className={styles.statusCards}>
			<PageHeader title={'Карточки услуг / Создание'} backLink={backLink} />

			<form onSubmit={formik.handleSubmit} className={styles.form}>
				<div className={styles.wrapper}>
					<ul className={styles.fields}>
						<li className={styles.field}>
							<Select
								options={statusesList}
								onChange={(option: IOption | null) => onStatusSelectChange(option as IOption)}
								placeholder='Студент'
								label='Роль'
							/>
							{formik.values.status_ids.length !== 0 && (
								<ul className={styles.chips}>
									{formik.values.status_ids.map((item) => (
										<li key={item!.value} className={styles.chip}>
											{item!.label}
											<IcClose onClick={() => onStatusDelete(item!.value as number)} />
										</li>
									))}
								</ul>
							)}
							<Validation touched={formik.touched.status_ids} errors={formik.errors.status_ids} />
						</li>

						<li className={styles.field}>
							<Select
								options={tabsList}
								onChange={(option: IOption | null) => onTabSelectChange(option as IOption)}
								placeholder='Услуги'
								label='Раздел'
							/>
							{formik.values.tab_names.length !== 0 && (
								<ul className={styles.chips}>
									{formik.values.tab_names.map((item, index) => (
										<li key={index} className={styles.chip}>
											{item.label} <IcClose onClick={() => onTabDelete(item.value as string)} />
										</li>
									))}
								</ul>
							)}
							<Validation touched={formik.touched.tab_names} errors={formik.errors.tab_names} />
						</li>

						<li className={styles.field}>
							<File label='Картинка' url='' onChange={uploadFile} onDelete={onDeleteFile} />
							<Validation touched={formik.touched.image} errors={formik.errors.image} />
						</li>

						<li className={styles.field}>
							<TextField
								name='name'
								label='Заголовок'
								onChange={formik.handleChange}
								value={formik.values.name}
								maxLength={35}
							/>
							<Validation touched={formik.touched.name} errors={formik.errors.name} />
						</li>

						<li className={styles.field}>
							<TextField
								name='description'
								label='Текст'
								onChange={formik.handleChange}
								value={formik.values.description}
								maxLength={100}
							/>
							<Validation touched={formik.touched.description} errors={formik.errors.description} />
						</li>

						<li className={styles.field}>
							<TextFieldWithPrefix
								name='link'
								label='Ссылка'
								prefix='https://'
								value={formik.values.link}
								onChange={(value) => formik.setFieldValue('link', value)}
							/>

							<Validation touched={formik.touched.link} errors={formik.errors.link} />
						</li>
					</ul>
				</div>

				<div className={styles.btns}>
					<AsyncButton isLoading={isRequesting} type='submit'>
						Создать
					</AsyncButton>
					<Link to={backLink}>
						<Button theme='outlined'>Отмена</Button>
					</Link>
				</div>
			</form>
		</section>
	);
});

export default StatusCardCreate;
