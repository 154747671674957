import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ContextProvider } from 'components/Logic/ContextProvider/ContextProvider';
import Routes from 'routes/Routes';

import 'assets/styles/root.scss';
import 'assets/fonts/fonts.scss';
import 'assets/styles/normalize.scss';
import 'assets/styles/main.scss';

const App: FC = observer(() => {
	return (
		<main>
			<ContextProvider>
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</ContextProvider>
		</main>
	);
});

export default App;
