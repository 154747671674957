import { TableHeader, THeaderField } from 'components/Layouts';

import styles from './PersonalQualities.module.scss';

const PersonalQualitiesTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Название качеств', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Состояние', name: '', type: null },
		{ label: '', name: '', type: null },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default PersonalQualitiesTableHeader;
