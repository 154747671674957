import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import ListPage from 'components/Layouts/ListPage/ListPage';
import UsersTableHeader from './UsersTableHeader';
import User from './User';

import styles from './Users.module.scss';

const Users: FC = observer(() => {
	const store = useStore();

	const exportConfig = {
		withSearchParams: false,
		list: [
			{
				path: 'applicant_profiles',
				fileWord: 'Пользователи(Соискатели)',
				label: 'Соискатели',
			},
			{
				path: 'employee_profiles',
				fileWord: 'Пользователи(Работодатели)',
				label: 'Работодатели',
			},
		],
	};

	return (
		<ListPage
			store={store.users}
			title='Пользователи'
			tableHeader={UsersTableHeader}
			tableClassName={styles.table}
			element={User}
			hasDelete={false}
			hasCreate={false}
			exportConfig={exportConfig}
		/>
	);
});

export default Users;
