import { FC } from 'react';

import { ChildTableHeaderProps, TableHeader, THeaderField } from 'components/Layouts';

import styles from './Articles.module.scss';

const ArticlesTableHeader: FC<ChildTableHeaderProps> = ({ isAllSelected, selectAllElements }) => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Название', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Расположение', name: 'type', type: 'textField' },
		{ label: '', name: '', type: null },
	];

	return (
		<TableHeader
			className={styles.row}
			fields={tableFields}
			isAllSelected={isAllSelected}
			selectAllElements={selectAllElements}
		/>
	);
};

export default ArticlesTableHeader;
