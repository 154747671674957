import { PracticeStudentService } from 'API';
import { TPracticeStudent, TPracticeStudentCreate } from 'interfaces/entities/IPracticeStudent';
import { ListStore } from 'store/general/List/lIstStore';

class PracticeStudentsStore extends ListStore<TPracticeStudent, TPracticeStudentCreate> {
	constructor() {
		super({
			APIFetchItems: PracticeStudentService.fetchPracticeStudents,
			APIFetchItem: PracticeStudentService.fetchPracticeStudent,
			APICreateItem: PracticeStudentService.createPracticeStudent,
			APIUpdateItem: PracticeStudentService.updatePracticeStudent,
			APIRemoveItem: PracticeStudentService.deletePracticeStudent,
		});
	}
}

export default PracticeStudentsStore;
