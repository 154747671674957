import { ChangeEvent, FC } from 'react';
import clsx from 'clsx';

import { TextField } from 'components/UI';

import { TextFieldWithPrefixProps } from './types';

import styles from './TextFieldWithPrefix.module.scss';

const TextFieldWithPrefix: FC<TextFieldWithPrefixProps> = ({
	id,
	value,
	onChange,
	label,
	prefix,
	className,
	...props
}) => {
	const transformValue = (inputValue: string) => {
		if (inputValue.startsWith(prefix + prefix)) return inputValue.slice(prefix.length * 2); // удаление prefix при вставке, если поле не пустое
		else if (inputValue.startsWith(prefix)) return inputValue.slice(prefix.length); // удалаление prefix, если поле пустое
		else return inputValue;
	};

	const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
		const transformedValue = transformValue(e.target.value.trim());

		if (!transformedValue) onChange('');
		else onChange(`${prefix}${transformedValue}`);
	};

	const transformedValue = typeof value === 'string' ? transformValue(value) : '';

	const inputClasses = clsx(styles.input, {
		[className ?? '']: !!className,
	});

	return (
		<div className={styles.wrapper}>
			{label && (
				<label htmlFor={id} className={styles.label}>
					{label}
				</label>
			)}

			<div className={styles.inputWrapper}>
				<div className={styles.prefixWrapper}>
					<span className={styles.prefix}>{prefix}</span>
				</div>

				<TextField
					id={id}
					value={transformedValue}
					onChange={onChangeValue}
					className={inputClasses}
					{...props}
				/>
			</div>
		</div>
	);
};

export default TextFieldWithPrefix;
