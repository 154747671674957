import { MailDistributionService } from 'API';
import {
	IMailDistributionCreate,
	IMailDistributionRead,
} from 'interfaces/entities/IMailDistribution';
import { ListStore } from 'store/general/List/lIstStore';

class MailDistributionStore extends ListStore<IMailDistributionRead, IMailDistributionCreate> {
	constructor() {
		super({
			APIFetchItems: MailDistributionService.fetchMailDistributions,
			APIFetchItem: MailDistributionService.fetchMailDistribution,
			APICreateItem: MailDistributionService.createMailDistribution,
			APIUpdateItem: MailDistributionService.updateMailDistribution,
			APIRemoveItem: MailDistributionService.deleteMailDistribution,
		});
	}

	async distributeToEmails(id: number) {
		return await MailDistributionService.distributeToEmails(id);
	}
}

export default MailDistributionStore;
