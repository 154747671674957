import {
	ArchiveSlot,
	isApprovedOptions,
	TableHeader,
	THeaderAdditionalSlot,
	THeaderField,
} from 'components/Layouts';

import styles from './Vacancies.module.scss';

const ResumesTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Название вакансии', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Компания', name: 'employer_name', type: 'textField', hasSorting: true },
		{ label: 'Одобрена', name: 'is_approved', type: 'select', options: isApprovedOptions, hasSorting: true },
		{ label: 'Дата обновления', name: '', type: null },
		{ label: '', name: '', type: null },
	];

	const additionalSlots: THeaderAdditionalSlot[] = [
		{
			columnName: 'is_approved',
			node: <ArchiveSlot />,
		},
	];

	return (
		<TableHeader fields={tableFields} additionalSlots={additionalSlots} className={styles.row} />
	);
};

export default ResumesTableHeader;
