import { SchoolUserService } from 'API';

import { ListStore } from 'store/general/List/lIstStore';
import { IUserRead } from 'interfaces/entities/IUser';

class SchoolUserStore extends ListStore<IUserRead, void> {
	constructor() {
		super({
			APIFetchItems: SchoolUserService.fetchUsers,
		});
	}
}

export default SchoolUserStore;
