import { PersonalQualityService } from 'API';
import { IPersonalQualityRead, IPersonalQualityCreate } from 'interfaces/entities/IPersonalQuality';
import { ListStore } from 'store/general/List/lIstStore';

class PersonalQualityStore extends ListStore<IPersonalQualityRead, IPersonalQualityCreate> {
	constructor() {
		super({
			APIFetchItems: PersonalQualityService.fetchPersonalQualities,
			APIFetchItem: PersonalQualityService.fetchPersonalQuality,
			APICreateItem: PersonalQualityService.createPersonalQuality,
			APIUpdateItem: PersonalQualityService.updatePersonalQuality,
			APIRemoveItem: PersonalQualityService.deletePersonalQuality,
		});
	}
}

export default PersonalQualityStore;
