import { Select } from 'components/UI';

import { IOption } from 'components/UI/Select/types';

import { ActionType } from '../СalculatorBuilder';

import { ReactComponent as IcTrash } from 'assets/icons/general/ic_trash.svg';
import { ReactComponent as IcDelete } from 'assets/icons/general/ic_close.svg';
import styles from '../СalculatorBuilder.module.scss';

const Question = ({ fields, dispatchField, question, isOperator }: any) => {
	const fieldsSelect = fields.filter(
		(field: any) => field.element === 'Select' || field.element === 'Checkboxes',
	);
	const formatResult = fieldsSelect.map(
		(field: { title: string; position: number; options: any; element: string }) => {
			return {
				label: field.title,
				value: field.position,
				questionOptions: field.options,
				element: field.element,
			};
		},
	);

	const operators = [
		{
			value: 1,
			label: 'ИЛИ',
		},
		{
			value: 2,
			label: 'И',
		},
	];

	const operator = [
		{
			value: 1,
			label: 'ИЛИ',
		},
	];

	return (
		<>
			{isOperator && (
				<div className={styles.wrapperField} style={{ margin: '20px 0' }}>
					<Select
						label='Условие между вопросами'
						options={operators}
						onChange={(option: IOption | null) => {
							dispatchField({
								type: ActionType.EDIT_QUESTION_OPERATOR,
								payload: {
									operatorLabel: option?.label,
									position: question.position,
									index: question.index,
								},
							});
						}}
						placeholder='Выберете вопрос'
						className={styles.select}
						value={{
							label: question.operatorLabel || 'Выберете условие',
							value: question.operatorLabel || 'Выберете условие',
						}}
					/>
				</div>
			)}
			<div className={styles.wrapperField} style={{ marginTop: '20px' }}>
				<Select
					label='Вопрос'
					options={formatResult}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					onChange={({
						label,
						value,
						questionOptions,
						element,
					}: {
						label: string;
						value: number;
						questionOptions: any;
						element: string;
					}) => {
						dispatchField({
							type: ActionType.EDIT_QUESTION,
							payload: {
								label,
								questionOptions,
								position: value,
								index: question.index,
								element,
							},
						});
					}}
					placeholder='Выберете вопрос'
					className={styles.select}
					value={{
						label: question.label || 'Выберете вопрос',
						value: question.label || 'Выберете вопрос',
					}}
				/>
				<div
					className={styles.wrapperIcon}
					onClick={() => {
						dispatchField({
							type: ActionType.DELETE_QUESTION,
							payload: {
								position: question.position,
							},
						});
					}}
				>
					<IcTrash />
				</div>
			</div>
			{question?.questionOptions && question?.answers.length > 0 && (
				<>
					{question?.answers?.map((answer: any, index: number) => {
						return (
							<>
								{index == 0 && <div className={styles.labelField}>Ответ на вопрос</div>}
								{index !== 0 && (
									<div className={styles.wrapperField}>
										<Select
											options={question.element === 'Select' ? operator : operators}
											onChange={(option: IOption | null) => {
												dispatchField({
													type: ActionType.EDIT_ANSWER_FOR_QUESTION_OPERATOR,
													payload: {
														operatorLabel: option?.label,
														position: question.position,
														positionAnswer: answer.positionAnswer,
													},
												});
											}}
											placeholder='Выберете вопрос'
											className={styles.select}
											value={{
												label: answer.operatorLabel || 'Выберете условие',
												value: answer.operatorLabel || 'Выберете условие',
											}}
										/>
									</div>
								)}
								<div className={styles.wrapperField}>
									<Select
										options={question?.questionOptions}
										onChange={(option: IOption | null) => {
											dispatchField({
												type: ActionType.EDIT_ANSWER_FOR_QUESTION,
												payload: {
													label: option?.label,
													position: question.position,
													positionAnswer: answer.positionAnswer,
												},
											});
										}}
										placeholder='Выберете вопрос'
										className={styles.select}
										value={{
											label: answer.label || 'Выберете вопрос',
											value: answer.label || 'Выберете вопрос',
										}}
									/>
									<div
										className={styles.wrapperIcon}
										style={{ marginTop: '10px' }}
										onClick={() => {
											dispatchField({
												type: ActionType.DELETE_ANSWER,
												payload: {
													position: question.position,
													positionAnswer: answer.positionAnswer,
												},
											});
										}}
									>
										<IcDelete />
									</div>
								</div>
							</>
						);
					})}
				</>
			)}
			{question.label && (
				<span
					onClick={() =>
						dispatchField({
							type: ActionType.ADD_ANSWER,
							payload: {
								position: question.position,
							},
						})
					}
					className={styles.addAnswer}
				>
					+ Добавить ответ
				</span>
			)}
		</>
	);
};

// const initialState: any = { attributes: []};
function reducer(state: any, action: any): any {
	const { type, payload } = action;
	const { value, label, link } = payload;

	const generateUniqOptionId: any = (fields: any) => {
		const randomNumber = Math.floor(Math.random() * 100);

		const optionsIds = fields.map((field: any) => {
			return field.value;
		});

		if (optionsIds.includes(randomNumber)) {
			return generateUniqOptionId(fields);
		} else {
			return randomNumber;
		}
	};

	switch (type) {
		case ActionType.RESET_ATTRIBUTE:
			return {
				...state,
				attributes: [],
			};
		case ActionType.EDIT_TEXT_LABEL:
			return {
				...state,
				title: label,
			};
		case ActionType.EDIT_TEXT_OPTION:
			return {
				...state,
				options: state.options.map((option: any) =>
					option.value === value
						? {
								...option,
								label,
						  }
						: option,
				),
			};

		case ActionType.EDIT_LINK_OPTION:
			return {
				...state,
				options: state.options.map((option: any) =>
					option.value === value
						? {
								...option,
								link,
						  }
						: option,
				),
			};
		case ActionType.ADD_OPTION:
			return {
				...state,
				options: [
					...state.options,
					{
						label: 'Ответ',
						value: generateUniqOptionId(state.options),
					},
				],
			};
		case ActionType.DELETE_OPTION:
			return {
				...state,
				options: state.options && state.options.filter((item: any) => item.value !== value),
			};
		default:
			console.log('default case');
			return state;
	}
}

export default Question;
