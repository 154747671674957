import { ComponentType, createElement, FC, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { observer } from 'mobx-react-lite';

import { PageHead } from 'components/Layouts';
import { Loader } from 'components/UI';
import { DeletePopup } from 'components/Logic';

import { DNDListPageProps } from './types';

import styles from './DNDListPage.module.scss';

const DNDListPage: FC<DNDListPageProps> = observer(({ title, store, element }) => {
	const [currentDeleteItem, setCurrentDeleteItem] = useState<number | null>(null);
	const { list, isLoading, isEmpty } = store;

	const closeDeleteModal = () => setCurrentDeleteItem(null);
	const openDeleteModal = (id: number) => setCurrentDeleteItem(id);

	const onDelete = () => {
		store.removeItem(currentDeleteItem as number);
		closeDeleteModal();
	};

	const onDragEnd = async (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const reorderedItems = [...list];

		const [movedItem] = reorderedItems.splice(result.source.index, 1);
		reorderedItems.splice(result.destination.index, 0, movedItem);

		const reorderedIds = reorderedItems.map((item) => item.id);

		store.clearItems();
		reorderedItems.forEach((item) => store.setItem(item));

		await store.updateOrder(reorderedIds);
	};

	useEffect(() => {
		store.fetchItems();
	}, [store]);

	if (isLoading) {
		return (
			<section className={styles.cardsPage}>
				<PageHead title={title} store={store} hasCreate={true} hasDelete={false} />

				<Loader />
			</section>
		);
	}

	if (isEmpty) {
		return (
			<section className={styles.cardsPage}>
				<PageHead title={title} store={store} hasCreate={true} hasDelete={false} />

				<div className={styles.emptyContainer}>
					<h5>Список пуст</h5>
					<span>Добавьте новые элементы</span>
				</div>
			</section>
		);
	}

	return (
		<section className={styles.cardsPage}>
			<PageHead title={title} store={store} hasCreate={true} hasDelete={false} />

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable' direction='vertical'>
					{(provided) => (
						<ul className={styles.list} {...provided.droppableProps} ref={provided.innerRef}>
							{list?.map((item: any, index: number) => {
								return createElement(element as ComponentType<any>, {
									key: item.id,
									store: store,
									item: item,
									index,
									openDeleteModal: openDeleteModal,
								});
							})}
							{provided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>

			<DeletePopup
				onDeleteItems={onDelete}
				isOpenPopup={currentDeleteItem !== null}
				closePopup={closeDeleteModal}
			/>
		</section>
	);
});

export default DNDListPage;
