import { computed, makeObservable, observable, toJS, action } from 'mobx';

import { VacancyService } from 'API';
import { PaginationStore } from 'store/general/Pagination/paginationStore';
import { IVacancyItem, IVacancyView } from 'interfaces/entities/IVacancy';
import { IVacancyStore } from './types';

class VacancyStore extends PaginationStore implements IVacancyStore {
	isLoading: boolean;
	items: IVacancyItem[];
	item = {} as IVacancyView;

	constructor() {
		super();
		this.isLoading = false;
		this.items = [];

		makeObservable(this, {
			isLoading: observable,
			item: observable,
			items: observable,
			list: computed,
			isEmpty: computed,
			setItem: action,
			setLoading: action,
			clearItems: action,
		});
	}

	// GET
	get element() {
		return toJS(this.item);
	}

	get list() {
		return toJS(this.items);
	}

	get isEmpty() {
		return this.items.length === 0;
	}

	// SET
	setElement(item: IVacancyView) {
		this.item = item;
	}

	setItem(item: IVacancyItem) {
		this.items.push(item);
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	changeItem(id: number, newItem: IVacancyItem) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as IVacancyItem & { id: number };

			return oldItem.id === id;
		});

		this.items[index] = newItem;
	}

	clearItems() {
		this.items = [];
	}

	deleteItem(id: number) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as IVacancyItem;

			return oldItem.id === id;
		});

		this.items.splice(index, 1);
	}

	// ASYNC
	// FETCH ITEMS
	async fetchItems(query: string) {
		this.setLoading(true);
		this.clearItems();
		this.setCurrentPage(1);
		this.setLastPage(1);

		const response = await VacancyService.fetchVacancies(query);

		if ('errors' in response) {
			this.setLoading(false);
			return;
		}

		if ('meta' in response && response.meta !== undefined) {
			this.setCurrentPage(response.meta.current_page ?? 1);
			this.setLastPage(response.meta.last_page ?? 1);
		}

		response.items?.forEach((item: IVacancyItem) => this.setItem(item));

		this.setLoading(false);
	}

	// FETCH ITEM
	async fetchItem(id: string) {
		const response = await VacancyService.fetchVacancy(id);

		if ('item' in response) {
			this.setElement(response.item);
		}

		this.setLoading(false);
	}

	// UPDATE
	async updateItem(id: string, isApproved: boolean | null) {
		const response = await VacancyService.updateVacancy(id, isApproved);

		if ('errors' in response) {
			throw response;
		}

		if ('item' in response) {
			this.setElement(response.item);
		}
	}
}

export default VacancyStore;
