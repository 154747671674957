import { FC, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { Button, Validation } from 'components/UI';
import { AsyncButton } from 'components/Form';
import { FieldByType } from 'components/Logic';

import { fieldOnChange } from 'utils/fieldOnChange';
import { createInitialValues } from 'utils/createInitialValues';
import { createRequestData } from 'utils/createRequestData';

import { FieldOnChangEventType } from 'components/Layouts/FieldList/types';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { DocumentFormProps } from './types';
import { globalFormikConfig } from 'config/globalFormikConfig';

import styles from './CareerPracticeQrs.module.scss';

const CareerPracticeQrForm: FC<DocumentFormProps> = observer(({ qr, onClose }) => {
	const store = useStore();
	const careerPracticesStore = store.careerPractices;

	const [isRequesting, setIsRequesting] = useState(false);
	const { id: careerPracticeId } = useParams();

	const fields: FieldsType[] = [
		{
			type: 'textFieldWithPrefix',
			prefix: 'https://',
			value: qr?.link ?? '',
			name: 'link',
			label: 'Ссылка в QR код',
		},
		{
			type: 'file',
			label: '',
			text: 'Доступны к загрузке PNG, JPG, PDF до',
			name: 'image',
			url: qr?.image_url ?? '',
			maxSize: 40_960,
		},
	];

	const formik = useFormik({
		...globalFormikConfig,
		initialValues: createInitialValues(fields),
		onSubmit: async (values) => {
			const { formData } = createRequestData({ values, fields });

			setIsRequesting(true);
			const response =
				qr === null
					? await careerPracticesStore.createQr(parseInt(careerPracticeId!), formData)
					: await careerPracticesStore.updateQr(parseInt(careerPracticeId!), qr.id, formData);
			setIsRequesting(false);

			if ('errors' in response) formik.setErrors(response.errors);
			else onClose();
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className={styles.formItem}>
			{fields.map((field) => (
				<div key={field.name}>
					<FieldByType
						field={field}
						value={formik?.values?.[field.name]}
						onChange={(event: FieldOnChangEventType) => fieldOnChange(event, field, formik)}
						className=''
						onDelete={() => formik.setFieldValue('url', null)}
					/>
					<Validation touched={formik.touched[field.name]} errors={formik.errors[field.name]} />
				</div>
			))}

			<div className={styles.btns}>
				<AsyncButton isLoading={isRequesting} type='submit' size='small'>
					Сохранить
				</AsyncButton>
				<Button theme='dark' onClick={onClose} size='small' className={styles.cancel}>
					Отменить
				</Button>
			</div>
		</form>
	);
});

export default CareerPracticeQrForm;
