import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts/';
import ArticlesTableHeader from './ArticlesTableHeader';
import Article from './Article';

import styles from './Articles.module.scss';

const Articles: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.articles}
			title='Статьи'
			tableHeader={ArticlesTableHeader}
			tableClassName={styles.table}
			element={Article}
		/>
	);
});

export default Articles;
