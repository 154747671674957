import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Checkbox } from 'components/UI';

const ArchiveSlot = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [isArchive, setIsArchive] = useState(getArchiveStatus());

	function getArchiveStatus() {
		return searchParams.get('is_archived') === '1';
	}

	const onClickArchive = (e: any) => {
		if (e.target.checked) {
			searchParams.set('is_archived', '1');
			setIsArchive(true);
		} else {
			searchParams.delete('is_archived');
			setIsArchive(false);
		}

		setSearchParams(searchParams);
	};

	return <Checkbox label='В архиве' checked={isArchive} onChange={onClickArchive} />;
};

export default ArchiveSlot;
