import makeRequest from '../makeRequest';
import { GetPavilionResponse, GetPavilionsResponse } from 'interfaces/api/PavilionResponse';
import {IPavilionCreate, IPavilionOstrovaCreate} from '../../interfaces/entities/IPavilion';

class PavilionService {
	async fetchPavilions(query: string) {
		const response = await makeRequest<GetPavilionsResponse>({
			url: `event_places?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.event_places,
			meta: response.data.meta,
		};
	}

	async fetchPavilionsOstrova(query: string) {
		const response = await makeRequest<GetPavilionsResponse>({
			url: `event_places?is_islands=1${query && `&${query}`}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.event_places,
			meta: response.data.meta,
		};
	}

	async fetchPavilion(id: string) {
		const response = await makeRequest<GetPavilionResponse>({
			url: `event_places/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event_place,
		};
	}

	async createPavilion(pavilion: IPavilionCreate) {
		const response = await makeRequest<GetPavilionResponse>({
			url: 'event_places',
			method: 'post',
			auth: true,
			data: pavilion,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event_place,
		};
	}

	async createPavilionOstrova(pavilion: IPavilionOstrovaCreate) {
		const response = await makeRequest<GetPavilionResponse>({
			url: 'event_places',
			method: 'post',
			auth: true,
			data: pavilion,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event_place,
		};
	}

	async updatePavilionOstrova(pavilion: IPavilionOstrovaCreate & { id: number }) {
		const response = await makeRequest<GetPavilionResponse>({
			url: `event_places/${pavilion.id}`,
			method: 'put',
			auth: true,
			data: pavilion,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event_place,
		};
	}
	async updatePavilion(pavilion: IPavilionCreate & { id: number }) {
		const response = await makeRequest<GetPavilionResponse>({
			url: `event_places/${pavilion.id}`,
			method: 'put',
			auth: true,
			data: pavilion,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event_place,
		};
	}

	deletePavilion(id: number) {
		return makeRequest<void>({
			url: `event_places/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new PavilionService();
