import { computed, makeObservable, observable, toJS, action } from 'mobx';

import { PaginationStore } from '../Pagination/paginationStore';
import { TBreadcrumbResponse } from 'interfaces/api/IAPI';
import { APIArguments, IListStore } from './types';

export class ListStore<ReadType, CreateType>
	extends PaginationStore
	implements IListStore<ReadType, CreateType>
{
	isLoading: boolean;
	items: ReadType[];
	item: ReadType = {} as ReadType;
	breadcrumbs: TBreadcrumbResponse[] = [];
	APIFetchItems: APIArguments<ReadType, CreateType>['APIFetchItems'];
	APIFetchItem?: APIArguments<ReadType, CreateType>['APIFetchItem'];
	APICreateItem?: APIArguments<ReadType, CreateType>['APICreateItem'];
	APIUpdateItem?: APIArguments<ReadType, CreateType>['APIUpdateItem'];
	APIRemoveItem?: APIArguments<ReadType, CreateType>['APIRemoveItem'];

	constructor(APIService: APIArguments<ReadType, CreateType>) {
		super();
		this.APIFetchItems = APIService.APIFetchItems;
		this.APIFetchItem = APIService.APIFetchItem;
		this.APICreateItem = APIService.APICreateItem;
		this.APIUpdateItem = APIService.APIUpdateItem;
		this.APIRemoveItem = APIService.APIRemoveItem;
		this.isLoading = false;
		this.items = [];

		makeObservable(this, {
			isLoading: observable,
			item: observable,
			items: observable,
			breadcrumbs: observable,
			list: computed,
			isEmpty: computed,
			setItem: action,
			setLoading: action,
			clearItems: action,
			changeItem: action,
			deleteItem: action,
		});
	}

	// GET
	get element() {
		return toJS(this.item);
	}

	get breadcrumbsList() {
		return toJS(this.breadcrumbs);
	}

	get list() {
		return toJS(this.items);
	}

	get isEmpty() {
		return this.items.length === 0;
	}

	// SET
	setElement(item: ReadType) {
		this.item = item;
	}

	setItem(item: ReadType) {
		this.items.push(item);
	}

	changeItem(id: number, newItem: ReadType) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as ReadType & { id: number };

			return oldItem.id === id;
		});

		this.items[index] = newItem;
	}

	clearItems() {
		this.items = [];
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	deleteItem(id: number) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as ReadType & { id: number };

			return oldItem.id === id;
		});

		this.items.splice(index, 1);
	}

	// ASYNC
	// FETCH ITEMS
	async fetchItems(query: string) {
		this.setLoading(true);
		this.clearItems();
		this.setCurrentPage(1);
		this.setLastPage(1);

		const response = await this.APIFetchItems(query);

		if ('errors' in response) {
			this.setLoading(false);
			return;
		}

		if ('meta' in response && response.meta !== undefined) {
			if ('breadcrumbs' in response.meta) {
				this.breadcrumbs = response.meta.breadcrumbs;
			}

			this.setCurrentPage(response.meta.current_page);
			this.setLastPage(response.meta.last_page);
		}

		response.items?.forEach((item: ReadType) => this.setItem(item));

		this.setLoading(false);
	}

	// FETCH ITEM
	async fetchItem(id: string | undefined) {
		if (this.APIFetchItem === undefined) return;
		if (id === undefined) return;

		const response = await this.APIFetchItem(id);

		if ('item' in response) {
			this.setElement(response.item);
		}

		this.setLoading(false);
	}

	// POST ITEM
	createItem = async (newItem: CreateType) => {
		if (this.APICreateItem === undefined) return;

		const response = await this.APICreateItem(newItem);

		if ('errors' in response) {
			throw response;
		}

		return response;
	};

	// PUT ITEM
	updateItem = async (updatedItem: CreateType & { id: number }) => {
		if (this.APIUpdateItem === undefined) return;

		const response = await this.APIUpdateItem(updatedItem);

		if ('errors' in response) {
			throw response;
		}

		if ('id' in updatedItem) {
			this.changeItem(updatedItem.id, response.item);
		}

		return response;
	};

	// DELETE ITEM
	removeItem = async (id: number) => {
		if (this.APIRemoveItem === undefined) return;

		this.APIRemoveItem(id);

		this.deleteItem(id);
	};
}
