import makeRequest from 'API/makeRequest';
import { GetUsersResponse } from 'interfaces/api/UserResponse';

class SchoolUserService {
	async fetchUsers(query: string) {
		const response = await makeRequest<GetUsersResponse>({
			url: `school_users?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.users,
			meta: response.data.meta,
		};
	}
}

export default new SchoolUserService();
