import makeRequest from '../makeRequest';
import {
	GetSupportMeasureResponse,
	GetSupportMeasuresResponse,
} from 'interfaces/api/SupportMeasuresResponse';

class SupportMeasureService {
	async fetchSupportMeasures() {
		const response = await makeRequest<GetSupportMeasuresResponse>({
			url: 'support_measures',
			auth: true,
		});
		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.support_measures,
			meta: { current_page: 1, last_page: 1, path: '', breadcrumbs: [{ id: 1, label: '' }] },
		};
	}

	async fetchSupportMeasure(id: string) {
		const response = await makeRequest<GetSupportMeasureResponse>({
			url: `support_measures/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}
		return {
			item: response.data.support_measure,
		};
	}

	async createSupportMeasure(advice: FormData) {
		const response = await makeRequest<GetSupportMeasureResponse>({
			url: 'support_measures',
			method: 'post',
			auth: true,
			data: advice,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.support_measure,
		};
	}

	async updateSupportMeasure(support_measure: FormData) {
		const response = await makeRequest<GetSupportMeasureResponse>({
			url: `support_measures/${support_measure.get('id')}`,
			method: 'post',
			auth: true,
			data: support_measure,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.support_measure,
		};
	}

	deleteSupportMeasure(id: number) {
		return makeRequest<void>({
			url: `support_measures/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new SupportMeasureService();
