import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { VacancyProps } from './types';

import styles from './Vacancies.module.scss';
import clsx from 'clsx';

const Vacancy: FC<VacancyProps> = observer(({ item }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update${location.search}`);
	};

	const approveStatus = item.is_approved
		? 'Опубликовано'
		: item.is_approved === null
		? 'На рассмотрении'
		: 'Отказано';

	const approveClasses = item.is_approved
		? styles.approved
		: item.is_approved === null
		? ''
		: styles.notApproved;

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.name}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.employer_profile.employer_name}</span>
			</div>
			<div className={styles.cell}>
				<span className={clsx(approveClasses, 'wrap-text limit-line-text')}>{approveStatus}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.updated_at}</span>
			</div>
			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Изменить</span>
				</div>
			</div>
		</div>
	);
});

export default Vacancy;
