/* eslint-disable */
import clsx from "clsx";
import { Checkbox, TextField } from "components/UI";
import styles from "../СalculatorBuilder.module.scss";
import { ReactComponent as IcTrash } from "assets/icons/general/ic_trash.svg";
import { ActionType } from "../СalculatorBuilder";
import { useState } from "react";

const EditField = ({ label, value, dispatch, link, key }: any) => {
	const [isLink, setIsLink] = useState(!!link);
	const textFieldClasses = clsx({
		[styles.filterField]: true
		// [styles.filterInactive]: column.name !== filterName && !isMustSelect,
		// [styles.filterSelectInactive]: column.name !== filterName && isMustSelect,
	});

	return (
		<div key={key} className={styles.answer}>
			<div className={styles.answerInput}>
				<TextField
					className={textFieldClasses}
					value={label}
					onChange={(e) => {
						dispatch({
							type: ActionType.EDIT_TEXT_OPTION,
							payload: {
								label: e.target.value,
								value
							}
						});
					}}
				/>
				<IcTrash
					className={styles.textInputIcon}
					onClick={() => {
						dispatch({
							type: ActionType.DELETE_OPTION,
							payload: {
								label,
								value
							}
						});
					}}
				/>
			</div>
			<div className={styles.isLink}>
				<Checkbox
					className={styles.answerChekbox}
					checked={isLink}
					onChange={() => setIsLink(!isLink)}
				/>
				Прикрепить ресурс
			</div>
			{isLink && (
				<TextField
					className={textFieldClasses}
					placeholder={"https://"}
					value={link || ""}
					onChange={(e) => {
						dispatch({
							type: ActionType.EDIT_LINK_OPTION,
							payload: {
								label,
								value,
								link: e.target.value
							}
						});
					}}
				/>
			)}
		</div>
	);
};

export default EditField;
