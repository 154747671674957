import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/UI';

import { IMailDistributionProps } from './types';

import { ReactComponent as IcDelete } from 'assets/icons/general/ic_trash.svg';
import { ReactComponent as IcHumans } from 'assets/icons/general/ic_humans.svg';
import styles from './MailDistribution.module.scss';

const MailDistributionCard: FC<IMailDistributionProps> = ({ item, openDeleteModal }) => {
	const navigate = useNavigate();

	const onLinkToUpdate = () => {
		navigate(`${item.id}/update`);
	};

	return (
		<li className={styles.item}>
			<span className={styles.title}>{item.title}</span>
			<p className={styles.description}>{item.theme}</p>

			<ul className={styles.categories}>
				<li className={styles.category}>
					<IcHumans />
					<span>{item.role === 'applicant' ? 'Гражданин' : 'Предприятие'}</span>
				</li>
			</ul>

			<div className={styles.btns}>
				<Button theme='filled' onClick={onLinkToUpdate} size='small' className={styles.edit}>
					Редактировать
				</Button>
				<Button
					theme='danger'
					onClick={() => openDeleteModal(item.id)}
					size='small'
					className={styles.delete}
				>
					<IcDelete />
				</Button>
			</div>
		</li>
	);
};

export default MailDistributionCard;
