import { makeAutoObservable } from 'mobx';

import { IAuthStore } from 'interfaces/stores/IAuthStore';
import AuthService from 'API/rest/AuthService';

class Auth implements IAuthStore {
	isAuth: boolean | null;
	userName = '';
	isLoading = false;

	constructor() {
		this.isAuth = null;
		makeAutoObservable(this);

		this.check();
	}

	setAuth(isAuth: boolean) {
		this.isAuth = isAuth;
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	setUserName(userName: string) {
		this.userName = userName;
	}

	check = async () => {
		const token = localStorage.getItem('token');

		if (token !== null) {
			const response = await AuthService.check();

			if (response.status !== 401 && 'data' in response) {
				this.setAuth(true);
				this.setUserName(response.data.full_name);
			} else {
				this.setAuth(false);
				localStorage.removeItem('token');
			}
			return;
		}

		this.setAuth(false);
	};

	async login(email: string, password: string) {
		this.setLoading(true);
		const response = await AuthService.login(email, password);

		if ('token' in response) {
			localStorage.setItem('token', response.token);
			this.setUserName(response.full_name);
			this.setAuth(true);
		}

		this.setLoading(false);

		return response;
	}

	logout = () => {
		AuthService.logout();
		localStorage.removeItem('token');
		this.setAuth(false);
	};
}

export default Auth;
