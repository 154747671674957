import { useLocation } from 'react-router-dom';
import { FC } from 'react';

import { PageHeader } from '../index';
import { LinkBtn, Loader, Select } from 'components/UI';

import { isApprovedOptions } from 'config/selectOptions';

import { ApprovePageProps } from './types';

import styles from './ApprovePage.module.scss';

const ApprovePage: FC<ApprovePageProps> = ({
	isLoading,
	title,
	mainInfo,
	secondaryInfo,
	onUpdate,
	currentValue,
	additionalButtons,
}) => {
	const location = useLocation();
	const backLink = location.pathname.split('/').slice(0, -2).join('/') + location.search;
	const approvedSelectValue = isApprovedOptions.find((option) => option.value === currentValue);

	if (isLoading)
		return (
			<section className={styles.approvePage}>
				<PageHeader title={title} backLink={backLink} />

				<Loader />
			</section>
		);

	return (
		<section className={styles.approvePage}>
			<PageHeader title={title} backLink={backLink} />

			<div className={styles.info}>
				<ul className={styles.main}>
					{mainInfo.map(
						(item) =>
							item.text?.toString().trim() && (
								<li className={styles.item} key={item.title}>
									<span>{item.title}</span>
									<p>{item.text}</p>
								</li>
							),
					)}
				</ul>
				<div className={styles.secondaryBlock}>
					{secondaryInfo.map(
						(infoItem) =>
							infoItem.blocks.length !== 0 && (
								<>
									<b>{infoItem.header}</b>

									{infoItem.blocks.map((block, index) => (
										<ul className={styles.secondary} key={index}>
											{block.map((item) =>
												'isLink' in item
													? item.url && ( // item with link
															<li className={styles.item} key={item.title}>
																<span>{item.title}</span>
																<a
																	href={`${process.env.REACT_APP_BACKEND_URL}/${item.url}`}
																	target='_blank'
																	rel='noreferrer'
																>
																	{item.text}
																</a>
															</li>
													  )
													: item.text?.toString().trim() && ( // item with text
															<li className={styles.item} key={item.title}>
																<span>{item.title}</span>
																<p>{item.text}</p>
															</li>
													  ),
											)}
										</ul>
									))}
								</>
							),
					)}
				</div>
			</div>

			<div className={styles.btns}>
				<LinkBtn to={backLink} theme='filled'>
					Закрыть
				</LinkBtn>

				{additionalButtons}

				{approvedSelectValue && (
					<Select
						options={isApprovedOptions}
						onChange={(option) => onUpdate && onUpdate(option?.value as boolean | null)}
						value={approvedSelectValue}
						wrapperClassName={styles.approveSelect}
						isSearchable={false}
						menuPlacement='top'
					/>
				)}
			</div>
		</section>
	);
};

export default ApprovePage;
