import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { ResumeProps } from './types';

import styles from './Resumes.module.scss';

const Resume: FC<ResumeProps> = observer(({ item }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update${location.search}`);
	};

	const position = item.position ?? 'Должность не указана';

	const positionClasses = clsx('wrap-text limit-line-text', {
		[styles.emptyPosition]: !item.position,
	});

	const approveStatus = item.is_approved
		? 'Опубликовано'
		: item.is_approved === null
		? 'На рассмотрении'
		: 'Отказано';

	const approveClasses = item.is_approved
		? styles.approved
		: item.is_approved === null
		? ''
		: styles.notApproved;

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className={positionClasses}>{position}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.applicant_profile.full_name}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.applicant_profile.status}</span>
			</div>
			<div className={styles.cell}>
				<span className={clsx(approveClasses, 'wrap-text limit-line-text')}>{approveStatus}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.created_at}</span>
			</div>
			<div className={clsx(styles.cell, styles.dateRangeAndLink)}>
				<span>{item.updated_at}</span>

				<div className='edit-icon-block' onClick={onLinkToUpdate}>
					<span className='update-text'>Изменить</span>
				</div>
			</div>
		</div>
	);
});

export default Resume;
