import { action, computed, makeObservable, observable, ObservableMap, values } from 'mobx';

import { CareerPracticesService } from 'API';

import { ListStore } from 'store/general/List/lIstStore';
import {
	ICareerPracticeCreate,
	ICareerPracticeQrRead,
	ICareerPracticeRead,
} from 'interfaces/entities/ICareerPractice';

class CareerPracticeStore extends ListStore<ICareerPracticeRead, ICareerPracticeCreate> {
	qrs: ObservableMap<number, ICareerPracticeQrRead> = observable.map();

	constructor() {
		super({
			APIFetchItems: CareerPracticesService.fetchPractices,
			APIFetchItem: CareerPracticesService.fetchPractice,
			APICreateItem: CareerPracticesService.createPractice,
			APIUpdateItem: CareerPracticesService.updatePractice,
			APIRemoveItem: CareerPracticesService.deletePractice,
		});

		makeObservable(this, {
			qrs: observable,
			qrsList: computed,
			setQr: action,
		});
	}

	get qrsList() {
		return values(this.qrs);
	}

	get hasQrs() {
		return !!values(this.qrs).length;
	}

	setQr(qr: ICareerPracticeQrRead) {
		this.qrs.set(qr.id, qr);
	}

	async fetchQrs(id: number) {
		this.setLoading(true);
		this.qrs.clear();

		const response = await CareerPracticesService.fetchQrs(id);

		if ('qr_codes' in response) {
			response.qr_codes.forEach((qr) => this.setQr(qr));
		}

		this.setLoading(false);
	}

	async createQr(id: number, document: FormData) {
		const response = await CareerPracticesService.createQr(id, document);

		if ('qr_code' in response) {
			this.setQr(response.qr_code);
		}

		return response;
	}

	async updateQr(id: number, qrId: number, document: FormData) {
		const response = await CareerPracticesService.updateQr(id, qrId, document);

		if ('qr_code' in response) {
			this.setQr(response.qr_code);
		}

		return response;
	}

	async removeQr(id: number, qrId: number) {
		const response = await CareerPracticesService.deleteQr(id, qrId);

		if (response.status === 200) {
			this.qrs.delete(qrId);
		}
	}
}

export default CareerPracticeStore;
