import makeRequest from 'API/makeRequest';
import { GetSchoolResumesResponse } from 'interfaces/api/SchoolUserResponse';

class SchoolResumeService {
	async fetchUsers(query: string) {
		const response = await makeRequest<GetSchoolResumesResponse>({
			url: `school_resumes?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.resumes,
			meta: response.data.meta,
		};
	}
}

export default new SchoolResumeService();
