import { ProfessionalQualityService } from 'API';
import {
	IProfessionalQualityRead,
	IProfessionalQualityCreate,
} from 'interfaces/entities/IProfessionalQuality';
import { ListStore } from 'store/general/List/lIstStore';

class ProfessionalQualityStore extends ListStore<
	IProfessionalQualityRead,
	IProfessionalQualityCreate
> {
	constructor() {
		super({
			APIFetchItems: ProfessionalQualityService.fetchProfessionalQualities,
			APIFetchItem: ProfessionalQualityService.fetchProfessionalQuality,
			APICreateItem: ProfessionalQualityService.createProfessionalQuality,
			APIUpdateItem: ProfessionalQualityService.updateProfessionalQuality,
			APIRemoveItem: ProfessionalQualityService.deleteProfessionalQuality,
		});
	}
}

export default ProfessionalQualityStore;
