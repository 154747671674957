import { FC } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { TLinkButtonProps } from './types';

import styles from './Button.module.scss';

const LinkBtn: FC<TLinkButtonProps> = ({
	theme = 'outlined',
	size = 'default',
	className = '',
	children,
	...props
}) => {
	const buttonStyle: string = clsx(styles.button, styles.defaultSize, {
		[className]: className,
		[styles.small]: size === 'small',
		[styles.filled]: theme === 'filled',
		[styles.outlined]: theme === 'outlined',
		[styles.grey]: theme === 'grey',
		[styles.dark]: theme === 'dark',
		[styles.danger]: theme === 'danger',
		[styles.light]: theme === 'light',
		[styles.borderless]: theme === 'borderless',
	});

	return (
		<Link className={buttonStyle} {...props}>
			{children}
		</Link>
	);
};

export default LinkBtn;
