import { ChangeEvent, FC, useState } from 'react';
import clsx from 'clsx';

import { Button, TextField } from 'components/UI/index';

import { ITextFieldWithCrumbsProps } from './types';

import { ReactComponent as IcAccess } from 'assets/icons/general/ic_access.svg';
import { ReactComponent as IcClose } from 'assets/icons/general/ic_close.svg';
import styles from './TextFieldWithCrumbs.module.scss';

const TextFieldWithCrumbs: FC<ITextFieldWithCrumbsProps> = ({
	label = '',
	placeholder,
	value = [],
	onChange,
	className = '',
}) => {
	const [inputValue, setInputValue] = useState('');
	const [items, setItems] = useState(value ? value : []);

	const addSkill = () => {
		if (!inputValue.trim()) return;
		const newItems = [...items, inputValue];
		setInputValue('');
		setItems(newItems);
		onChange(newItems);
	};

	const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const onDeleteItem = (currentValue: string, currentIndex: number) => {
		const newItems = items.filter(
			(item, index) => !(item === currentValue && index === currentIndex),
		);
		setItems(newItems);
		onChange(newItems);
	};

	const containerClasses = clsx(styles.container, {
		[className]: !!className,
	});

	return (
		<div className={containerClasses}>
			<div className={styles.top}>
				{label && <span className={styles.label}>{label}</span>}

				<div className={styles.group}>
					<TextField value={inputValue} onChange={onChangeInput} placeholder={placeholder} />

					<Button
						theme='filled'
						size='default'
						onClick={addSkill}
						className={styles.buttonAdd}
						disabled={!inputValue.trim()}
					>
						<IcAccess />
					</Button>
				</div>
			</div>

			{items?.length !== 0 && (
				<ul className={styles.list}>
					{items?.map((item, index) => (
						<li key={index} className={styles.item}>
							<span>{item}</span>
							<button type='button' onClick={() => onDeleteItem(item, index)}>
								<IcClose />
							</button>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default TextFieldWithCrumbs;
