import { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';

const CareerPracticeUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.careerPractices.element;

	const fetchData = useCallback(async () => {
		await store.careerPractices.fetchItem(id);
	}, [id]);

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'name',
			value: item.name ?? '',
			label: 'Название*',
		},
		{
			type: 'file',
			name: 'image',
			label: 'Изображение*',
			text: 'Доступны к загрузке PNG, JPG, PDF до',
			url: item.image_url ?? '',
			maxSize: 1_048_576,
		},
		{
			type: 'wysiwyg',
			name: 'description',
			value: item.description ?? '',
			label: 'Описание*',
		},
		{
			type: 'phonefield',
			name: 'phone',
			value: item.phone ?? '',
			label: 'Номер телефона',
		},
		{
			type: 'textFieldWithPrefix',
			name: 'link',
			prefix: 'https://',
			value: item.link ?? '',
			label: 'Ссылка',
		},
		{
			type: 'switch',
			name: 'is_active',
			value: item.is_active,
			label: 'Состояние',
		},
	];

	return (
		<UpdatePage
			title='Практики профориентации / Изменение'
			fields={fields}
			store={store.careerPractices}
			fetchData={fetchData}
		/>
	);
});

export default CareerPracticeUpdate;
