import { makeAutoObservable } from 'mobx';

import AppSettingService from 'API/rest/AppSettingService';
import { IAppSettingsStore } from './types';

export class AppSettingsStore implements IAppSettingsStore {
	workingStatus: boolean | null = null;
	isLoading = false;

	constructor() {
		makeAutoObservable(this);
	}

	setIsLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	setWorkingStatus(status: boolean | null) {
		this.workingStatus = status;
	}

	async fetchAppWorkingStatus() {
		this.setIsLoading(true);
		this.setWorkingStatus(null);

		const response = await AppSettingService.fetchAppWorkingStatus();

		if ('data' in response) {
			this.setWorkingStatus(response.data.status);
		}

		this.setIsLoading(false);
		return response;
	}

	async updateWorkingStatus(isWorking: boolean) {
		this.setIsLoading(true);

		const response = await AppSettingService.changeWorkingStatus(isWorking);

		if ('data' in response) {
			this.setWorkingStatus(response.data.status);
		}

		this.setIsLoading(false);
		return response;
	}
}
