import makeRequest from 'API/makeRequest';
import {
	GetChampionshipsResponse,
	GetChampionshipResponse,
} from 'interfaces/api/ChampionshipResponse';
import { IChampionshipCreate } from 'interfaces/entities/IChampionship';

class ChampionshipService {
	async fetchChampionships(query: string) {
		const response = await makeRequest<GetChampionshipsResponse>({
			url: `championships?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.championships,
			meta: response.data.meta,
		};
	}

	async fetchChampionship(id: string) {
		const response = await makeRequest<GetChampionshipResponse>({
			url: `championships/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.championship,
		};
	}

	async createChampionship(championship: IChampionshipCreate) {
		const response = await makeRequest<GetChampionshipResponse>({
			url: 'championships',
			method: 'post',
			auth: true,
			data: championship,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.championship,
		};
	}

	async updateChampionship(championship: IChampionshipCreate & { id: number }) {
		const response = await makeRequest<GetChampionshipResponse>({
			url: `championships/${championship.id}`,
			method: 'put',
			auth: true,
			data: championship,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.championship,
		};
	}

	deleteChampionship(id: number) {
		return makeRequest<void>({
			url: `championships/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new ChampionshipService();
