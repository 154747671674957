import { FC } from 'react';
import clsx from 'clsx';

import styles from './Formbuilder.module.scss';

const Tabs: FC<any> = (props: any) => {
	const tabStyle = clsx({
		[styles.tab]: true,
	});

	return (
		<div className={styles.tabs}>
			<div
				className={tabStyle}
				onClick={() => props.setActiveTab('builder')}
				style={
					props.activeTab === 'builder'
						? {
								color: 'var(--c_blue)',
								borderBottom: '2px solid var(--c_blue)',
								opacity: 1,
						  }
						: {}
				}
			>
				Конструктор
			</div>
			<div
				onClick={() => props.setActiveTab('settings')}
				className={tabStyle}
				style={
					props.activeTab === 'settings'
						? {
								color: 'var(--c_blue)',
								borderBottom: '2px solid var(--c_blue)',
								opacity: 1,
						  }
						: {}
				}
			>
				Настройки
			</div>
		</div>
	);
};

export default Tabs;
