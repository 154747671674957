import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { LinkBtn, Loader, Select } from 'components/UI';
import MainSection from './Sections/MainSection';
import SecondarySection from './Sections/SecondarySection';
import { PageHeader } from '../index';

import { checkHasContentInBlock } from './uitls/checkHasContentInBlock';
import { isApprovedOptions } from 'config/selectOptions';

import { ApprovePageProps } from './types';

import styles from './HistoryPage.module.scss';

const ApprovePage: FC<ApprovePageProps> = ({
	isLoading,
	title,
	mainInfo,
	secondaryInfo,
	onUpdate,
	currentValue,
	additionalButtons,
}) => {
	const location = useLocation();
	const backLink = location.pathname.split('/').slice(0, -2).join('/') + location.search;
	const approvedSelectValue = isApprovedOptions.find((option) => option.value === currentValue);

	const hasOldMain = checkHasContentInBlock(mainInfo, 'oldValue');

	const currentContentClasses = clsx(styles.content, {
		[styles.emptyColor]: !hasOldMain,
	});

	if (isLoading)
		return (
			<section className={styles.historyPage}>
				<PageHeader title={title} backLink={backLink} />
				<Loader />
			</section>
		);

	return (
		<section className={styles.historyPage}>
			<PageHeader title={title} backLink={backLink} />

			<div className={styles.wrapper}>
				{hasOldMain && (
					<div className={styles.content}>
						<MainSection items={mainInfo} type='oldValue' />
						<SecondarySection items={secondaryInfo} type='oldValue' />
					</div>
				)}

				<div className={currentContentClasses}>
					<MainSection items={mainInfo} type='newValue' />
					<SecondarySection items={secondaryInfo} type='newValue' />
				</div>
			</div>

			<div className={styles.btns}>
				<LinkBtn to={backLink} theme='filled'>
					Закрыть
				</LinkBtn>

				{additionalButtons}

				{approvedSelectValue && (
					<Select
						options={isApprovedOptions}
						onChange={(option) => onUpdate && onUpdate(option?.value as boolean | null)}
						value={approvedSelectValue}
						wrapperClassName={styles.approveSelect}
						isSearchable={false}
						menuPlacement='top'
					/>
				)}
			</div>
		</section>
	);
};

export default ApprovePage;
