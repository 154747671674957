import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import HistoryPage from 'components/Layouts/HistoryPage/HistoryPage';

import { getMainConfig, getSecondaryConfig } from './config';

const ResumeUpdate: FC = observer(() => {
	const store = useStore();
	const [isLoading, setLoading] = useState(true);
	const { id } = useParams();
	const item = store.resumes.element;
	const historyItem = store.resumes.historyItem;

	useEffect(() => {
		(async function () {
			setLoading(true);
			if (id !== undefined) {
				await store.resumes.fetchItem(id);
				await store.resumes.fetchItemHistory(id);
			}
			setLoading(false);
		})();
	}, [id]);

	const onChangeApproved = (value: boolean | null) => {
		store.resumes.updateItem(item?.id.toString(), value);
	};

	const mainInfo = historyItem ? getMainConfig(historyItem) : [];
	const secondaryInfo = historyItem ? getSecondaryConfig(historyItem) : [];

	return (
		<HistoryPage
			isLoading={isLoading}
			title={'Анкеты / Одобрение'}
			mainInfo={mainInfo}
			secondaryInfo={secondaryInfo}
			onUpdate={onChangeApproved}
			currentValue={item?.is_approved}
		/>
	);
});
export default ResumeUpdate;
