import { FC } from 'react';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

import { educationOptions } from './types';

const ProfessionCreate: FC = () => {
	const store = useStore();

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'name',
			value: '',
			label: 'Название',
		},
		{
			type: 'select',
			name: 'education_type',
			label: 'Образование ',
			placeholder: '',
			options: educationOptions,
		},
		{
			type: 'wysiwyg',
			name: 'description',
			value: '',
			label: 'Описание',
		},
		{
			type: 'switch',
			name: 'is_active',
			value: true,
			label: 'Состояние',
		},
	];
	if (store.professions.createItem === undefined) return null;

	return <CreatePage title='Профессии / Создание' fields={fields} store={store.professions} />;
};

export default ProfessionCreate;
