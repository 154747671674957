import {
	isApprovedOptions,
	isResumeStatusOptions,
	TableHeader,
	THeaderField,
} from 'components/Layouts';

import styles from './Resumes.module.scss';

const ResumesTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Должность', name: 'position', type: 'textField', hasSorting: true },
		{ label: 'ФИО', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Статус', name: 'status', type: 'select', options: isResumeStatusOptions },
		{ label: 'Одобрена', name: 'is_approved', type: 'select', options: isApprovedOptions, hasSorting: true },
		{ label: 'Дата создания', name: '', type: null },
		{ label: 'Дата обновления', name: '', type: 'dateRange' },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default ResumesTableHeader;
