import { THistoryItem } from '../Sections/MainItem/types';

export const checkHasContentInBlock = (content: THistoryItem[], type: 'oldValue' | 'newValue') => {
	let hasContent = false;

	if (content)
		content.forEach((item) => {
			if ('hasLink' in item) {
				if (item[type].url) hasContent = true;
			} else {
				if (item[type]) hasContent = true;
			}
		});

	return hasContent;
};
