import { FC } from 'react';

import LinkHistoryItem from './MainItem/LinkHistoryItem';
import TextHistoryItem from './MainItem/TextHistoryItem';

import { IMainListProps } from '../types';

import styles from '../HistoryPage.module.scss';

const MainSection: FC<IMainListProps> = ({ items, type }) => {
	return (
		<section className={styles.mainSection}>
			<h6 className={styles.subtitle}>{type === 'oldValue' ? 'Старая' : 'Новая'}</h6>
			<h4 className={styles.title}>Основное</h4>

			<ul className={styles.list}>
				{items.map((item) =>
					'hasLink' in item ? (
						<LinkHistoryItem item={item} type={type} key={item.title} />
					) : (
						<TextHistoryItem item={item} type={type} key={item.title} />
					),
				)}
			</ul>
		</section>
	);
};

export default MainSection;
