import makeRequest from 'API/makeRequest';
import { GetSpecialitiesResponse, GetSpecialityResponse } from 'interfaces/api/SpecialityResponse';
import { ISpecialityCreate } from 'interfaces/entities/ISpeciality';

class SpecialityService {
	async fetchSpecialities(query: string) {
		const response = await makeRequest<GetSpecialitiesResponse>({
			url: `specialities?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.specialities,
			meta: response.data.meta,
		};
	}

	async fetchSpeciality(id: string) {
		const response = await makeRequest<GetSpecialityResponse>({
			url: `specialities/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.speciality,
		};
	}

	async createSpeciality(speciality: ISpecialityCreate) {
		const response = await makeRequest<GetSpecialityResponse>({
			url: 'specialities',
			method: 'post',
			auth: true,
			data: speciality,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.speciality,
		};
	}

	async updateSpeciality(speciality: ISpecialityCreate & { id: number }) {
		const response = await makeRequest<GetSpecialityResponse>({
			url: `specialities/${speciality.id}`,
			method: 'put',
			auth: true,
			data: speciality,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.speciality,
		};
	}

	deleteSpeciality(id: number) {
		return makeRequest<void>({
			url: `specialities/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new SpecialityService();
