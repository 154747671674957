import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import CareerGuidance from './CareerGuidance';
import CareerGuidancesTableHeader from './CareerGuidancesTableHeader';

import styles from './CareerGuidances.module.scss';

const CareerGuidances: FC = observer(() => {
	const store = useStore();

	return (
			<ListPage
				store={store.careerGuidance}
				title='Профориентация'
				tableHeader={CareerGuidancesTableHeader}
				tableClassName={styles.table}
				element={CareerGuidance}
				hasDelete={false}
				hasCreate={false}
			/>
	);
});

export default CareerGuidances;
