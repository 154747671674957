import makeRequest from 'API/makeRequest';
import {
	GetUserDocumentResponse,
	GetUserDocumentsResponse,
	GetUserFriendWorkResponse,
	GetUserResponse,
	GetUsersResponse,
	GetUserToken
} from 'interfaces/api/UserResponse';

class UserService {
	async fetchUsers(query: string) {
		const response = await makeRequest<GetUsersResponse>({
			url: `users?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.users,
			meta: response.data.meta,
		};
	}

	async fetchUser(id: string) {
		const response = await makeRequest<GetUserResponse>({
			url: `users/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.user,
		};
	}

	async fetchFriendWork(id: string) {
		const response = await makeRequest<GetUserFriendWorkResponse>({
			url: `users/${id}/friendwork`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return response.data;
	}

	async fetchDocuments(id: string) {
		const response = await makeRequest<GetUserDocumentsResponse>({
			url: `users/${id}/documents`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return response.data;
	}
	async createDocument(id: string, document: FormData) {
		const response = await makeRequest<GetUserDocumentResponse>({
			url: `users/${id}/documents`,
			method: 'POST',
			data: document,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			user_document: response.data.user_document,
		};
	}

	async updateDocument(id: string, documentId: string, document: FormData) {
		const response = await makeRequest<GetUserDocumentResponse>({
			url: `users/${id}/documents/${documentId}`,
			method: 'POST',
			data: document,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			user_document: response.data.user_document,
		};
	}


	async CreateUserToken(id: number) {
		const response = await makeRequest<GetUserToken>({
			url: 'create_token',
			method: 'post',
			data: { id },
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			token: response.data.token,
		};
	}

	async deleteDocument(documentId: string,userId: string) {
		return await makeRequest<GetUserDocumentResponse>({
			url: `users/${userId}/documents/${documentId}`,
			method: 'DELETE',
			auth: true,
		});
	}

}

export default new UserService();
