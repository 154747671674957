const getWordFromNumber = (
	number: number,
	firstWord: string,
	secondWord: string,
	thirdWord: string,
) => {
	if (number >= 5 && number <= 20) return firstWord;
	else if (number % 10 === 1 && number % 100 !== 11) return secondWord;
	else if (number % 10 > 1 && number % 10 < 5 && (number % 100 < 10 || number % 100 >= 20)) return thirdWord;
	else return firstWord;
};

export const getAnswersWord = (answers: number) => {
	return getWordFromNumber(answers, 'ответов', 'ответ', 'ответа');
};

export const getYearWord = (years: number) => {
	return getWordFromNumber(years, 'лет', 'год', 'года');
};

export const getParticipantWord = (participants: number) => {
	return getWordFromNumber(participants, 'участникам', 'участнику', 'участникам');
};
