import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { useStore } from 'hooks/useStore';
import { Checkbox, Switch } from 'components/UI';

import { ICalendarEventProps } from './types';

import styles from './CalendarEvents.module.scss';

const Sphere: FC<ICalendarEventProps> = observer(({ item, isSelected = false, selectElement }) => {
	const store = useStore();
	const navigate = useNavigate();
	const location = useLocation();

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update${location.search}`);
	};

	const onChangeCheckbox = () => {
		selectElement(item.id);
	};

	const changeSwitch = () => {
		item.is_active = !item.is_active;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		store.calendarEvents.updateItem(item);
	};

	const time = `с ${item.start_time}` + (item.finish_time ? ` до ${item.finish_time}` : '');

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<Checkbox checked={isSelected} onChange={onChangeCheckbox} />
			</div>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.name}</span>
			</div>
			<div className={styles.cell}>
				<span className={clsx(styles.time, 'wrap-text limit-line-text')}>
					{item.start_date} {item.finish_date && `- ${item.finish_date}`}, {time}
				</span>
			</div>
			<div className={clsx(styles.cell, styles.switchWrapper)} onClick={(e) => e.stopPropagation()}>
				<Switch checked={item.is_active} onChange={changeSwitch}/>
			</div>
			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Изменить</span>
				</div>
			</div>
		</div>
	);
});

export default Sphere;
