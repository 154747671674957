import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import ListPage from 'components/Layouts/ListPage/ListPage';
import CareerPracticesTableHeader from './CareerPracticesTableHeader';
import CareerPractice from './CareerPractice';

import styles from './Users.module.scss';

const CareerPractices: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.careerPractices}
			title='Практики профориентации'
			tableHeader={CareerPracticesTableHeader}
			tableClassName={styles.table}
			element={CareerPractice}
		/>
	);
});

export default CareerPractices;
