import { ActivityService } from 'API';

import { ListStore } from 'store/general/List/lIstStore';

import { IActivityRead } from 'interfaces/entities/IActivity';

class ActivitiesStore extends ListStore<IActivityRead, FormData> {
	constructor() {
		super({
			APIFetchItems: ActivityService.fetchActivities,
			APIFetchItem: ActivityService.fetchActivity,
			APICreateItem: ActivityService.createActivity,
			APIUpdateItem: ActivityService.updateActivity,
			APIRemoveItem: ActivityService.deleteActivity,
		});
	}

	async updateOrder(ids: number[]) {
		return await ActivityService.updateActivitiesOrder(ids);
	}
}

export default ActivitiesStore;
