import {careerGuidanceTypeOptions, TableHeader, THeaderField} from 'components/Layouts';

import styles from './CareerGuidances.module.scss';

const CareerGuidancesTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: null, hasSorting: false },
		{ label: 'ФИО', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Результат тестирования', name: 'test_result', type: 'select', options: careerGuidanceTypeOptions, hasSorting: true },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default CareerGuidancesTableHeader;
