import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import { Switch } from 'components/UI';

import { CareerToolProps } from './types';

import styles from './CareerTools.module.scss';

const CareerTool: FC<CareerToolProps> = observer(({ item }) => {
	const store = useStore();
	const navigate = useNavigate();
	const location = useLocation();

	const objectName = item.applicant_profile?.full_name;

	const typeTitle = item.career_tool_name;

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update${location.search}`);
	};

	const changeSwitch = () => {
		item.is_reviewed = !item.is_reviewed;
		store.careerTools.updateItem(item);
	};

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{typeTitle}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{objectName}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.applicant_profile?.phone}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.applicant_profile?.email}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.created_at}</span>
			</div>
			<div className={styles.switchWrapper} onClick={(e) => e.stopPropagation()}>
				<Switch checked={item.is_reviewed} onChange={changeSwitch} />
			</div>
			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Изменить</span>
				</div>
			</div>
		</div>
	);
});

export default CareerTool;
