import { IArticleRead, TArticleType } from 'interfaces/entities/IArticle';

export interface ArticleProps {
	item: IArticleRead;
	isSelected: boolean;
	selectElement: (id: number) => void;
}

export const typeOptions = [
	{
		label: 'Карьерные советы',
		value: TArticleType.CAREER_ADVICE,
	},
	{
		label: 'Молодежь островов',
		value: TArticleType.YOUTH_ISLANDS,
	},
	{
		label: 'Молодежные проекты',
		value: TArticleType.YOUTH_PROJECTS,
	},
	{
		label: 'Новости',
		value: TArticleType.NEWS,
	},
];
