import {
	GetDistributeToEmailsResponse,
	GetMailDistributionResponse,
	GetMailDistributionsResponse,
} from 'interfaces/api/MailDistributionResponse';
import { IMailDistributionCreate } from 'interfaces/entities/IMailDistribution';

import makeRequest from '../makeRequest';

export class MailDistributionService {
	async fetchMailDistributions() {
		const response = await makeRequest<GetMailDistributionsResponse>({
			url: 'mail_distributions',
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.mail_distributions,
			meta: response.data.meta,
		};
	}

	async fetchMailDistribution(id: string) {
		const response = await makeRequest<GetMailDistributionResponse>({
			url: `mail_distributions/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.mail_distribution,
		};
	}

	async createMailDistribution(mailDistribution: IMailDistributionCreate) {
		const response = await makeRequest<GetMailDistributionResponse>({
			url: 'mail_distributions',
			method: 'post',
			auth: true,
			data: mailDistribution,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.mail_distribution,
		};
	}

	async updateMailDistribution(mailDistribution: IMailDistributionCreate & { id: number }) {
		const response = await makeRequest<GetMailDistributionResponse>({
			url: `mail_distributions/${mailDistribution.id}`,
			method: 'put',
			auth: true,
			data: mailDistribution,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.mail_distribution,
		};
	}

	async deleteMailDistribution(id: number) {
		return await makeRequest<void>({
			url: `mail_distributions/${id}`,
			method: 'delete',
			auth: true,
		});
	}

	async distributeToEmails(id: number) {
		return await makeRequest<GetDistributeToEmailsResponse>({
			url: `mail_distributions/${id}/distribute`,
			method: 'post',
			auth: true,
		});
	}
}

export default new MailDistributionService();
