'use client';

import { FC, useState } from 'react';
import clsx from 'clsx';

import { Popover } from 'components/Logic';

import { PromptProps } from './types';

import { ReactComponent as IcClose } from 'assets/icons/general/ic_close.svg';
import { ReactComponent as IcInfo } from 'assets/icons/general/ic_tooltip.svg';
import styles from './Prompt.module.scss';

const Prompt: FC<PromptProps> = ({ title, description, color = 'blue', className = '' }) => {
	const [promptOpen, setPromptOpen] = useState(false);
	const [refIcon, setRefIcon] = useState<HTMLDivElement | null>(null);

	const onClose = () => setPromptOpen(false);

	const iconClasses = clsx(styles.icon, {
		[styles.grey]: color === 'grey',
		[styles.blue]: color === 'blue',
		[className]: !!className,
	});

	return (
		<>
			<div ref={setRefIcon} className={styles.iconWrapper}>
				<IcInfo onClick={() => setPromptOpen(true)} className={iconClasses} />
			</div>

			<Popover
				isOpen={promptOpen}
				refEl={refIcon}
				onClose={() => setPromptOpen(false)}
				className={styles.popover}
				offset={[0, 10]}
			>
				<div className={styles.header}>
					<h6 className={styles.title}>{title}</h6>
					<IcClose onClick={onClose} />
				</div>

				{description && <p className={styles.description}>{description}</p>}
			</Popover>
		</>
	);
};

export default Prompt;
