import { FC } from 'react';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import PracticeStudentElement from './PracticeStudent';
import PracticeStudentsTableHeader from './PracticeStudentsTableHeader';

import styles from './PracticeStudents.module.scss';
const PracticeStudents: FC = () => {
	const store = useStore();

	return (
		<ListPage
			store={store.practiceStudents}
			title='График практик студентов'
			tableHeader={PracticeStudentsTableHeader}
			tableClassName={styles.table}
			element={PracticeStudentElement}
			hasDelete={true}
			hasCreate={true}
			hasImport={true}
		/>
	);
};

export default PracticeStudents;
