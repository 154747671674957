import { TableHeader, THeaderField } from 'components/Layouts';

import styles from './SchoolUsers.module.scss';

const SchoolUsersTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Имя / название компании', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Почта', name: 'email', type: 'textField', hasSorting: true },
		{ label: '', name: '', type: null },
		{ label: '', name: '', type: null },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default SchoolUsersTableHeader;
