import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { CardsPage } from 'components/Layouts';
import SupportMeasureCard from './SupportMeasureCard';

const SupportMeasures: FC = observer(() => {
	const store = useStore();

	return (
		<CardsPage title={'Меры поддержки'} store={store.supportMeasure} element={SupportMeasureCard} />
	);
});

export default SupportMeasures;
