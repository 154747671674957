import { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';
import { roleOptions } from './types';

const CalendarEventUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.calendarEvents.element;

	const fetchData = useCallback(async () => {
		await store.calendarEvents.fetchItem(id);
	}, [id]);

	const fields: FieldsType[] = [
		{
			type: 'multiSelect',
			name: 'roles',
			value: item.roles ?? [],
			label: 'Роли',
			placeholder: '',
			options: roleOptions,
		},
		{
			type: 'textfield',
			name: 'name',
			value: item.name ?? '',
			label: 'Название',
			maxLength: 255,
		},
		{
			type: 'wysiwyg',
			name: 'description',
			value: item.description ?? '',
			label: 'Описание',
		},
		{
			type: 'textfield',
			name: 'location',
			value: item.location ?? '',
			label: 'Место проведения',
			maxLength: 255,
		},
		{
			type: 'datePicker',
			name: 'start_date',
			value: item.start_date ?? '',
			label: 'Дата начала',
		},
		{
			type: 'datePicker',
			name: 'finish_date',
			value: item.finish_date ?? '',
			label: 'Дата окончания',
		},
		{
			type: 'timeField',
			name: 'start_time',
			value: item.start_time ?? '',
			label: 'Время начала',
			placeholder: '17:00',
		},
		{
			type: 'timeField',
			name: 'finish_time',
			value: item.finish_time ?? '',
			label: 'Время окончания',
			placeholder: '19:00',
		},
		{
			type: 'datePicker',
			name: 'finish_registration_date',
			value: item.finish_registration_date ?? '',
			label: 'Конечная дата регистрации',
			placeholder: '25.02.2023',
		},
		{
			type: 'timeField',
			name: 'finish_registration_time',
			value: item.finish_registration_time ?? '',
			label: 'Конечное время регистрации',
		},
		{
			type: 'textFieldWithPrefix',
			name: 'link',
			prefix: 'https://',
			value: item.link ?? '',
			label: 'Ссылка',
		},
		{
			type: 'textfield',
			name: '',
			value: item.number_of_students ? item.number_of_students.toString() : '0',
			disabled: true,
			label: 'Записано людей',
		},
		{
			type: 'switch',
			name: 'is_active',
			value: item.is_active,
			label: 'Состояние',
		},
	];

	return (
		<UpdatePage
			title='Календарь / Изменение'
			fields={fields}
			store={store.calendarEvents}
			fetchData={fetchData}
		/>
	);
});

export default CalendarEventUpdate;
