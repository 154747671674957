import { CalendarEventService } from 'API';

import { ListStore } from 'store/general/List/lIstStore';
import { ICalendarEventCreate, ICalendarEventRead } from 'interfaces/entities/ICalendarEvent';

class CalendarEventStore extends ListStore<ICalendarEventRead, ICalendarEventCreate> {
	constructor() {
		super({
			APIFetchItems: CalendarEventService.fetchCalendarEvents,
			APIFetchItem: CalendarEventService.fetchCalendarEvent,
			APICreateItem: CalendarEventService.createCalendarEvent,
			APIUpdateItem: CalendarEventService.updateCalendarEvent,
			APIRemoveItem: CalendarEventService.deleteCalendarEvent,
		});
	}
}

export default CalendarEventStore;
