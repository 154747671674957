import { FC } from 'react';
import clsx from 'clsx';

import LinkHistoryItem from './MainItem/LinkHistoryItem';
import TextHistoryItem from './MainItem/TextHistoryItem';

import { checkHasContentInBlock } from '../uitls/checkHasContentInBlock';

import { ISecondaryBlockProps } from '../types';
import { THistoryItem } from './MainItem/types';

import styles from '../HistoryPage.module.scss';

const SecondarySection: FC<ISecondaryBlockProps> = ({ items, type }) => {
	const getBlockClasses = (items: THistoryItem[]) => {
		const hasOldValue = checkHasContentInBlock(items, 'oldValue');
		const hasNewValue = checkHasContentInBlock(items, 'newValue');

		return clsx(styles.blockItemList, {
			[styles.removeBlock]: type === 'oldValue' && hasOldValue && !hasNewValue,
			[styles.addBlock]: type === 'newValue' && hasNewValue && !hasOldValue,
		});
	};

	return (
		<ul className={styles.spheresList}>
			{items.map(
				(block) =>
					!!block.blocks.length && (
						<li key={block.header} className={styles.sphereItem}>
							<h4 className={styles.title}>{block.header}</h4>
							<section className={styles.sphereBlockList}>
								{block.blocks.map((items, index) => (
									<ul className={getBlockClasses(items)} key={index}>
										{items.map((item) =>
											'hasLink' in item ? (
												<LinkHistoryItem item={item} type={type} key={item.title} />
											) : (
												<TextHistoryItem item={item} type={type} key={item.title} />
											),
										)}
									</ul>
								))}
							</section>
						</li>
					),
			)}
		</ul>
	);
};

export default SecondarySection;
