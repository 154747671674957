import makeRequest from 'API/makeRequest';
import { GetReportResponse, GetReportsResponse } from 'interfaces/api/ReportResponse';
import { IReportRead } from 'interfaces/entities/IReport';

class ReportService {
	async fetchReports(query: string) {
		const response = await makeRequest<GetReportsResponse>({
			url: `reports?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.reports,
			meta: response.data.meta,
		};
	}

	async fetchReport(id: string) {
		const response = await makeRequest<GetReportResponse>({
			url: `reports/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.report,
		};
	}

	async updateReport(report: IReportRead) {
		const response = await makeRequest<GetReportResponse>({
			url: `reports/${report.id}`,
			method: 'put',
			auth: true,
			data: { is_reviewed: report.is_reviewed },
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.report,
		};
	}
}

export default new ReportService();
