import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

import { TEditorType } from 'interfaces/entities/IEditor';
import { typeOptions } from './types';

const ArticleCreate: FC = observer(() => {
	const store = useStore();

	const articlesFields: FieldsType[] = [
		{
			type: 'textfield',
			label: 'Заголовок',
			name: 'name',
			maxLength: 64,
		},
		{
			type: 'file',
			label: 'Изображение',
			name: 'image',
			maxSize: 1048576,
		},
		{
			type: 'textfield',
			name: 'description',
			label: 'Описание',
		},
		{
			type: 'select',
			name: 'type',
			label: 'Расположение',
			options: typeOptions,
		},
		{
			type: 'editor',
			name: 'content',
			label: 'Контент',
			tools: ['header', 'list', 'quote', 'image'],
			editorType: 'articles_wysiwyg' as TEditorType,
		},
	];

	return <CreatePage title={'Статьи / Создание'} fields={articlesFields} store={store.articles} />;
});

export default ArticleCreate;
