import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { Checkbox } from 'components/UI';

import { PracticeStudentProps } from './types';

import styles from './PracticeStudents.module.scss';

const PracticeStudentElements: FC<PracticeStudentProps> = observer(
	({ item, isSelected, selectElement }) => {
		const navigate = useNavigate();
		const location = useLocation();

		const onChangeCheckbox = () => {
			selectElement(item.id);
		};

		const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
			e.preventDefault();
			navigate(`${item.id}/update${location.search}`);
		};

		return (
			<div className={styles.row}>
				<div className={styles.cell}>
					<Checkbox checked={isSelected} onChange={onChangeCheckbox} />
				</div>
				<div className={styles.cell}>
					<span className='wrap-text limit-line-text'>{item.speciality.name}</span>
				</div>
				<div className={styles.cell}>
					<span className='wrap-text limit-line-text'>{item.institution_name}</span>
				</div>
				<div className={styles.cell}>
					<span>{item.start_date}</span>
				</div>
				<div className={styles.cell}>
					<span>{item.finish_date}</span>
				</div>
				<div className={styles.cell}>
					<span>{item.practice_type.substring(0, 10) + '.'}</span>
				</div>
				<div className={styles.cell} onClick={onLinkToUpdate}>
					<div className='edit-icon-block'>
						<span className='update-text'>Изменить</span>
					</div>
				</div>
			</div>
		);
	},
);

export default PracticeStudentElements;
