import { SoftwareProductService } from 'API';
import { ISoftwareProductRead, ISoftwareProductCreate } from 'interfaces/entities/ISoftwareProduct';
import { ListStore } from 'store/general/List/lIstStore';

class SoftwareProductStore extends ListStore<ISoftwareProductRead, ISoftwareProductCreate> {
	constructor() {
		super({
			APIFetchItems: SoftwareProductService.fetchSoftwareProducts,
			APIFetchItem: SoftwareProductService.fetchSoftwareProduct,
			APICreateItem: SoftwareProductService.createSoftwareProduct,
			APIUpdateItem: SoftwareProductService.updateSoftwareProduct,
			APIRemoveItem: SoftwareProductService.deleteSoftwareProduct,
		});
	}
}

export default SoftwareProductStore;
