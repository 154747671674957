import { FC, useEffect, useState } from 'react';

import { convertToRaw, ContentState, EditorState, convertFromHTML } from 'draft-js';
import draftjsToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';

import { IWysiwygProps } from './types';

import styles from './Wysiwyg.module.scss';

const Wysiwyg: FC<IWysiwygProps> = ({ label, value, onChange }) => {
	const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

	useEffect(() => {
		const blocksFromHTML = convertFromHTML(value) as any;
		const content = ContentState.createFromBlockArray(blocksFromHTML);
		setEditorState(EditorState.createWithContent(content));
	}, []);

	useEffect(() => {
		const editorValue = draftjsToHtml(convertToRaw(editorState.getCurrentContent()));
		onChange(editorValue);
	}, [editorState]);

	return (
		<div className={styles.container}>
			{label && <span>{label}</span>}

			<Editor
				editorState={editorState}
				onEditorStateChange={setEditorState}
				wrapperClassName={styles.wrapper}
				editorClassName={styles.editor}
				toolbarClassName={styles.toolbar}
				stripPastedStyles
			/>
		</div>
	);
};

export default Wysiwyg;
