import { FC } from 'react';

import { Button } from 'components/UI';
import { Popup } from 'components/Logic';

import { IPopupDeleteProps } from './types';

import styles from './Popup.module.scss';

const DeletePopup: FC<IPopupDeleteProps> = ({ isOpenPopup, closePopup, onDeleteItems }) => {
	return (
		<Popup isOpen={isOpenPopup} onClose={closePopup} className={styles.popup}>
			<div className={styles.container}>
				<span className={styles.title}>Удаление</span>
				<p className={styles.subtitle}>
					Вы уверены, что хотите безвозвратно удалить выбранные элементы?
				</p>

				<div className={styles.modalButtons}>
					<Button theme='outlined' onClick={closePopup}>
						Отмена
					</Button>
					<Button theme='filled' onClick={onDeleteItems}>
						Удалить
					</Button>
				</div>
			</div>
		</Popup>
	);
};

export default DeletePopup;
