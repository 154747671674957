import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

import { roles } from 'config/selectOptions';

const MailDistributionCardCreate: FC = observer(() => {
	const store = useStore();

	const mailDistributionsFields: FieldsType[] = [
		{
			type: 'select',
			name: 'role',
			label: 'Роль',
			options: roles,
		},
		{
			type: 'textfield',
			label: 'Название',
			maxLength: 100,
			name: 'title',
		},
		{
			type: 'textfield',
			name: 'theme',
			label: 'Заголовок письма',
			maxLength: 100,
		},
		{
			type: 'textarea',
			name: 'message',
			label: 'Текст',
		},
	];

	return (
		<CreatePage
			title={'Рассылка писем / Создание'}
			fields={mailDistributionsFields}
			store={store.mailDistributions}
		/>
	);
});

export default MailDistributionCardCreate;
