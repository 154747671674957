import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import Vacancy from './Vacancy';
import VacanciesTableHeader from './VacanciesTableHeader';

import styles from './Vacancies.module.scss';

const Vacancies: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.vacancies}
			title='Вакансии'
			tableHeader={VacanciesTableHeader}
			tableClassName={styles.table}
			element={Vacancy}
			hasDelete={false}
			hasCreate={false}
		/>
	);
});

export default Vacancies;
