import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserProps } from './types';

import styles from './SchoolUsers.module.scss';

const SchoolUser: FC<UserProps> = observer(({ item }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/read${location.search}`);
	};

	const onLinkToQualifications = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`/documents/${item.id}/update${location.search}`);
	};

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>
					{item.applicant_profile?.first_name + ' ' + item.applicant_profile?.last_name}
				</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.email}</span>
			</div>
			<div className={styles.cell} onClick={onLinkToQualifications}>
				<div className='edit-icon-block'>
					<span className='update-text'>Документы</span>
				</div>
			</div>
			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Смотреть</span>
				</div>
			</div>
		</div>
	);
});

export default SchoolUser;
