import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import Report from './Report';
import ReportsTableHeader from './ReportsTableHeader';

import styles from './Reports.module.scss';

const Reports: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.reports}
			title='Жалобы'
			tableHeader={ReportsTableHeader}
			tableClassName={styles.table}
			element={Report}
			hasDelete={false}
			hasCreate={false}
		/>
	);
});

export default Reports;
