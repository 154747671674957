import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import DocumentForm from './DocumentForm';

import { DocumentItemProps } from './types';

import { ReactComponent as IcFile } from 'assets/icons/form/ic_file.svg';
import { ReactComponent as IcClose } from 'assets/icons/general/ic_close.svg';
import { ReactComponent as IcEdit } from 'assets/icons/form/ic_edit.svg';

import styles from './Documents.module.scss';

const DocumentItem: FC<DocumentItemProps> = observer(({ userId, document, onDelete }) => {
	const [isEditing, setIsEditing] = useState(false);

	const onClickEdit = () => {
		setIsEditing(true);
	};

	const getStatusClassName = clsx({
		[styles.notChecked]: document?.status === 'Не просмотрено',
		[styles.checked]: document?.status === 'Просмотрено',
		[styles.downloaded]: document?.status === 'Скачано',
	});

	const size = document.file_size === 0 ? 0.01: document.file_size;


	return (
		<>
			{isEditing ? (
				<DocumentForm
					userId={userId}
					type={document.type}
					document={document}
					onClose={() => setIsEditing(false)}
				/>
			) : (
				<li className={styles.docWrapper}>
					<div className={styles.doc}>
						<div className={styles.filegroup}>
							<IcFile className={styles.file} />
							<span className={styles.title}>{document.name}</span>
						</div>
						<div className={styles.btns}>
							<span className={styles.size}>{size} MB</span>
							{!isEditing && <IcEdit className={styles.edit} onClick={onClickEdit} />}
							<IcClose className={styles.close} onClick={onDelete} />
						</div>
					</div>
					{document?.status && <span><p className={getStatusClassName}></p>{document.status}</span>}
				</li>
			)}
		</>
	);
});

export default DocumentItem;
