import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

const EventCardCreate: FC = observer(() => {
	const store = useStore();

	const eventsFields: FieldsType[] = [
		{
			type: 'file',
			label: 'Картинка',
			name: 'image',
		},
		{
			type: 'textfield',
			label: 'Заголовок',
			maxLength: 60,
			name: 'name',
		},
		{
			type: 'textfield',
			name: 'description',
			label: 'Текст',
			maxLength: 100,
		},
		{
			type: 'textFieldWithPrefix',
			prefix: 'https://',
			name: 'link',
			label: 'Ссылка',
		},
	];

	return (
		<CreatePage
			title={'Важные мероприятия / Создание'}
			fields={eventsFields}
			store={store.events}
		/>
	);
});

export default EventCardCreate;
