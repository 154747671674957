import { FC } from 'react';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

import { TLocationSphere } from 'interfaces/entities/ISphere';
import { locationOptions } from './types';

const SphereCreate: FC = () => {
	const store = useStore();

	const schoolShowCondition = {
		name: 'locations',
		value: ['Школьник'] as TLocationSphere[],
	};

	const IslandsAndSchoolShowCondition = {
		name: 'locations',
		value: ['Острова Развития', 'Школьник'] as TLocationSphere[],
	};

	const allShowCondition = {
		name: 'locations',
		value: [
			'Острова Развития',
			'Школьник',
			'Вакансии инвест проектов, Компании, Курсы',
		] as TLocationSphere[],
	};

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'name',
			value: '',
			label: 'Название',
			maxLength: 48,
		},
		{
			type: 'multiSelect',
			name: 'locations',
			value: [],
			label: 'Расположение',
			placeholder: '',
			options: locationOptions,
		},
		{
			type: 'wysiwyg',
			name: 'description',
			value: '',
			label: 'Описание',
			showCondition: schoolShowCondition,
		},
		{
			type: 'file',
			name: 'image',
			label: 'Изображение',
			showCondition: IslandsAndSchoolShowCondition,
			maxSize: 1_048_576,
		},
		{
			type: 'textFieldWithCrumbs',
			name: 'projects',
			value: [],
			label: 'Проекты',
			showCondition: schoolShowCondition,
		},
		{
			type: 'textFieldWithCrumbs',
			name: 'operator_companies',
			value: [],
			label: 'Компании операторы ',
			showCondition: schoolShowCondition,
		},
		{
			type: 'textFieldWithCrumbs',
			name: 'useful_resources',
			value: [],
			label: 'Ссылки на полезные ресурсы',
			placeholder: 'https://example.com',
			showCondition: schoolShowCondition,
		},
		{
			type: 'switch',
			name: 'is_active',
			value: true,
			label: 'Состояние',
			showCondition: allShowCondition,
		},
	];
	if (store.spheres.createItem === undefined) return null;

	return <CreatePage title='Сферы / Создание' fields={fields} store={store.spheres} />;
};

export default SphereCreate;
