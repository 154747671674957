import clsx from 'clsx';

import { THeaderField } from '../types';
import { IAdditionalSlotsProps } from './types';

import tableHeaderFieldStyles from '../TableHeaderField/TableHeaderField.module.scss';
import styles from './AdditionalSlots.module.scss';

const AdditionalSlots = ({ fields, additionalSlots, className }: IAdditionalSlotsProps) => {
	if (!additionalSlots.length) return null;

	const getSlotContent = (field: THeaderField) => {
		const slot = additionalSlots.find((slot) => slot.columnName === field.name);

		if (slot) return slot.node;

		return null;
	};

	const columnsClasses = clsx(styles.row, {
		[className]: !!className,
	});

	return (
		<ul className={columnsClasses}>
			{fields.map((field, index) => (
				<div className={tableHeaderFieldStyles.column} key={index}>
					{getSlotContent(field)}
				</div>
			))}
		</ul>
	);
};

export default AdditionalSlots;
