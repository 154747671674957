import { FC } from 'react';

import { FieldsType } from 'components/Logic/FieldByType/types';
import { useStore } from 'hooks/useStore';
import { CreatePage } from 'components/Layouts';

import { roleOptions } from './types';

const CalendarEventCreate: FC = () => {
	const store = useStore();

	const fields: FieldsType[] = [
		{
			type: 'multiSelect',
			name: 'roles',
			value: [],
			label: 'Роли',
			placeholder: '',
			options: roleOptions,
		},
		{
			type: 'textfield',
			name: 'name',
			value: '',
			label: 'Название',
			maxLength: 255,
		},
		{
			type: 'wysiwyg',
			name: 'description',
			value: '',
			label: 'Описание',
		},
		{
			type: 'textfield',
			name: 'location',
			value: '',
			label: 'Место проведения',
			maxLength: 255,
		},
		{
			type: 'datePicker',
			name: 'start_date',
			value: '',
			label: 'Дата начала',
			placeholder: '25.02.2023',
		},
		{
			type: 'datePicker',
			name: 'finish_date',
			value: '',
			label: 'Дата окончания',
			placeholder: '25.02.2023',
		},
		{
			type: 'timeField',
			name: 'start_time',
			value: '',
			label: 'Время начала',
		},
		{
			type: 'timeField',
			name: 'finish_time',
			value: '',
			label: 'Время окончания',
		},
		{
			type: 'datePicker',
			name: 'finish_registration_date',
			value: '',
			label: 'Конечная дата регистрации',
			placeholder: '25.02.2023',
		},
		{
			type: 'timeField',
			name: 'finish_registration_time',
			value: '',
			label: 'Конечное время регистрации',
		},
		{
			type: 'textFieldWithPrefix',
			name: 'link',
			prefix: 'https://',
			value: '',
			label: 'Ссылка',
		},
		{
			type: 'switch',
			name: 'is_active',
			value: true,
			label: 'Состояние',
		},
	];

	if (store.calendarEvents.createItem === undefined) return null;

	return <CreatePage title='Календарь / Создание' fields={fields} store={store.calendarEvents} />;
};

export default CalendarEventCreate;
