import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { AnswerProps } from './types';
import styles from './Answers.module.scss';

const Answer: FC<AnswerProps> = observer(({ item }) => {
	const navigate = useNavigate();

	const onLinkToRead = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/read`);
	};

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.applicant_profile.full_name}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.applicant_profile.phone}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.applicant_profile.email}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.applicant_profile.status.name}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.updated_at}</span>
			</div>
			<div className={styles.cell} onClick={onLinkToRead}>
				<div className='edit-icon-block'>
					<span className='update-text'>Просмотр</span>
				</div>
			</div>
		</div>
	);
});

export default Answer;
