import makeRequest from 'API/makeRequest';
import {
	GetPracticeStudentsResponse,
	GetPracticeStudentResponse,
} from 'interfaces/api/PracticeStudentResponse';
import { TPracticeStudentCreate } from 'interfaces/entities/IPracticeStudent';

class PracticeStudentService {
	async fetchPracticeStudents(query: string) {
		const response = await makeRequest<GetPracticeStudentsResponse>({
			url: `practices?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.practices,
			meta: response.data.meta,
		};
	}

	async fetchCities(query: string) {
		const response = await makeRequest<GetPracticeStudentsResponse>({
			url: `cities?query=${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.practices,
			meta: response.data.meta,
		};
	}

	async fetchPracticeStudent(id: string) {
		const response = await makeRequest<GetPracticeStudentResponse>({
			url: `practices/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.practice,
		};
	}

	uploadPractices(practices: FormData) {
		return makeRequest<void>({
			url: 'import/practices',
			method: 'post',
			data: practices,
			auth: true,
		});
	}

	async createPracticeStudent(sphere: TPracticeStudentCreate) {
		const response = await makeRequest<GetPracticeStudentResponse>({
			url: 'practices',
			method: 'post',
			auth: true,
			data: sphere,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.practice,
		};
	}

	async updatePracticeStudent(sphere: TPracticeStudentCreate & { id: number }) {
		const response = await makeRequest<GetPracticeStudentResponse>({
			url: `practices/${sphere.id}`,
			method: 'put',
			auth: true,
			data: sphere,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.practice,
		};
	}

	deletePracticeStudent(id: number) {
		return makeRequest<void>({
			url: `practices/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new PracticeStudentService();
