import { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';
import { educationOptions } from './types';

const ProfessionUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.professions.element;

	const fetchData = useCallback(async () => {
		await store.professions.fetchItem(id);
	}, [id]);

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'name',
			value: item.name,
			label: 'Название',
		},
		{
			type: 'select',
			name: 'education_type',
			value: { value: item.education_type, label: item.education_type },
			label: 'Образование ',
			placeholder: '',
			options: educationOptions,
		},
		{
			type: 'wysiwyg',
			name: 'description',
			value: item.description || '',
			label: 'Описание',
		},
		{
			type: 'switch',
			name: 'is_active',
			value: item.is_active,
			label: 'Состояние',
		},
	];

	return (
		<UpdatePage
			title='Профессии / Изменение'
			fields={fields}
			store={store.professions}
			fetchData={fetchData}
		/>
	);
});

export default ProfessionUpdate;
