import { FC } from 'react';

import { PageHeader } from 'components/Layouts/';

import { CustomPageProps } from './types';

import styles from './CustomPage.module.scss';

const CustomPage: FC<CustomPageProps> = ({
	title = '',
	backLink,
	bottomButtons = [],
	children,
}) => {
	return (
		<section className={styles.createPage}>
			<PageHeader title={title} backLink={backLink} />

			<div className={styles.content}>{children}</div>

			{bottomButtons.length !== 0 && (
				<div className={styles.btns}>{bottomButtons.map((button) => button)}</div>
			)}
		</section>
	);
};

export default CustomPage;
