import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import styles from './UpdatePage.module.scss';
import { PageHeader } from '../../components/Layouts';
import { Loader } from '../../components/UI';
import UpdateForm from './UpdateForm';

const SupportMeasureUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.supportMeasure.element;
	const fetchData = useCallback(async () => {
		if (id) {
			await store.supportMeasure.fetchItem(id);
		}
	}, [id]);

	const [isLoading, setLoading] = useState(true);
	const [isRequesting, setIsRequesting] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (typeof fetchData === 'function') {
			setLoading(true);

			fetchData?.()
				.catch((error) => console.log(error))
				.finally(() => setLoading(false));
		} else {
			setLoading(false);
		}
	}, [fetchData]);

	const backLink = location.pathname.slice(0, -7) + location.search;

	if (isLoading) {
		return (
			<section className={styles.updatePage}>
				<PageHeader title={'Меры поддержки / Изменение'} backLink={'/support_measures'} />

				<Loader />
			</section>
		);
	}

	return (
		<section key={id} className={styles.updatePage}>
			<PageHeader title={'Меры поддержки / Изменение'} backLink={'/support_measures'} />

			<UpdateForm fetchData={fetchData} item={item} />
		</section>
	);
});
export default SupportMeasureUpdate;
