import { ProfessionService } from 'API';
import { ListStore } from 'store/general/List/lIstStore';
import { IProfessionCreate, IProfessionRead } from 'interfaces/entities/IProfession';
import { IProfessionStore } from './types';

class ProfessionStore
	extends ListStore<IProfessionRead, IProfessionCreate>
	implements IProfessionStore
{
	idCurrentSphere: number | null = null;

	constructor() {
		super({
			APIFetchItems: (query) => ProfessionService.fetchProfessions(this.idCurrentSphere!, query),
			APIFetchItem: (id) => ProfessionService.fetchProfession(this.idCurrentSphere!, id),
			APICreateItem: (profession) =>
				ProfessionService.createProfession(this.idCurrentSphere!, profession),
			APIUpdateItem: (profession) =>
				ProfessionService.updateProfession(this.idCurrentSphere!, profession),
			APIRemoveItem: (id) => ProfessionService.deleteProfession(this.idCurrentSphere!, id),
		});
	}

	setIdCurrentSphere(id: number) {
		this.idCurrentSphere = id;
	}
}

export default ProfessionStore;
