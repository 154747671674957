import PavilionService from 'API/rest/PavilionService';

import { ListStore } from 'store/general/List/lIstStore';
import { IPavilionCreate, IPavilionRead } from 'interfaces/entities/IPavilion';

class PavilionStore extends ListStore<IPavilionRead, IPavilionCreate> {
	constructor() {
		super({
			APIFetchItems: PavilionService.fetchPavilions,
			APIFetchItem: PavilionService.fetchPavilion,
			APICreateItem: PavilionService.createPavilion,
			APIUpdateItem: PavilionService.updatePavilion,
			APIRemoveItem: PavilionService.deletePavilion,
		});
	}
}

export default PavilionStore;
