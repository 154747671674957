import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import { Checkbox, Switch } from 'components/UI';

import { dynamicLinks } from 'config/routingLinks';

import { TLocationSphere } from 'interfaces/entities/ISphere';
import { SphereProps } from './types';

import styles from './Spheres.module.scss';

const Sphere: FC<SphereProps> = observer(({ item, isSelected = false, selectElement }) => {
	const store = useStore();
	const navigate = useNavigate();
	const location = useLocation();

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update${location.search}`);
	};

	const onLinkToProfessions = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();

		const windowReference = window.open();
		if (windowReference) {
			windowReference.name = '_blank';
			windowReference.location = dynamicLinks(item.id).professions;
		}
	};

	const onChangeCheckbox = () => {
		selectElement(item.id);
	};

	const changeSwitch = () => {
		item.is_active = !item.is_active;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		store.spheres.updateItem(item.image_url ? { ...item, has_file: 1 } : item);
	};

	return (
		<>
			<div className={styles.row}>
				<div className={styles.cell}>
					<Checkbox checked={isSelected} onChange={onChangeCheckbox} />
				</div>
				<div className={styles.cell}>
					<span>{item.id}</span>
				</div>
				<div className={styles.cell}>
					<span className='wrap-text limit-line-text'>{item.name}</span>
				</div>
				<div className={styles.switchWrapper} onClick={(e) => e.stopPropagation()}>
					<Switch checked={item.is_active} onChange={changeSwitch} />
				</div>
				<div className={styles.cell} onClick={onLinkToUpdate}>
					<div className='edit-icon-block'>
						<span className='update-text'>Изменить</span>
					</div>
				</div>

				{item.locations.includes('Острова Развития' as TLocationSphere) && (
					<div className={styles.cell} onClick={onLinkToProfessions}>
						<div className='edit-icon-block'>
							<span className='update-text'>Открыть</span>
						</div>
					</div>
				)}
			</div>
		</>
	);
});

export default Sphere;
