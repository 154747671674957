import makeRequest from '../makeRequest';
import { GetArticleResponse, GetArticlesResponse } from 'interfaces/api/ArticleResponse';

class ArticleService {
	async fetchArticles(query: string) {
		const response = await makeRequest<GetArticlesResponse>({
			url: `articles?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.articles,
			meta: response.data.meta,
		};
	}

	async fetchArticle(id: string) {
		const response = await makeRequest<GetArticleResponse>({
			url: `articles/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.article,
		};
	}

	async createArticle(article: FormData) {
		const response = await makeRequest<GetArticleResponse>({
			url: 'articles',
			method: 'post',
			auth: true,
			data: article,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.article,
		};
	}

	async updateArticle(article: FormData) {
		const response = await makeRequest<GetArticleResponse>({
			url: `articles/${article.get('id')}`,
			method: 'post',
			auth: true,
			data: article,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.article,
		};
	}

	deleteArticle(id: number) {
		return makeRequest<void>({
			url: `articles/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new ArticleService();
