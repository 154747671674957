import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { PavilionProps } from './types';

import styles from './Pavilions.module.scss';

const Pavilion: FC<PavilionProps> = observer(({ item }) => {
	const navigate = useNavigate();

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update`);
	};

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span className='limit-line-text wrap-text'>{item.name}</span>
			</div>
			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Изменить</span>
				</div>
			</div>
		</div>
	);
});

export default Pavilion;
