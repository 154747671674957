import makeRequest from 'API/makeRequest';
import {
	GetCareerPracticeQrResponse,
	GetCareerPracticeQrsResponse,
	GetCareerPracticeResponse,
	GetCareerPracticesResponse,
} from 'interfaces/api/CareerPracticeResponse';
import { ICareerPracticeCreate } from 'interfaces/entities/ICareerPractice';

class CareerPracticesService {
	async fetchPractices(query: string) {
		const response = await makeRequest<GetCareerPracticesResponse>({
			url: `career_practices?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.career_practices,
			meta: response.data.meta,
		};
	}

	async fetchPractice(id: string) {
		const response = await makeRequest<GetCareerPracticeResponse>({
			url: `career_practices/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.career_practice,
		};
	}

	async createPractice(practice: ICareerPracticeCreate) {
		const response = await makeRequest<GetCareerPracticeResponse>({
			url: 'career_practices',
			method: 'post',
			auth: true,
			data: practice,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.career_practice,
		};
	}

	async updatePractice(profession: (ICareerPracticeCreate & { id: number }) | FormData) {
		const id = 'id' in profession ? profession.id : profession.get('id');

		const response = await makeRequest<GetCareerPracticeResponse>({
			url: `career_practices/${id}`,
			method: 'post',
			auth: true,
			data: profession,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.career_practice,
		};
	}

	deletePractice(id: number) {
		return makeRequest<void>({
			url: `career_practices/${id}`,
			method: 'delete',
			auth: true,
		});
	}

	// qrs
	async fetchQrs(id: number) {
		const response = await makeRequest<GetCareerPracticeQrsResponse>({
			url: `career_practices/${id}/qr_codes`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return response.data;
	}

	async createQr(id: number, qr: FormData) {
		const response = await makeRequest<GetCareerPracticeQrResponse>({
			url: `career_practices/${id}/qr_codes`,
			method: 'POST',
			data: qr,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			qr_code: response.data.qr_code,
		};
	}

	async updateQr(id: number, qrId: number, qr: FormData) {
		const response = await makeRequest<GetCareerPracticeQrResponse>({
			url: `career_practices/${id}/qr_codes/${qrId}`,
			method: 'POST',
			data: qr,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			qr_code: response.data.qr_code,
		};
	}

	async deleteQr(id: number, qrId: number) {
		return await makeRequest<GetCareerPracticeQrResponse>({
			url: `career_practices/${id}/qr_codes/${qrId}`,
			method: 'DELETE',
			auth: true,
		});
	}
}

export default new CareerPracticesService();
