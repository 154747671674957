import { FieldsType } from 'components/Logic/FieldByType/types';

interface ICreateRequestDataArgs {
	values: any;
	fields: FieldsType[];
}

export const createRequestData = ({ values, fields }: ICreateRequestDataArgs) => {
	const request = { ...values };
	const formData = new FormData();
	const needFormData = !!fields.find((field) => field.type === 'file');

	Object.keys(request).forEach((keyCurrentField) => {
		const foundField = fields.find((field) => field.name === keyCurrentField);

		if (foundField?.type === 'select') {
			if (Array.isArray(values[keyCurrentField])) {
				request[keyCurrentField] = values[keyCurrentField]?.map(
					(item: { label: string; value: string }) => item.value,
				);
			} else {
				request[keyCurrentField] = values[keyCurrentField].value;
				formData.append(keyCurrentField, values[keyCurrentField].value);
			}
		} else if (foundField?.type === 'file') {
			if (values[keyCurrentField] && typeof values[keyCurrentField].name === 'string') {
				formData.append(keyCurrentField, values[keyCurrentField]);
			}
		} else if (foundField?.type === 'switch' && needFormData) {
			formData.append(foundField.name, values[foundField.name] ? '1' : '0');
		} else if (
			foundField?.type &&
			['textFieldWithCrumbs', 'multiSelect'].includes(foundField.type) &&
			needFormData
		) {
			!values[keyCurrentField]
				? formData.append(foundField.name, '')
				: values[keyCurrentField]?.forEach((value: any) =>
						formData.append(`${foundField.name}[]`, value),
				  );
		} else if (foundField?.type === 'editor') {
			const value = values[keyCurrentField] ? JSON.stringify(values[keyCurrentField]) : '';

			needFormData ? formData.append(foundField.name, value) : (request[keyCurrentField] = value);
		} else if (foundField?.type === 'wysiwyg') {
			const value = ['', '<p></p>\n'].includes(values[keyCurrentField])
				? ''
				: values[keyCurrentField];

			needFormData ? formData.append(foundField.name, value) : (request[keyCurrentField] = value);
		} else {
			formData.append(keyCurrentField, values[keyCurrentField]);
		}
	});

	if (values.url && needFormData && !values.image) {
		formData.append('has_file', values.url ? '1' : '0');
	}

	return {
		needFormData,
		request,
		formData,
	};
};
