import { FC } from 'react';

import { LinkBtn } from 'components/UI';

import { staticLinks } from 'config/routingLinks';

import { ReactComponent as IcNotFoundMessages } from 'assets/icons/general/ic_not-found-messgages.svg';
import styles from './UnpaidEmailSubscription.module.scss';

const UnpaidEmailSubscription: FC = () => {
	return (
		<section className={styles.wrapper}>
			<IcNotFoundMessages />

			<div className={styles.content}>
				<h1 className={styles.title}>Ошибка отправки писем</h1>
				<p className={styles.description}>
					Похоже что средства на сервисе рассылки закончились. Пополните баланс и попробуйте позже.
				</p>

				<LinkBtn to={staticLinks.mailDistributions} theme='filled' className={styles.button}>
					Вернуться обратно
				</LinkBtn>
			</div>
		</section>
	);
};

export default UnpaidEmailSubscription;
