import { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';

const ChampionshipUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.championships.element;

	const fetchData = useCallback(async () => {
		await store.championships.fetchItem(id);
	}, [id]);

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'name',
			value: item.name,
			label: 'Название чемпионата',
		},
		{
			type: 'switch',
			name: 'is_active',
			value: item.is_active,
			label: 'Состояние',
		},
	];

	return (
		<UpdatePage
			title='Чемпионаты / Изменение'
			fields={fields}
			store={store.championships}
			fetchData={fetchData}
		/>
	);
});

export default ChampionshipUpdate;
