import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ApprovePage } from 'components/Layouts';
import { LinkBtn } from 'components/UI';

import { staticLinks } from 'config/routingLinks';

const CourseUpdate: FC = observer(() => {
	const store = useStore();
	const [isLoading, setLoading] = useState(true);
	const { id } = useParams();
	const item = store.courses.element;

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			if (id !== undefined) {
				await store.courses.fetchItem(id);
			}
			setLoading(false);
		};
		fetchData();
	}, [id]);

	const onChangeApproved = (value: boolean | null) => {
		store.courses.updateItem(item.id.toString(), value);
	};

	const mainInfo = [
		{ title: 'ID курса', text: item.id },
		{ title: 'Дата создания', text: item.created_at },
		{ title: 'Дата обновления', text: item.updated_at },
		{ title: 'Название курса', text: item.name },
		{ title: 'Сфера', text: item.sphere?.name },
		{ title: 'Формат обучени', text: item.education_format },
		{ title: 'Адрес', text: item.address },
		{ title: 'Количество часов', text: item.hours },
		{ title: 'Стоимость', text: item.cost },
		{ title: 'Уровень образования', text: item.education_type },
		{ title: 'Ссылка на курс', text: item.link },
	];

	const secondaryInfo = [
		{
			header: 'Компания',
			blocks: [
				[
					{
						title: 'ID',
						text: item.employer_profile?.id,
					},
					{
						title: 'Название',
						text: item.employer_profile?.employer_name,
					},
				],
			],
		},
		{
			header: 'Подробности курса',
			blocks: [
				[
					{
						title: 'Проф. навыки',
						text: item.skills?.join(', '),
					},
					{
						title: 'Описание',
						text: parse(item.description ?? '') as string,
					},
				],
			],
		},
	];

	const additionalButtons = (
		<LinkBtn
			to={staticLinks.employers + '/' + item.employer_profile?.id + '/update'}
			theme='outlined'
			rel='noreferrer'
			target='_blank'
		>
			Профиль предприятия
		</LinkBtn>
	);

	return (
		<ApprovePage
			isLoading={isLoading}
			title={'Курсы / Одобрение'}
			mainInfo={mainInfo}
			secondaryInfo={secondaryInfo}
			onUpdate={onChangeApproved}
			currentValue={item.is_approved}
			additionalButtons={additionalButtons}
		/>
	);
});

export default CourseUpdate;
