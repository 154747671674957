import React, { FC } from 'react';
import clsx from 'clsx';

import { ValidationProps } from './types';

import styles from './Validation.module.scss';

const Validation: FC<ValidationProps> = ({ customValidation, touched, errors, className = '' }) => {
	const formikStyles = clsx(styles.formikValidation, {
		[className]: className,
	});

	const validation = customValidation || touched || errors;

	if (!validation) return null;

	return (
		<div className={formikStyles} data-form-error-validation>
			{errors}
		</div>
	);
};

export default Validation;
