import { ListStore } from 'store/general/List/lIstStore';
import { IArticleRead } from 'interfaces/entities/IArticle';
import ArticleService from 'API/rest/ArticleService';

class ArticleStore extends ListStore<IArticleRead, FormData> {
	constructor() {
		super({
			APIFetchItems: ArticleService.fetchArticles,
			APIFetchItem: ArticleService.fetchArticle,
			APICreateItem: ArticleService.createArticle,
			APIUpdateItem: ArticleService.updateArticle,
			APIRemoveItem: ArticleService.deleteArticle,
		});
	}
}

export default ArticleStore;
