import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import { Checkbox, Switch } from 'components/UI';

import { SpecialityProps } from './types';

import styles from './Specialities.module.scss';

const Speciality: FC<SpecialityProps> = observer(({ item, isSelected = false, selectElement }) => {
	const store = useStore();
	const navigate = useNavigate();
	const location = useLocation();

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update${location.search}`);
	};

	const onChangeCheckbox = () => {
		selectElement(item.id);
	};

	const onClickSwitch = () => {
		item.is_active = !item.is_active;
		store.specialities.updateItem(item);
	};

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<Checkbox checked={isSelected} onChange={onChangeCheckbox} />
			</div>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.name}</span>
			</div>
			<div className={styles.switchWrapper} onClick={(e) => e.stopPropagation()}>
				<Switch checked={item.is_active} onChange={onClickSwitch} />
			</div>
			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Изменить</span>
				</div>
			</div>
		</div>
	);
});

export default Speciality;
