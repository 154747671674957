import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { Button, LinkBtn, Loader } from 'components/UI';
import { DeletePopup } from 'components/Logic';
import { FieldList, PageHeader } from 'components/Layouts';
import { AsyncButton } from 'components/Form';

import { createInitialValues } from 'utils/createInitialValues';
import { createRequestData } from 'utils/createRequestData';
import { UpdatePageProps } from './types';
import { globalFormikConfig } from 'config/globalFormikConfig';

import { ReactComponent as IcDelete } from 'assets/icons/general/ic_trash.svg';
import styles from './UpdatePage.module.scss';

const UpdatePage: FC<UpdatePageProps> = ({
	title = '',
	fields = [],
	store,
	fetchData,
	additionalButtons,
}) => {
	const [isLoading, setLoading] = useState(true);
	const [isRequesting, setIsRequesting] = useState(false);
	const [modalIsOpen, setModalOpen] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (typeof fetchData === 'function') {
			setLoading(true);

			fetchData?.()
				.catch((error) => console.log(error))
				.finally(() => setLoading(false));
		} else {
			setLoading(false);
		}
	}, [fetchData]);

	const backLink = location.pathname.split('/').slice(0, -2).join('/') + location.search;

	const formik = useFormik({
		...globalFormikConfig,
		initialValues: { ...createInitialValues(fields), id },
		enableReinitialize: true,
		onSubmit: async (values) => {
			const { needFormData, formData, request } = createRequestData({ values, fields });

			return await store.updateItem?.(needFormData ? formData : request).catch((errors: any) => {
				formik.setErrors(errors.errors);
				throw errors;
			});
		},
	});

	const onSaveAndClose = async () => {
		try {
			setIsRequesting(true);
			const response = await formik.submitForm();
			if ('errors' in response) {
				formik.setErrors(response.errors);
			} else {
				navigate(backLink);
			}
		} catch (e) {
			console.error(e);
		} finally {
			setIsRequesting(false);
		}
	};
	const openDeleteModal = () => setModalOpen(true);
	const closeDeleteModal = () => setModalOpen(false);

	const onDeleteItem = () => {
		closeDeleteModal();

		store
			.removeItem?.(id)
			.then(() => navigate(backLink))
			.catch((errors: any) => {
				formik.setErrors(errors.errors);
				throw errors;
			});
	};

	if (isLoading) {
		return (
			<section className={styles.updatePage}>
				<PageHeader title={title} backLink={backLink} />
				<Loader />
			</section>
		);
	}

	return (
		<section className={styles.updatePage}>
			<PageHeader title={title} backLink={backLink} />

			<form onSubmit={formik.handleSubmit} className={styles.form}>
				<FieldList formik={formik} fields={fields} />

				<div className={styles.btns}>
					<AsyncButton isLoading={isRequesting} type='button' onClick={onSaveAndClose}>
						Сохранить
					</AsyncButton>

					<LinkBtn to={backLink} theme='outlined'>
						Отмена
					</LinkBtn>

					{additionalButtons}

					<Button theme='danger' className={styles.delete} type='button' onClick={openDeleteModal}>
						<IcDelete />
					</Button>
				</div>
			</form>

			<DeletePopup
				onDeleteItems={onDeleteItem}
				isOpenPopup={modalIsOpen}
				closePopup={closeDeleteModal}
			/>
		</section>
	);
};

export default UpdatePage;
