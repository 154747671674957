import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { CustomPage } from 'components/Layouts';
import { Button, LinkBtn } from 'components/UI';
import { DeletePopup } from 'components/Logic';
import CareerPracticeQrItem from './CareerPracticeQrItem';
import CareerPracticeQrForm from './CareerPracticeQrForm';

import { staticLinks } from 'config/routingLinks';

import { ICareerPracticeQrRead } from 'interfaces/entities/ICareerPractice';

import styles from './CareerPracticeQrs.module.scss';

const CareerPracticeQrs: FC = observer(() => {
	const store = useStore();
	const careerPracticesStore = store.careerPractices;

	const [isCreating, setIsCreating] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [currentItem, setCurrentItem] = useState<ICareerPracticeQrRead | null>(null);

	const { id: careerPractice } = useParams();
	if (!careerPractice) return null;

	useEffect(() => {
		careerPracticesStore.fetchQrs(parseInt(careerPractice));
	}, []);

	const maxQrs = 4;
	const backLink = staticLinks.careerPractices;

	const buttons = [
		<LinkBtn to={backLink} theme='filled' key='close'>
			Закрыть
		</LinkBtn>,
	];

	const onAdd = () => setIsCreating(true);

	const onCloseModal = () => {
		setIsOpenModal(false);
		setCurrentItem(null);
	};

	const onDeleteItem = async () => {
		if (currentItem) careerPracticesStore.removeQr(parseInt(careerPractice), currentItem.id);
		onCloseModal();
	};

	return (
		<CustomPage
			title='Практики профориентации / Добавление QR'
			bottomButtons={buttons}
			backLink={backLink}
		>
			<div className={styles.wrapper}>
				<section className={styles.block}>
					<h4 className={styles.blockTitle}>QR коды</h4>

					{careerPracticesStore.hasQrs && (
						<ul className={styles.list}>
							{careerPracticesStore.qrsList.map((qr) => (
								<CareerPracticeQrItem
									qr={qr}
									onDelete={() => {
										setCurrentItem(qr);
										setIsOpenModal(true);
									}}
									key={qr.id}
								/>
							))}
						</ul>
					)}

					{isCreating ? (
						<CareerPracticeQrForm qr={null} onClose={() => setIsCreating(false)} />
					) : (
						careerPracticesStore.qrsList.length < maxQrs && (
							<Button onClick={onAdd} theme='borderless' size='small' className={styles.addBtn}>
								Добавить QR
							</Button>
						)
					)}

					<DeletePopup
						onDeleteItems={onDeleteItem}
						isOpenPopup={isOpenModal}
						closePopup={onCloseModal}
					/>
				</section>
			</div>
		</CustomPage>
	);
});

export default CareerPracticeQrs;
