import { APIError, TMakeRequestParams } from 'interfaces/api/IAPI';
import axios from 'axios';

const makeRequest = async <Type>({
	url = '/',
	method = 'GET',
	auth = false,
	headers = {},
	params = {},
	data = {},
	responseType = 'application/json',
	isAdmin =  true
}: TMakeRequestParams) => {

	url = `${process.env.REACT_APP_BACKEND_URL}/api/${isAdmin ? 'admin/': ''}${url}`;

	if (auth) {
		const token = localStorage.getItem('token');

		headers.authorization = `Bearer ${token}`;
	}

	return axios
		.request<Type>({
			url,
			method,
			headers,
			params,
			data,
			responseType,
		})
		.catch((errors) => {
			const responseErrors = errors.response?.data?.errors;
			const status = errors?.response?.status;

			const returnErrors: APIError = { errors: responseErrors, status };
			return returnErrors;
		});
};

export default makeRequest;
