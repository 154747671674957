import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import { Checkbox } from 'components/UI';
import TableHeaderField from './TableHeaderField/TableHeaderField';
import AdditionalSlots from './AdditionalSlots/AdditionalSlots';

import { getSortDirection } from './utils';

import { ITableHeaderProps } from './types';

import tableHeaderFieldStyles from './TableHeaderField/TableHeaderField.module.scss';

import styles from './TableHeader.module.scss';

const TableHeader = ({
	fields,
	additionalSlots,
	isAllSelected,
	selectAllElements,
	className,
}: ITableHeaderProps) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const sortName = searchParams.get('sort_by');
	const sortDirection = getSortDirection(searchParams.get('sort_dir'));

	const onSort = (fieldName: string) => {
		searchParams.set('sort_by', fieldName);
		searchParams.set('sort_dir', 'asc');

		if (fieldName !== sortName) {
			setSearchParams(searchParams);
			return;
		}

		if (sortDirection === 'asc') {
			searchParams.set('sort_dir', 'desc');
		} else if (sortDirection === 'desc') {
			searchParams.delete('sort_dir');
			searchParams.delete('sort_by');
		}

		setSearchParams(searchParams);
	};

	const onFilter = (fieldName: string, value: string) => {
		searchParams.delete('page');

		if (!value || value === '__.__.____') {
			searchParams.delete(fieldName);
		} else {
			searchParams.set(fieldName, value);
		}

		setSearchParams(searchParams);
	};

	const columnsClasses = clsx(styles.row, {
		[className]: !!className,
	});

	return (
		<section className={styles.tableHeader}>
			<ul className={columnsClasses}>
				{isAllSelected !== undefined && (
					<li className={tableHeaderFieldStyles.column}>
						<Checkbox checked={isAllSelected} onChange={selectAllElements} />
					</li>
				)}

				{fields.map((field, index) => (
					<TableHeaderField field={field} onSort={onSort} onFilter={onFilter} key={index} />
				))}
			</ul>

			{additionalSlots && (
				<AdditionalSlots fields={fields} additionalSlots={additionalSlots} className={className} />
			)}
		</section>
	);
};

export default TableHeader;
