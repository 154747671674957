import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';

const ActivityUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.activities.element;

	const fetchData = useCallback(async () => {
		if (id) {
			await store.activities.fetchItem(id);
		}
	}, [id]);

	const eventsFields: FieldsType[] = [
		{
			type: 'file',
			url: item.image_url,
			label: 'Картинка',
			text: 'Доступны к загрузке PNG, JPG, PDF до',
			name: 'image',
		},
		{
			type: 'textfield',
			label: 'Заголовок',
			value: item.title,
			maxLength: 60,
			name: 'title',
		},
		{
			type: 'textfield',
			name: 'text',
			label: 'Текст',
			value: item.text ?? '',
			maxLength: 100,
		},
		{
			type: 'textFieldWithPrefix',
			name: 'link',
			prefix: 'https://',
			label: 'Ссылка',
			value: item.link ?? '',
		},
	];

	return (
		<UpdatePage
			title={'Активности / Изменение'}
			fields={eventsFields}
			store={store.activities}
			fetchData={fetchData}
		/>
	);
});
export default ActivityUpdate;
