import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import Resume from './Resume';
import ResumesTableHeader from './ResumesTableHeader';

import styles from './Resumes.module.scss';

const Resumes: FC = observer(() => {
	const store = useStore();

	const exportConfig = {
		withSearchParams: true,
		list: [
			{
				path: 'resumes',
				label: 'Анкеты',
				fileWord: 'Список_анкет',
			},
		],
	};

	return (
		<ListPage
			store={store.resumes}
			title='Анкеты'
			tableHeader={ResumesTableHeader}
			tableClassName={styles.table}
			element={Resume}
			hasDelete={false}
			hasCreate={false}
			exportConfig={exportConfig}
		/>
	);
});

export default Resumes;
