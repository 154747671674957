import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { CardsPage } from 'components/Layouts';
import StatusCard from './StatusCard';

const StatusCards: FC = observer(() => {
	const store = useStore();

	return <CardsPage title={'Карточки услуг'} store={store.statusCards} element={StatusCard} />;
});

export default StatusCards;
