export const enum EFriendWorkStatuses {
	default = 'default',
	success = 'success',
	error = 'error',
}

export interface IFriendWorkLinkProps {
	link: string;
}

export interface IFriendWorkSyncProps {
	userId: string;
	friendWorkLink: string | null | undefined;
	syncBtnDisabled: boolean;
	isForList: boolean;
}
