import { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';
import { FieldsType } from 'components/Logic/FieldByType/types';

import { StatusCardService } from 'API';

import { tabs } from './types';
import { TStatus } from 'interfaces/entities/IStatusCard';

const StatusCardUpdate: FC = observer(() => {
	const store = useStore();
	const item = store.statusCards.element;
	const { id } = useParams();

	const [statuses, setStatuses] = useState([] as TStatus[]);

	const statusesList = statuses.map((statusItem) => {
		return { value: statusItem.id, label: statusItem.name };
	});

	const fetchData = useCallback(async () => {
		await store.statusCards.fetchItem(id);
		const response = await StatusCardService.fetchStatuses();
		if ('data' in response) {
			setStatuses(response.data.statuses);
		}
	}, [id]);

	const statusCardsFields: FieldsType[] = [
		{
			type: 'select',
			name: 'status_id',
			label: 'Статус',
			options: statusesList,
			value: { value: item.status?.id, label: item.status?.name },
		},
		{
			type: 'textfield',
			name: 'name',
			label: 'Заголовок',
			value: item.name,
			maxLength: 35,
		},
		{
			type: 'textfield',
			name: 'description',
			label: 'Текст',
			value: item.description,
			maxLength: 100,
		},
		{
			type: 'textFieldWithPrefix',
			name: 'link',
			prefix: 'https://',
			label: 'Ссылка',
			value: item.link,
		},
		{
			name: 'tab_name',
			type: 'select',
			options: tabs,
			value: { value: item.tab_name, label: item.tab_name },
			placeholder: 'Услуги',
			label: 'Раздел',
		},
		{
			type: 'file',
			url: item.image_url,
			label: 'Картинка',
			name: 'image',
		},
	];

	return (
		<UpdatePage
			title={'Карточки услуг / Изменение'}
			fields={statusCardsFields}
			store={store.statusCards}
			fetchData={fetchData}
		/>
	);
});

export default StatusCardUpdate;
