import { FC, useState } from 'react';

import { useStore } from 'hooks/useStore';
import { Button } from 'components/UI';
import { Popup } from 'components/Logic';
import { File } from 'components/Form';

import { IPopupSendProps } from './types';

import { PracticeStudentService } from 'API';

import styles from './Popup.module.scss';

const SendPopup: FC<IPopupSendProps> = ({ isOpenPopup, closePopup }) => {
	const store = useStore();

	const [message, setMessage] = useState('');

	const onUpload = async (file: File) => {
		const formData = new FormData();
		formData.append('file', file);

		const response = await PracticeStudentService.uploadPractices(formData);

		if (response.status === 200) {
			setMessage('');
			store.practiceStudents.fetchItems('');
			closePopup();
		} else {
			setMessage('Ошибка: загрузка не удалась.');
		}
	};

	return (
		<Popup isOpen={isOpenPopup} onClose={closePopup}>
			<div className={styles.container}>
				<span className={styles.title}>Загрузка данных</span>
				<p className={styles.subtitle}>Выберите нужный документ с графиком практик студентов</p>

				<File onChange={onUpload} extensions={/.(xlsx|xls)$/i} wrapperClassname={styles.upload} />

				{message}

				<div className={styles.modalButtons}>
					<Button theme='outlined' onClick={closePopup}>
						Отмена
					</Button>
				</div>
			</div>
		</Popup>
	);
};

export default SendPopup;
