import React, { FC, useState } from 'react';
import clsx from 'clsx';

import { TextFieldProps } from './types';

import { ReactComponent as IcOpenedEye } from 'assets/icons/form/ic_eye.svg';
import { ReactComponent as IcClosedEye } from 'assets/icons/form/ic_eye-slash.svg';
import styles from './TextFields.module.scss';

const TextField: FC<TextFieldProps> = ({
	id,
	label,
	validation,
	disabled,
	type = 'text',
	className = '',
	wrapperClassName = '',
	placeholder = ' ',
	...props
}) => {
	const [inputType, setInputType] = useState(type);
	const [isShowPassword, setShowPassword] = useState(false);

	const wrapperStyle = clsx(styles.wrapper, {
		[wrapperClassName]: !!wrapperClassName,
		[styles.disabled]: !!disabled,
	});

	const textFieldStyle = clsx(styles.input, {
		[className]: !!className,
		[styles.validation]: !!validation,
	});

	const showPassword = () => {
		setShowPassword(true);
		setInputType('text');
	};

	const hidePassword = () => {
		setShowPassword(false);
		setInputType('password');
	};

	return (
		<div className={wrapperStyle}>
			{label && (
				<label htmlFor={id} className={styles.label}>
					{label}
				</label>
			)}

			<input
				id={id}
				type={inputType}
				disabled={disabled}
				placeholder={placeholder}
				className={textFieldStyle}
				{...props}
			/>

			{inputType === 'password' && (
				<IcOpenedEye className={styles.passwordEye} onClick={showPassword} />
			)}
			{inputType === 'text' && isShowPassword && (
				<IcClosedEye className={styles.passwordEye} onClick={hidePassword} />
			)}
		</div>
	);
};

export default TextField;
