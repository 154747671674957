import makeRequest from 'API/makeRequest';
import {
	GetPersonalQualitiesResponse,
	GetPersonalQualityResponse,
} from 'interfaces/api/PersonalQualityResponse';
import { IPersonalQualityCreate } from 'interfaces/entities/IPersonalQuality';

class PersonalQualityService {
	async fetchPersonalQualities(query: string) {
		const response = await makeRequest<GetPersonalQualitiesResponse>({
			url: `personal_qualities?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.personal_qualities,
			meta: response.data.meta,
		};
	}

	async fetchPersonalQuality(id: string) {
		const response = await makeRequest<GetPersonalQualityResponse>({
			url: `personal_qualities/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.personal_quality,
		};
	}

	async createPersonalQuality(personal_quality: IPersonalQualityCreate) {
		const response = await makeRequest<GetPersonalQualityResponse>({
			url: 'personal_qualities',
			method: 'post',
			auth: true,
			data: personal_quality,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.personal_quality,
		};
	}

	async updatePersonalQuality(personal_quality: IPersonalQualityCreate & { id: number }) {
		const response = await makeRequest<GetPersonalQualityResponse>({
			url: `personal_qualities/${personal_quality.id}`,
			method: 'put',
			auth: true,
			data: personal_quality,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.personal_quality,
		};
	}

	async deletePersonalQuality(id: number) {
		return await makeRequest<GetPersonalQualityResponse>({
			url: `personal_qualities/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new PersonalQualityService();
