import { SchoolResumeService } from 'API';
import { IUserRead } from 'interfaces/entities/IUser';
import { ListStore } from 'store/general/List/lIstStore';

class SchoolResumeStore extends ListStore<IUserRead, void> {
	constructor() {
		super({
			APIFetchItems: SchoolResumeService.fetchUsers,
		});
	}
}

export default SchoolResumeStore;
