import { FC } from 'react';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

const SpecialityCreate: FC = () => {
	const store = useStore();

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'name',
			value: '',
			label: 'Название специальности',
		},
		{
			type: 'switch',
			name: 'is_active',
			value: false,
			label: 'Состояние',
		},
	];

	if (store.specialities.createItem === undefined) return null;

	return <CreatePage title='Специальности / Создание' fields={fields} store={store.specialities} />;
};

export default SpecialityCreate;
