import { ReactComponent as IcLogo } from 'assets/icons/general/ic_logo-short.svg';
import styles from './Plug.module.scss';

const Plug = () => {
	return (
		<section className={styles.wrapper}>
			<h1 className={styles.title}>Выберите страницу из меню</h1>
			<p className={styles.description}>Чтобы перейти к соответствующему разделу</p>
			<IcLogo className={styles.logo} />
		</section>
	);
};

export default Plug;
