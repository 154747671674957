import { getYearWord } from 'utils/wordFunctions';

import {
	serializeSecondaryItems,
	TSerializeArgs,
} from 'components/Layouts/HistoryPage/uitls/serializeSecondaryItems';
import { TSecondaryInfo } from 'components/Layouts/HistoryPage/types';
import { THistoryItem } from 'components/Layouts/HistoryPage/Sections/MainItem/types';
import {
	IEducation,
	IExperience,
	ILanguage,
	IResumeCourse,
	IResumeEvent,
	IResumeHistoryItem,
	IResumePortfolio,
	IResumePublication,
} from 'interfaces/entities/IResume';

// utils
const getApplicantAge = (birthday: string) => {
	if (!birthday) return '';

	const age = new Date().getFullYear() - parseInt(birthday?.slice(-4));
	return `${age} ${getYearWord(age)}`;
};

const joinToDot = (array: string[]) => array.map((item) => item).join(', ');

const joinToSemicolon = (array: string[]) => array.map((item) => item).join('; ');

const getMainResume = (item: IResumeHistoryItem) => ({
	oldMainResume: item?.old_resume?.main_resume,
	currentMainResume: item?.current_resume.main_resume,
});

// configs
export const getMainConfig = (item: IResumeHistoryItem): THistoryItem[] => {
	const { oldMainResume, currentMainResume } = getMainResume(item);

	return [
		{
			title: 'ФИО',
			oldValue: oldMainResume?.applicant_profile?.full_name ?? '',
			newValue: currentMainResume?.applicant_profile.full_name ?? '',
		},
		{
			title: 'Возраст',
			oldValue: getApplicantAge(oldMainResume?.applicant_profile.birthday ?? ''),
			newValue: getApplicantAge(currentMainResume?.applicant_profile.birthday ?? ''),
		},
		{
			title: 'Телефон',
			oldValue: oldMainResume?.applicant_profile.phone ?? '',
			newValue: currentMainResume?.applicant_profile.phone ?? '',
		},
		{
			title: 'Почта',
			oldValue: oldMainResume?.applicant_profile.email ?? '',
			newValue: currentMainResume?.applicant_profile.email ?? '',
		},
		{
			title: 'Категория прав',
			oldValue: joinToDot(oldMainResume?.applicant_profile.driving_license_categories ?? []),
			newValue: joinToDot(currentMainResume?.applicant_profile.driving_license_categories ?? []),
		},
		{
			title: 'Статус',
			oldValue: oldMainResume?.applicant_profile.status ?? '',
			newValue: currentMainResume?.applicant_profile.status ?? '',
		},
		{
			title: 'Пожелание к работе',
			oldValue: oldMainResume?.position ?? '',
			newValue: currentMainResume?.position ?? '',
		},
		{
			title: 'Желаемый доход',
			oldValue: oldMainResume?.expected_salary ?? '',
			newValue: currentMainResume?.expected_salary ?? '',
		},
		{
			title: 'График работы',
			oldValue: oldMainResume?.employment_type ?? '',
			newValue: currentMainResume?.employment_type ?? '',
		},
		{
			title: 'Текущий доход',
			oldValue: oldMainResume?.current_salary ?? '',
			newValue: currentMainResume?.current_salary ?? '',
		},
		{
			title: 'Положение',
			oldValue: oldMainResume?.situation ?? '',
			newValue: currentMainResume?.situation ?? '',
		},
		{
			title: 'Проф. качества',
			oldValue: joinToSemicolon(
				oldMainResume?.professional_qualities.map((item) => item.name) ?? [],
			),
			newValue: joinToSemicolon(
				currentMainResume?.professional_qualities.map((item) => item.name) ?? [],
			),
		},
		{
			title: 'Личностные качества',
			oldValue: joinToSemicolon(oldMainResume?.personal_qualities.map((item) => item.name) ?? []),
			newValue: joinToSemicolon(
				currentMainResume?.personal_qualities.map((item) => item.name) ?? [],
			),
		},
		{
			title: 'Участие в чемпионатах',
			oldValue: joinToSemicolon(item?.old_resume?.championships.map((item) => item.name) ?? []),
			newValue: joinToSemicolon(item?.current_resume.championships.map((item) => item.name) ?? []),
		},
		{
			title: 'id анкеты',
			oldValue: oldMainResume?.id ?? '',
			newValue: currentMainResume?.id ?? '',
		},
		{
			title: 'Дата создания',
			oldValue: oldMainResume?.created_at ?? '',
			newValue: currentMainResume?.created_at ?? '',
		},
		{
			title: 'Дата обновления',
			oldValue: oldMainResume?.updated_at ?? '',
			newValue: currentMainResume?.updated_at ?? '',
		},
		{
			title: 'Последний онлайн',
			oldValue: oldMainResume?.user_last_online_at ?? '',
			newValue: currentMainResume?.user_last_online_at ?? '',
		},
	];
};

export const getSecondaryConfig = (item: IResumeHistoryItem): TSecondaryInfo[] => {
	const { oldMainResume, currentMainResume } = getMainResume(item);

	const oldLanguage = oldMainResume ? [...oldMainResume.foreign_languages] : [];
	const currentLanguage = [...currentMainResume.foreign_languages];

	if (oldMainResume?.native_language)
		oldLanguage.unshift({
			id: 0,
			name: oldMainResume.native_language.name,
			level: 'Родной',
		});

	if (currentMainResume?.native_language)
		currentLanguage.unshift({
			id: 0,
			name: currentMainResume.native_language.name,
			level: 'Родной',
		});

	return [
		{
			header: 'Образование',
			blocks: serializeSecondaryItems(
				item.old_resume?.educations ?? [],
				item.current_resume.educations,
				serializeEducation,
			),
		},
		{
			header: 'Опыт работы',
			blocks: serializeSecondaryItems(
				item.old_resume?.employment_histories ?? [],
				item.current_resume.employment_histories,
				serializeEmploymentHistory,
			),
		},
		{
			header: 'Повышение квалификации',
			blocks: serializeSecondaryItems(
				item.old_resume?.courses ?? [],
				item.current_resume.courses,
				serializeCourses,
			),
		},
		{
			header: 'Портфолио',
			blocks: serializeSecondaryItems(
				item.old_resume?.portfolios ?? [],
				item.current_resume.portfolios,
				serializePortfolio,
			),
		},
		{
			header: 'Мероприятия',
			blocks: serializeSecondaryItems(
				item.old_resume?.events ?? [],
				item.current_resume.events,
				serializeEvent,
			),
		},
		{
			header: 'Публикации',
			blocks: serializeSecondaryItems(
				item.old_resume?.publications ?? [],
				item.current_resume.publications,
				serializePublication,
			),
		},
		{
			header: 'Владения языками',
			blocks: serializeSecondaryItems(oldLanguage, currentLanguage, serializeLanguage),
		},
	];
};

// serialization data
export const serializeEducation = ({
	old,
	current,
}: TSerializeArgs<IEducation>): THistoryItem[] => [
	{
		title: 'Тип образования',
		oldValue: old?.education_type ?? '',
		newValue: current?.education_type ?? '',
	},
	{
		title: 'Специальность',
		oldValue: old?.faculty_name ?? '',
		newValue: current?.faculty_name ?? '',
	},
	{
		title: 'Учебное учреждение',
		oldValue: old?.institution_name ?? '',
		newValue: current?.institution_name ?? '',
	},
	{
		title: 'Дата начала обучения',
		oldValue: old?.start_date ?? '',
		newValue: current?.start_date ?? '',
	},
	{
		title: 'Дата окончания обучения',
		oldValue: old?.finish_date ?? '',
		newValue: current?.finish_date ?? '',
	},
];

function serializeEmploymentHistory({ old, current }: TSerializeArgs<IExperience>): THistoryItem[] {
	return [
		{
			title: 'Дата начала работы',
			oldValue: old?.employment_date ?? '',
			newValue: current?.employment_date ?? '',
		},
		{
			title: 'Дата окончания работы',
			oldValue: old?.dismissal_date ?? '',
			newValue: current?.dismissal_date ?? '',
		},
		{
			title: 'Компания',
			oldValue: old?.employer_name ?? '',
			newValue: current?.employer_name ?? '',
		},
		{
			title: 'Город',
			oldValue: old?.employer_city ?? '',
			newValue: current?.employer_city ?? '',
		},
		{
			title: 'Должность',
			oldValue: old?.position ?? '',
			newValue: current?.position ?? '',
		},
		{
			title: 'Рабочие обязанности',
			oldValue: joinToSemicolon(old?.skills || []),
			newValue: joinToSemicolon(current?.skills || []),
		},
	];
}

function serializeCourses({ old, current }: TSerializeArgs<IResumeCourse>): THistoryItem[] {
	return [
		{
			title: 'Название',
			oldValue: old?.name ?? '',
			newValue: current?.name ?? '',
		},
		{
			title: 'Дата',
			oldValue: old?.finish_date ?? '',
			newValue: current?.finish_date ?? '',
		},
		{
			hasLink: true,
			title: 'Ссылка на документ',
			oldValue: {
				value: 'Перейти',
				url: old?.url ?? '',
			},
			newValue: {
				value: 'Перейти',
				url: current?.url ?? '',
			},
		},
	];
}

function serializePortfolio({ old, current }: TSerializeArgs<IResumePortfolio>): THistoryItem[] {
	return [
		{
			title: 'Название',
			oldValue: old?.name ?? '',
			newValue: current?.name ?? '',
		},
		{
			hasLink: true,
			title: 'Ссылка на документ',
			oldValue: {
				value: 'Перейти',
				url: old?.url ?? '',
			},
			newValue: {
				value: 'Перейти',
				url: current?.url ?? '',
			},
		},
	];
}

function serializeEvent({ old, current }: TSerializeArgs<IResumeEvent>): THistoryItem[] {
	return [
		{
			title: 'Название',
			oldValue: old?.name ?? '',
			newValue: current?.name ?? '',
		},
		{
			hasLink: true,
			title: 'Ссылка на документ',
			oldValue: {
				value: 'Перейти',
				url: old?.url ?? '',
			},
			newValue: {
				value: 'Перейти',
				url: current?.url ?? '',
			},
		},
	];
}

function serializePublication({
	old,
	current,
}: TSerializeArgs<IResumePublication>): THistoryItem[] {
	return [
		{
			title: 'Название',
			oldValue: old?.name ?? '',
			newValue: current?.name ?? '',
		},
		{
			hasLink: true,
			title: 'Ссылка на документ',
			oldValue: {
				value: 'Перейти',
				url: old?.url ?? '',
			},
			newValue: {
				value: 'Перейти',
				url: current?.url ?? '',
			},
		},
	];
}

export function serializeLanguage({ old, current }: TSerializeArgs<ILanguage>): THistoryItem[] {
	return [
		{
			title: old?.name ?? current?.name ?? '',
			oldValue: old?.level ?? '',
			newValue: current?.level ?? '',
		},
	];
}
