import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import CoursesTableHeader from './CoursesTableHeader';
import Course from './Course';

import styles from './Courses.module.scss';

const Courses: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.courses}
			title='Курсы'
			tableHeader={CoursesTableHeader}
			tableClassName={styles.table}
			element={Course}
			hasDelete={false}
			hasCreate={false}
		/>
	);
});

export default Courses;
