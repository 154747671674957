import { staticLinks } from 'config/routingLinks';

import NotFound from 'pages/NotFound/NotFound';
import Plug from 'pages/Plug/Plug';

import Specialities from 'pages/Specialities/Specialities';
import SpecialityCreate from 'pages/Specialities/SpecialityCreate';
import SpecialityUpdate from 'pages/Specialities/SpecialityUpdate';

import ProfessionalQualities from 'pages/ProfessionalQualities/ProfessionalQualities';
import ProfessionalQualityCreate from 'pages/ProfessionalQualities/ProfessionalQualityCreate';
import ProfessionalQualityUpdate from 'pages/ProfessionalQualities/ProfessionalQualityUpdate';

import Championships from 'pages/Championships/Championships';
import ChampionshipCreate from 'pages/Championships/ChampionshipCreate';
import ChampionshipUpdate from 'pages/Championships/ChampionshipUpdate';

import SoftwareProducts from 'pages/SoftwareProducts/SoftwareProducts';
import SoftwareProductCreate from 'pages/SoftwareProducts/SoftwareProductCreate';
import SoftwareProductUpdate from 'pages/SoftwareProducts/SoftwareProductUpdate';

import Users from 'pages/Users/Users';
import UserRead from 'pages/Users/UserRead';

import SchoolUsers from 'pages/SchoolUsers/SchoolUsers';
import SchoolUserRead from 'pages/SchoolUsers/SchoolUserRead';

import Documents from 'pages/Users/Documents/Documents';

import PersonalQualities from 'pages/PersonalQualities/PersonalQualities';
import PersonalQualityCreate from 'pages/PersonalQualities/PersonalQualityCreate';
import PersonalQualityUpdate from 'pages/PersonalQualities/PersonalQualityUpdate';

import Spheres from 'pages/Spheres/Spheres';
import SphereCreate from 'pages/Spheres/SphereCreate';
import SphereUpdate from 'pages/Spheres/SphereUpdate';

import AdditionalApplications from 'pages/AdditionalApplications/AdditionalApplications';

import Resumes from 'pages/Resumes/Resumes';
import ResumeUpdate from 'pages/Resumes/ResumeUpdate';

import SchoolResumes from 'pages/SchoolResumes/SchoolResumes';

import Vacancies from 'pages/Vacancies/Vacancies';
import VacancyUpdate from 'pages/Vacancies/VacancyUpdate';

import Employers from 'pages/Employers/Employers';
import EmployerUpdate from 'pages/Employers/EmployerUpdate';

import Courses from 'pages/Courses/Courses';
import CourseUpdate from 'pages/Courses/CourseUpdate';

import Reports from 'pages/Reports/Reports';
import ReportUpdate from 'pages/Reports/ReportUpdate';

import FormBuilder from 'pages/FormBuilder/FormBuilder';
import FormBuilderList from 'pages/FormBuilder/FormBuilderList';

import CalculatorBuilder from 'pages/СalculatorBuilder/СalculatorBuilder';
import CalculatorBuilderList from 'pages/СalculatorBuilder/СalculatorBuilderList';

import StatusCards from 'pages/StatusCards/StatusCards';
import StatusCardCreate from 'pages/StatusCards/StatusCardCreate';
import StatusCardUpdate from 'pages/StatusCards/StatusCardUpdate';

import Answers from 'pages/Answers/Answers';
import AnswerRead from 'pages/Answers/AnswerRead';

import EventCards from 'pages/Events/EventCards';
import EventCardCreate from 'pages/Events/EventCardCreate';
import EventCardUpdate from 'pages/Events/EventCardUpdate';

import Articles from 'pages/Articles/Articles';
import ArticleCreate from 'pages/Articles/ArticleCreate';
import ArticleUpdate from 'pages/Articles/ArticleUpdate';

import PracticeStudentCreate from 'pages/PracticeStudents/PracticeStudentCreate';
import PracticeStudents from 'pages/PracticeStudents/PracticeStudents';
import PracticeStudentUpdate from 'pages/PracticeStudents/PracticeStudentUpdate';

import MailDistributions from 'pages/MailDistributions/MailDistributions';
import MailDistributionCreate from 'pages/MailDistributions/MailDistributionCreate';
import MailDistributionUpdate from 'pages/MailDistributions/MailDistributionUpdate';

import Activities from 'pages/Activities/Activities';
import ActivityCreate from 'pages/Activities/ActivityCreate';
import ActivityUpdate from 'pages/Activities/ActivityUpdate';

import CalendarEvents from 'pages/CalendarEvents/CalendarEvents';
import CalendarEventCreate from 'pages/CalendarEvents/CalendarEventCreate';
import CalendarEventUpdate from 'pages/CalendarEvents/CalendarEventUpdate';

import Professions from 'pages/Professions/Professions';
import ProfessionCreate from 'pages/Professions/ProfessionCreate';
import ProfessionUpdate from 'pages/Professions/ProfessionUpdate';

import CareerPractices from 'pages/CareerPractices/CareerPractices';
import CareerPracticeCreate from 'pages/CareerPractices/CareerPracticeCreate';
import CareerPracticeUpdate from 'pages/CareerPractices/CareerPracticeUpdate';
import CareerPracticeQrs from 'pages/CareerPractices/CareerPracticeQrs/CareerPracticeQrs';

import UnpaidEmailSubscription from 'pages/MailDistributions/UnpaidEmailSubscription/UnpaidEmailSubscription';

import SupportMeasures from 'pages/SupportMeasures/SupportMeasures';
import SupportMeasureCreate from 'pages/SupportMeasures/SupportMeasureCreate';
import SupportMeasureUpdate from 'pages/SupportMeasures/SupportMeasureUpdate';

import CareerTools from 'pages/CareerTools/CareerTools';
import CareerToolUpdate from 'pages/CareerTools/CareerToolUpdate';

import CareerGuidances from 'pages/CareerGuidance/CareerGuidances';
import PavilionsOstrova from '../pages/PavilionsOstrova/Pavilions';
import PavilionsOstrovaCreate from '../pages/PavilionsOstrova/PavilionCU/PavilionCreate';
import PavilionsOstrovaUpdate from '../pages/PavilionsOstrova/PavilionCU/PavilionUpdate';


export const routes = [
	{
		path: staticLinks.main,
		element: <Plug />,
	},
	{
		path: staticLinks.unpaidEmailSubscription,
		element: <UnpaidEmailSubscription />,
	},
	{
		path: staticLinks.specialities,
		children: [
			{
				index: true,
				path: staticLinks.specialities,
				element: <Specialities />,
			},
			{
				path: staticLinks.specialitiesCreate,
				element: <SpecialityCreate />,
			},
			{
				path: staticLinks.specialitiesUpdate,
				element: <SpecialityUpdate />,
			},
		],
	},
	// {
	// 	path: staticLinks.pavilions,
	// 	children: [
	// 		{
	// 			index: true,
	// 			path: staticLinks.pavilions,
	// 			element: <Pavilions />,
	// 		},
	// 		{
	// 			path: staticLinks.pavilionsCreate,
	// 			element: <PavilionCreate />,
	// 		},
	// 		{
	// 			path: staticLinks.pavilionsUpdate,
	// 			element: <PavilionUpdate />,
	// 		},
	// 	],
	// },
	{
		path: staticLinks.pavilionsOstrova,
		children: [
			{
				index: true,
				path: staticLinks.pavilionsOstrova,
				element: <PavilionsOstrova />,
			},
			{
				path: staticLinks.pavilionsOstrovaCreate,
				element: <PavilionsOstrovaCreate />,
			},
			{
				path: staticLinks.pavilionsOstrovaUpdate,
				element: <PavilionsOstrovaUpdate />,
			},
		],
	},
	{
		path: staticLinks.professionalQualities,
		children: [
			{
				index: true,
				path: staticLinks.professionalQualities,
				element: <ProfessionalQualities />,
			},
			{
				path: staticLinks.professionalQualitiesCreate,
				element: <ProfessionalQualityCreate />,
			},
			{
				path: staticLinks.professionalQualitiesUpdate,
				element: <ProfessionalQualityUpdate />,
			},
		],
	},
	{
		path: staticLinks.championships,
		children: [
			{
				index: true,
				element: <Championships />,
				path: staticLinks.championships,
			},
			{
				path: staticLinks.championshipsCreate,
				element: <ChampionshipCreate />,
			},
			{
				path: staticLinks.championshipsUpdate,
				element: <ChampionshipUpdate />,
			},
		],
	},
	{
		path: staticLinks.softwareProducts,
		children: [
			{
				index: true,
				path: staticLinks.softwareProducts,
				element: <SoftwareProducts />,
			},
			{
				path: staticLinks.softwareProductsCreate,
				element: <SoftwareProductCreate />,
			},
			{
				path: staticLinks.softwareProductsUpdate,
				element: <SoftwareProductUpdate />,
			},
		],
	},
	{
		path: staticLinks.users,
		children: [
			{
				index: true,
				path: staticLinks.users,
				element: <Users />,
			},
			{
				path: staticLinks.usersRead,
				element: <UserRead />,
			},
		],
	},
	{
		path: staticLinks.schoolUsers,
		children: [
			{
				index: true,
				path: staticLinks.schoolUsers,
				element: <SchoolUsers />,
			},
			{
				path: staticLinks.schoolUsersRead,
				element: <SchoolUserRead />,
			},
		],
	},
	{
		path: staticLinks.documents,
		element: <Documents />,
	},
	{
		path: staticLinks.careerPracticesQrs,
		element: <CareerPracticeQrs />,
	},
	{
		path: staticLinks.personalQualities,
		children: [
			{
				index: true,
				path: staticLinks.personalQualities,
				element: <PersonalQualities />,
			},
			{
				path: staticLinks.personalQualitiesCreate,
				element: <PersonalQualityCreate />,
			},
			{
				path: staticLinks.personalQualitiesUpdate,
				element: <PersonalQualityUpdate />,
			},
		],
	},
	{
		path: staticLinks.supportMeasures,
		children: [
			{
				index: true,
				path: staticLinks.supportMeasures,
				element: <SupportMeasures />,
			},
			{
				path: staticLinks.supportMeasuresCreate,
				element: <SupportMeasureCreate />,
			},
			{
				path: staticLinks.supportMeasuresUpdate,
				element: <SupportMeasureUpdate />,
			},
		],
	},
	{
		path: staticLinks.spheres,
		children: [
			{
				index: true,
				path: staticLinks.spheres,
				element: <Spheres />,
			},
			{
				path: staticLinks.spheresCreate,
				element: <SphereCreate />,
			},
			{
				path: staticLinks.spheresUpdate,
				element: <SphereUpdate />,
			},
		],
	},
	{
		path: staticLinks.professions,
		children: [
			{
				index: true,
				path: staticLinks.professions,
				element: <Professions />,
			},
			{
				path: staticLinks.professionsCreate,
				element: <ProfessionCreate />,
			},
			{
				path: staticLinks.professionsUpdate,
				element: <ProfessionUpdate />,
			},
		],
	},
	{
		path: staticLinks.additionalApplications,
		element: <AdditionalApplications />,
	},
	{
		path: staticLinks.reports,
		children: [
			{
				index: true,
				path: staticLinks.reports,
				element: <Reports />,
			},
			{
				path: staticLinks.reportsUpdate,
				element: <ReportUpdate />,
			},
		],
	},
	{
		path: staticLinks.careerTools,
		children: [
			{
				index: true,
				path: staticLinks.careerTools,
				element: <CareerTools />,
			},
			{
				path: staticLinks.careerToolsUpdate,
				element: <CareerToolUpdate />,
			},
		],
	},
	{
		path: staticLinks.resumes,
		children: [
			{
				index: true,
				path: staticLinks.resumes,
				element: <Resumes />,
			},
			{
				path: staticLinks.resumesUpdate,
				element: <ResumeUpdate />,
			},
		],
	},
	{
		path: staticLinks.vacancies,
		children: [
			{
				index: true,
				path: staticLinks.vacancies,
				element: <Vacancies />,
			},
			{
				path: staticLinks.vacanciesUpdate,
				element: <VacancyUpdate />,
			},
		],
	},
	{
		path: staticLinks.employers,
		children: [
			{
				index: true,
				path: staticLinks.employers,
				element: <Employers />,
			},
			{
				path: staticLinks.employersUpdate,
				element: <EmployerUpdate />,
			},
		],
	},
	{
		path: staticLinks.courses,
		children: [
			{
				index: true,
				path: staticLinks.courses,
				element: <Courses />,
			},
			{
				path: staticLinks.coursesUpdate,
				element: <CourseUpdate />,
			},
		],
	},
	{
		path: staticLinks.formBuilder,
		children: [
			{
				index: true,
				path: staticLinks.formBuilder,
				element: <FormBuilderList />,
			},
			{
				path: staticLinks.formBuilderUpdate,
				element: <FormBuilder />,
			},
		],
	},
	{
		path: staticLinks.calculatorBuilder,
		children: [
			{
				index: true,
				path: staticLinks.calculatorBuilder,
				element: <CalculatorBuilderList />,
			},
			{
				path: staticLinks.calculatorBuilderUpdate,
				element: <CalculatorBuilder />,
			},
		],
	},
	{
		path: staticLinks.statusCards,
		children: [
			{
				index: true,
				path: staticLinks.statusCards,
				element: <StatusCards />,
			},
			{
				path: staticLinks.statusCardsCreate,
				element: <StatusCardCreate />,
			},
			{
				path: staticLinks.statusCardsUpdate,
				element: <StatusCardUpdate />,
			},
		],
	},
	{
		path: staticLinks.answers,
		children: [
			{
				index: true,
				path: staticLinks.answers,
				element: <Answers />,
			},
			{
				path: staticLinks.answersRead,
				element: <AnswerRead />,
			},
		],
	},
	{
		path: staticLinks.eventCards,
		children: [
			{
				index: true,
				path: staticLinks.eventCards,
				element: <EventCards />,
			},
			{
				path: staticLinks.eventCardsCreate,
				element: <EventCardCreate />,
			},
			{
				path: staticLinks.eventCardsUpdate,
				element: <EventCardUpdate />,
			},
		],
	},
	{
		path: staticLinks.articles,
		children: [
			{
				index: true,
				path: staticLinks.articles,
				element: <Articles />,
			},
			{
				path: staticLinks.articlesCreate,
				element: <ArticleCreate />,
			},
			{
				path: staticLinks.articlesUpdate,
				element: <ArticleUpdate />,
			},
		],
	},
	{
		path: staticLinks.activities,
		children: [
			{
				index: true,
				path: staticLinks.activities,
				element: <Activities />,
			},
			{
				path: staticLinks.activitiesCreate,
				element: <ActivityCreate />,
			},
			{
				path: staticLinks.activitiesUpdate,
				element: <ActivityUpdate />,
			},
		],
	},
	{
		path: staticLinks.practiceStudents,
		children: [
			{
				index: true,
				path: staticLinks.practiceStudents,
				element: <PracticeStudents />,
			},
			{
				path: staticLinks.practiceStudentsCreate,
				element: <PracticeStudentCreate />,
			},
			{
				path: staticLinks.practiceStudentsUpdate,
				element: <PracticeStudentUpdate />,
			},
		],
	},
	{
		path: staticLinks.mailDistributions,
		children: [
			{
				index: true,
				path: staticLinks.mailDistributions,
				element: <MailDistributions />,
			},
			{
				index: true,
				path: staticLinks.mailDistributionsUpdate,
				element: <MailDistributionUpdate />,
			},
			{
				index: true,
				path: staticLinks.mailDistributionsCreate,
				element: <MailDistributionCreate />,
			},
		],
	},
	{
		path: staticLinks.schoolEvents,
		children: [
			{
				index: true,
				path: staticLinks.calendarEvents,
				element: <CalendarEvents />,
			},
			{
				path: staticLinks.calendarEventsCreate,
				element: <CalendarEventCreate />,
			},
			{
				path: staticLinks.calendarEventsUpdate,
				element: <CalendarEventUpdate />,
			},
		],
	},
	{
		path: staticLinks.schoolResumes,
		children: [
			{
				index: true,
				path: staticLinks.schoolResumes,
				element: <SchoolResumes />,
			},
			{
				path: staticLinks.schoolResumesUpdate,
				element: <ResumeUpdate />,
			},
		],
	},
	{
		path: staticLinks.careerPractices,
		children: [
			{
				index: true,
				path: staticLinks.careerPractices,
				element: <CareerPractices />,
			},
			{
				path: staticLinks.careerPracticesCreate,
				element: <CareerPracticeCreate />,
			},
			{
				path: staticLinks.careerPracticesUpdate,
				element: <CareerPracticeUpdate />,
			},
		],
	},
	{
		path: staticLinks.careerGuidance,
		element: <CareerGuidances />,
	},
	{
		path: '*',
		element: <NotFound />,
	},
];
