import { FC } from 'react';
import clsx from 'clsx';

import { TLinkHistoryItemProps } from './types';

import styles from './MainItem.module.scss';

const LinkHistoryItem: FC<TLinkHistoryItemProps> = ({ type, item }) => {
	const url = item[type].url;
	if (!url) return null;

	const fullUrl = `${process.env.REACT_APP_BACKEND_URL}/${url}`;

	const oldValue = item.oldValue;
	const newValue = item.newValue;

	const itemClasses = clsx(styles.item, {
		[styles.change]: type && newValue.url && oldValue.url && oldValue.url !== newValue.url,
		[styles.remove]: type === 'oldValue' && oldValue.url && !newValue.url,
		[styles.add]: type === 'newValue' && newValue.url && !oldValue.url,
	});

	return (
		<li className={type ? itemClasses : styles.item}>
			<li className={styles.item} key={item.title}>
				<span>{item.title}</span>
				<a href={fullUrl} target='_blank' rel='noreferrer'>
					{item[type].value}
				</a>
			</li>
		</li>
	);
};

export default LinkHistoryItem;
