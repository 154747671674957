import { FC, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';
import { Button, Loader } from 'components/UI';
import { Popup } from 'components/Logic';

import { getModalContent, TInfoModalState } from './config';
import { roles } from 'config/selectOptions';
import { staticLinks } from 'config/routingLinks';

import { FieldsType } from 'components/Logic/FieldByType/types';

import popupStyles from 'components/Logic/CustomPopups/Popup.module.scss';

const MailDistributionCardUpdate: FC = observer(() => {
	const store = useStore();

	const [modalInfo, setModalInfo] = useState<TInfoModalState>({ type: 'hidden', count: 0 });

	const { id } = useParams();
	const item = store.mailDistributions.element;
	const navigate = useNavigate();

	const fetchData = useCallback(async () => {
		if (id) await store.mailDistributions.fetchItem(id);
	}, [id]);

	const closeModal = () => setModalInfo({ type: 'hidden', count: 0 });

	const openLoadingModal = () => setModalInfo({ type: 'loading', count: 0 });

	const openConfirmationModal = () => setModalInfo({ type: 'confirmation', count: 0 });

	const openResultModal = (count: number) => setModalInfo({ type: 'result', count });

	const onDistribute = async () => {
		openLoadingModal();

		const response = await store.mailDistributions.distributeToEmails(item.id);

		if ('data' in response) openResultModal(response.data.count);
		else navigate(staticLinks.unpaidEmailSubscription);
	};

	const MailDistributionsFields: FieldsType[] = [
		{
			type: 'select',
			name: 'role',
			label: 'Роль',
			options: roles,
			value: { value: item.role, label: item.role === 'applicant' ? 'Гражданин' : 'Предприятие' },
		},
		{
			type: 'textfield',
			label: 'Название',
			value: item.title,
			maxLength: 60,
			name: 'title',
		},
		{
			type: 'textfield',
			name: 'theme',
			label: 'Заголовок письма',
			value: item.theme ?? '',
			maxLength: 100,
		},
		{
			type: 'textarea',
			name: 'message',
			label: 'Текст',
			value: item.message ?? '',
		},
	];

	const additionalButtons = (
		<Button theme='outlined' onClick={openConfirmationModal}>
			Разослать письмо
		</Button>
	);

	const modalContent = getModalContent(modalInfo);

	return (
		<>
			<UpdatePage
				title={'Рассылка писем / Изменение'}
				fields={MailDistributionsFields}
				store={store.mailDistributions}
				fetchData={fetchData}
				additionalButtons={additionalButtons}
			/>

			<Popup
				className={popupStyles.modal}
				isOpen={modalInfo.type !== 'hidden'}
				onClose={closeModal}
			>
				<div className={popupStyles.container}>
					{modalInfo.type === 'loading' ? (
						<Loader />
					) : (
						<>
							<span className={popupStyles.title}>{modalContent?.title}</span>
							<p className={popupStyles.subtitle}>{parse(modalContent?.description ?? '')}</p>

							<div className={popupStyles.modalButtons}>
								{modalInfo.type === 'confirmation' && (
									<>
										<Button theme='outlined' onClick={closeModal}>
											Отмена
										</Button>

										<Button theme='filled' onClick={onDistribute}>
											Разослать
										</Button>
									</>
								)}

								{modalInfo.type === 'result' && (
									<Button theme='outlined' onClick={closeModal}>
										Закрыть
									</Button>
								)}
							</div>
						</>
					)}
				</div>
			</Popup>
		</>
	);
});
export default MailDistributionCardUpdate;
