import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PaginationProps } from './types';

import { ReactComponent as IcArrowLeft } from 'assets/icons/general/ic_arrow-left.svg';
import { ReactComponent as IcArrowRight } from 'assets/icons/general/ic_arrow-right.svg';
import styles from './Pagination.module.scss';

const Pagination: FC<PaginationProps> = ({ currentPage, lastPage }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	if (lastPage === 1 || lastPage === undefined) return null;

	const pages = [currentPage - 1, currentPage, currentPage + 1];
	const hasPreviousPage = currentPage - 1 > 0;
	const hasNextPage = currentPage + 1 <= lastPage;
	const isPrevPageNotFirst = currentPage - 1 > 1;
	const isShowPrevDots = isPrevPageNotFirst && currentPage > 3;
	const isNextPageNotLast = currentPage + 1 < lastPage;
	const isShowNextDots = isNextPageNotLast && currentPage < lastPage - 2;

	const onPrevPage = () => navigatePage(currentPage - 1);
	const onNextPage = () => navigatePage(currentPage + 1);

	const navigatePage = (page: number) => {
		searchParams.set('page', page.toString());
		setSearchParams(searchParams);
	};

	return (
		<div className={styles.wrapper}>
			<button disabled={!hasPreviousPage} className={styles.buttonPrevious} onClick={onPrevPage}>
				<IcArrowLeft />
			</button>

			{isPrevPageNotFirst && (
				<button className={styles.button} onClick={() => navigatePage(1)}>
					1
				</button>
			)}

			{isShowPrevDots && <span className={styles.dots}>...</span>}

			{pages.map((page) => {
				if (page < 1) return null;
				if (page > lastPage) return null;

				return (
					<button
						key={page}
						className={page === currentPage ? styles.active : styles.button}
						onClick={() => navigatePage(page)}
					>
						{page}
					</button>
				);
			})}

			{isShowNextDots && <span className={styles.dots}>...</span>}

			{isNextPageNotLast && (
				<button className={styles.button} onClick={() => navigatePage(lastPage)}>
					{lastPage}
				</button>
			)}

			<button disabled={!hasNextPage} className={styles.buttonNext} onClick={onNextPage}>
				<IcArrowRight />
			</button>
		</div>
	);
};

export default Pagination;
