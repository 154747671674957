import { reviewedOptions, TableHeader, THeaderField } from 'components/Layouts';

import styles from './CareerTools.module.scss';

const CareerToolsTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Тип заявки', name: 'career_tool_name', type: 'textField', hasSorting: true },
		{ label: 'ФИО', name: 'name', type: null, hasSorting: true },
		{ label: 'Телефон', name: 'phone', type: null, hasSorting: true },
		{ label: 'Почта', name: 'email', type: null, hasSorting: true },
		{ label: 'Дата обновления', name: '', type: null },
		{ label: 'Состояние', name: 'is_reviewed', type: 'select', options: reviewedOptions, hasSorting: true },
		{ label: '', name: '', type: null },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default CareerToolsTableHeader;
