/* eslint-disable */
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import { Button } from 'components/UI';
import { useEffect, useState } from 'react';
import { ReactComponent as IcEdit } from 'assets/icons/form/ic_edit.svg';
import { ReactComponent as IcDelete } from 'assets/icons/general/ic_trash.svg';
import EditCondition from './EditCondition';
import { ActionType } from '../СalculatorBuilder';
import { useStore } from 'hooks/useStore';
import styles from './Settings.module.scss';

const Settings = observer(({ state, dispatch, id }: any) => {
	const store = useStore();

	useEffect(() => {
		store.questions?.conditions?.map((condition: any) => {
			dispatch({
				type: ActionType.SET_CONDITIONS,
				payload: {
					value: '',
					itemIndex: condition.id,
					id: condition.id,
					name: condition.name,
				},
			});
		});

		return () => {
			dispatch({
				type: ActionType.DELETE_ALL_CONDITIONS,
				payload: {},
			});
		};
	}, [store.questions?.conditions?.length]);

	const { fields, conditions } = state;
	// const [state, dispatch] = useReducer(reducer, initialState);
	const [isOpen, setIsOpen]: any = useState(false);
	const [currentCondition, setCurrentCondition]: any = useState(null);

	const openEditDialog = (field: any) => {
		const condition = store.questions?.conditions.find(
			(condition: any) => condition.id === field.id,
		);
		setCurrentCondition(condition || field);
		setIsOpen(true);
	};

	return (
		<>
			<div className={styles.title}>ВЫВОД</div>
			<ul className={styles.list}>
				{state?.conditions?.map((condition: any, index: number) => {
					return (
						<li>
							<div className={styles.headerQuestion}>
								{condition.name}
								<div className={styles.actions}>
									<IcEdit className={styles.buttonIcon} onClick={() => openEditDialog(condition)} />
									<IcDelete
										className={styles.buttonIcon}
										onClick={() => {
											dispatch({
												type: ActionType.DELETE_CONDITIONS,
												payload: {
													itemIndex: condition.itemIndex,
												},
											});
											store.questions?.deleteCondition(id, condition.id);
										}}
									/>
								</div>
							</div>
						</li>
					);
				})}
			</ul>
			<Button
				className={styles.addButton}
				theme='filled'
				onClick={() => {
					const index = uuidv4();
					dispatch({
						type: ActionType.SET_CONDITIONS,
						payload: {
							value: '',
							itemIndex: index,
							id: null,
							name: 'Условие вывода',
						},
					});
				}}
			>
				Добавить условие вывода
			</Button>
			{isOpen && (
				<>
					<div onClick={() => setIsOpen(false)} className={styles.background} />
					<EditCondition
						id={id}
						fields={fields}
						setIsOpen={(isOpen: boolean) => setIsOpen(isOpen)}
						currentCondition={currentCondition}
						dispatch={dispatch}
					/>
				</>
			)}
			{/* <div style={{ width: "100%", marginBottom: "15px", display: "flex", alignItems: "center" }}>
        <div className={styles.addAttrBlock} onClick={() => {
          const index = uuidv4();
          dispatch({
            type: ActionType.SET_ATTRIBUTE,
            payload: {
              value: "",
              itemIndex: index,
              id: null,
            }
          });
        }}>
          <div className={styles.addAttr}>+</div>
          Добавить атрибут
        </div>
      </div> */}
		</>
	);
});

export default Settings;
