import { EditorService } from 'API';

import { TEditorType } from 'interfaces/entities/IEditor';
import { IEditorStore } from './types';

class EditorStore implements IEditorStore {
	async uploadByFile(file: File, type: TEditorType) {
		const formData = new FormData();

		formData.append('image', file);
		formData.append('type', type);

		const response = await EditorService.uploadByFile(formData);

		if ('data' in response) {
			response.data.file.url = `${process.env.REACT_APP_BACKEND_URL}/${response.data.file.url}`;

			return response.data;
		}

		return response;
	}
}

export default EditorStore;
