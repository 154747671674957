import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import { Button, LinkBtn, Loader, Switch } from 'components/UI';

import styles from './CareerTools.module.scss';
import { PageHeader } from '../../components/Layouts';
import InfoList from './InfoList/InfoList';
import { AsyncButton } from '../../components/Form';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ReactComponent as IcDelete } from 'assets/icons/general/ic_trash.svg';
import { DeletePopup } from '../../components/Logic';
import { globalFormikConfig } from 'config/globalFormikConfig';

const CareerToolUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.careerTools?.item;
	const navigate = useNavigate();
	const [isRequesting, setIsRequesting] = useState(false);
	const [modalIsOpen, setModalOpen] = useState(false);

	useEffect(() => {
		store.careerTools.fetchItem(id);
	}, [id]);

	const formik = useFormik({
		...globalFormikConfig,
		initialValues: {
			is_reviewed: item?.is_reviewed ?? false,
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			return store.careerTools
				.updateItem?.({ ...item, is_reviewed: values.is_reviewed })
				.catch((errors: any) => {
					formik.setErrors(errors.errors);
					throw errors;
				});
		},
	});

	const onSaveAndClose = async () => {
		try {
			setIsRequesting(true);
			const response = await formik.submitForm();
			if ('errors' in response) {
				console.error(response);
			} else {
				navigate('/career_tools');
			}
		} catch (e) {
			console.error(e);
		} finally {
			setIsRequesting(false);
		}
	};
	const onDeleteItem = () => {
		closeDeleteModal();
		if (typeof id === 'string') {
			store.careerTools
				.removeItem?.(+id)
				.then(() => navigate('/career_tools'))
				.catch((errors: any) => {
					formik.setErrors(errors.errors);
					throw errors;
				});
		}
	};
	const openDeleteModal = () => setModalOpen(true);
	const closeDeleteModal = () => setModalOpen(false);

	if (store.careerTools.isLoading)
		return (
			<section className={styles.wrapper}>
				<PageHeader title={'Заявки запросов / Просмотр'} backLink={'/career_tools'} />
				<Loader />
			</section>
		);
	return (
		<section className={styles.wrapper}>
			<PageHeader title={'Заявки запросов / Просмотр'} backLink={'/career_tools'} />
			<form className={styles.form}>
				<div className={styles.content}>
					<InfoList item={item} />
					<div>
						<p>Просмотрено</p>
						<Switch
							checked={formik.values.is_reviewed}
							onChange={(event) => formik.setFieldValue('is_reviewed', event.target.checked)}
						/>
					</div>
				</div>
				<div className={styles.btns}>
					<AsyncButton isLoading={isRequesting} type='button' onClick={onSaveAndClose}>
						Сохранить
					</AsyncButton>

					<LinkBtn to={'/career_tools'} theme='outlined'>
						Отмена
					</LinkBtn>

					<Button theme='danger' className={styles.delete} type='button' onClick={openDeleteModal}>
						<IcDelete />
					</Button>
				</div>
			</form>
			<DeletePopup
				onDeleteItems={onDeleteItem}
				isOpenPopup={modalIsOpen}
				closePopup={closeDeleteModal}
			/>
		</section>
	);
});

export default CareerToolUpdate;
