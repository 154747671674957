import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import CareerTool from './CareerTool';
import CareerToolsTableHeader from './CareerToolsTableHeader';

import styles from './CareerTools.module.scss';

const CareerTools: FC = observer(() => {
	const store = useStore();

	const exportConfig = {
		withSearchParams: false,
		list: [
			{
				path: 'career_tools',
				fileWord: 'Заявки запросов',
				label: 'Заявки',
			},
		],
	};

	return (
		<ListPage
			store={store.careerTools}
			title='Заявки запросов'
			tableHeader={CareerToolsTableHeader}
			tableClassName={styles.table}
			element={CareerTool}
			hasDelete={false}
			hasCreate={false}
			exportConfig={exportConfig}
		/>
	);
});

export default CareerTools;
