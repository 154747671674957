import makeRequest from 'API/makeRequest';
import { GetVacanciesResponse, GetVacancyResponse } from 'interfaces/api/VacancyResponse';

class VacancyService {
	async fetchVacancies(query: string) {
		const response = await makeRequest<GetVacanciesResponse>({
			url: `vacancies?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.vacancies,
			meta: response.data.meta,
		};
	}

	async fetchVacancy(id: string) {
		const response = await makeRequest<GetVacancyResponse>({
			url: `vacancies/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.vacancy,
		};
	}

	async updateVacancy(id: string, is_approved: boolean | null) {
		const response = await makeRequest<GetVacancyResponse>({
			url: `vacancies/${id}`,
			method: 'put',
			auth: true,
			data: { is_approved },
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.vacancy,
		};
	}
}

export default new VacancyService();
