import { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { Accordion, AccordionContent, AccordionSummary } from 'components/Logic';

import { sidebarConfig } from './SidebarConfig';
import { staticLinks } from 'config/routingLinks';

import { ReactComponent as LogoSvg } from 'assets/icons/general/ic_logo.svg';
import { ReactComponent as IcArrowDown } from 'assets/icons/general/ic_arrow-down.svg';
import styles from './Sidebar.module.scss';
import EngineeringWorks from './EngireeingWorks/EngineeringWorks';

const Sidebar: FC = observer(() => {
	const store = useStore();
	const auth = store.auth;

	const [currentAccordion, setCurrentAccordion] = useState<string | null>(null);
	const location = useLocation();

	const getLinkStyles = (link: string) =>
		clsx(styles.link, {
			[styles.activeLink]: location.pathname.includes(link),
		});

	return (
		<nav className={styles.sidebar}>
			<Link to={staticLinks.main} className={styles.logo}>
				<LogoSvg />
			</Link>

			<nav className={styles.navigation}>
				{sidebarConfig.map((confItem, index) => {
					if (confItem.navigation) {
						return (
							<Accordion
								key={index}
								className={styles.accordion}
								onChange={() => setCurrentAccordion(confItem.title)}
								expanded={confItem.title === currentAccordion}
							>
								<AccordionSummary className={styles.header}>
									<span className={styles.title}>{confItem.title}</span>
									<IcArrowDown />
								</AccordionSummary>
								<AccordionContent>
									<ul className={styles.links}>
										{confItem.navigation.map((navItem) => (
											<li className={getLinkStyles(navItem.to)} key={navItem.to}>
												<Link to={navItem.to}>{navItem.name}</Link>
											</li>
										))}
									</ul>
								</AccordionContent>
							</Accordion>
						);
					}

					return (
						<Link className={styles.globalLink} to={confItem.to as string} key={confItem.to}>
							{confItem.name}
						</Link>
					);
				})}

				<EngineeringWorks />
			</nav>

			<div className={styles.bottom}>
				<span>
					вы зашли как: <br /> <strong className={styles.role}>{auth.userName}</strong>{' '}
				</span>

				<button className={styles.exit} onClick={auth.logout}>
					Выйти
				</button>
			</div>
		</nav>
	);
});

export default Sidebar;
