import { makeObservable, observable } from 'mobx';

import { QuestionService } from 'API';
import { IReportCreate, IReportRead } from 'interfaces/entities/IReport';
import { ListStore } from 'store/general/List/lIstStore';

class QuestionStore extends ListStore<IReportRead, IReportCreate> {
	statuses = observable.array<any>([]);
	calculators = observable.array<any>([]);
	conditions = observable.array<any>([]);
	status: string | null;
	activeTab = 'builder';
	responseTemplate: any;

	constructor() {
		super({
			APIFetchItems: QuestionService.fetchQuestions,
		});
		this.status = null;
		makeObservable(this, {
			status: observable,
			activeTab: observable,
			responseTemplate: observable,
		});
	}

	setStatus(status: string | null) {
		this.status = status;
	}

	setActiveTab(tabName: string) {
		this.activeTab = tabName;
	}

	async fetchStatuses() {
		const data: any = await QuestionService.fetchStatuses();
		this.statuses.replace(data.statuses);
	}

	async fetchPagesList() {
		const data: any = await QuestionService.fetchPagesList();
		this.calculators.replace(data.calculators);
	}

	async deletePage(id: number) {
		const data: any = await QuestionService.deletePage(id);
		this.fetchPagesList();
	}

	async deleteCondition(calculatorId: any, id: number) {
		const data: any = await QuestionService.deleteCondition(calculatorId, id);
		this.fetchConditions(id);
	}

	async createPage(props: any) {
		const data: any = await QuestionService.createPage(props);
		this.fetchPagesList();
	}

	async fetchSettingsForm(id: number) {
		const data: any = await QuestionService.fetchSettingsForm(id);
		this.responseTemplate = data.responseTemplate;
		// this.statuses.replace(data.statuses)
	}

	async createQuestions(items: any, id: number) {
		this.isLoading = true;
		await QuestionService.createQuestions(items, id);
		this.isLoading = false;
	}

	async createQuestionsCalculators(items: any, id: number) {
		this.isLoading = true;
		await QuestionService.createQuestionsCalculators(items, id);
		this.isLoading = false;
	}

	async postConditions(items: any, id: number) {
		this.isLoading = true;
		await QuestionService.postConditions(items, id);
		this.isLoading = false;
		this.fetchConditions(id);
	}

	async updateConditions(items: any, id: number, conditionId: any) {
		this.isLoading = true;
		await QuestionService.updateConditions(items, id, conditionId);
		this.isLoading = false;
		this.fetchConditions(id);
	}

	async fetchQuestionsCalculators(id: any) {
		const data: any = await QuestionService.fetchQuestionsCalculators(id);
		console.log('data', data);
		this.items = data.items;
	}

	async fetchConditions(id: any) {
		const data: any = await QuestionService.fetchConditions(id);
		this.conditions.replace(data.data.answer_ways);
	}

	async postSettingsForm(data: any) {
		this.isLoading = true;
		await QuestionService.postSettingsForm(data);
		this.isLoading = false;
		// this.isLoading = true;
	}
}

export default QuestionStore;
