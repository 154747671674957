import makeRequest from 'API/makeRequest';
import {
	GetCalendarEventResponse,
	GetCalendarEventsResponse,
} from 'interfaces/api/CalendarEventResponse';
import { ICalendarEventCreate } from 'interfaces/entities/ICalendarEvent';

class CalendarEventService {
	async fetchCalendarEvents(query: string) {
		const response = await makeRequest<GetCalendarEventsResponse>({
			url: `calendar_events?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.calendar_events,
			meta: response.data.meta,
		};
	}

	async fetchCalendarEvent(id: string) {
		const response = await makeRequest<GetCalendarEventResponse>({
			url: `calendar_events/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.calendar_event,
		};
	}

	async createCalendarEvent(event: ICalendarEventCreate) {
		const response = await makeRequest<GetCalendarEventResponse>({
			url: 'calendar_events',
			method: 'post',
			auth: true,
			data: event,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.calendar_event,
		};
	}

	async updateCalendarEvent(event: ICalendarEventCreate & { id: number }) {
		const response = await makeRequest<GetCalendarEventResponse>({
			url: `calendar_events/${event.id}`,
			method: 'put',
			auth: true,
			data: event,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.calendar_event,
		};
	}

	deleteCalendarEvent(id: number) {
		return makeRequest<void>({
			url: `calendar_events/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new CalendarEventService();
