/* eslint-disable */
import clsx from "clsx";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./СalculatorBuilder.module.scss";

const Tabs: FC<any> = (props: any) => {
	const navigate = useNavigate();

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>, page: string) => {
		e.preventDefault();
		navigate(`/form_builder/${page}`);
	};

	const tabStyle = clsx({
		[styles.tab]: true
	});

	return (
		<div className={styles.tabs}>
			<div
				className={tabStyle}
				// onClick={(e) => onLinkToUpdate(e, "construnctor")}
				onClick={() => props.setActiveTab("builder")}
				style={
					props.activeTab === "builder"
						? {
							color: "var(--c_blue)",
							borderBottom: "2px solid var(--c_blue)",
							opacity: 1
						}
						: {}
				}
			>
				Конструктор
			</div>
			<div
				onClick={() => props.setActiveTab("settings")}
				className={tabStyle}
				style={
					props.activeTab === "settings"
						? {
							color: "var(--c_blue)",
							borderBottom: "2px solid var(--c_blue)",
							opacity: 1
						}
						: {}
				}
			>
				Настройки
			</div>
		</div>
	);
};

export default Tabs;
