import makeRequest from 'API/makeRequest';
import {
	GetTestApplicantsResponse,
	GetApplicantAnswerResponse,
} from 'interfaces/api/AnswerResponse';

class AnswerService {
	async fetchAnswers(query: string) {
		const response = await makeRequest<GetTestApplicantsResponse>({
			url: `answers?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.answers,
			meta: response.data.meta,
		};
	}

	async fetchAnswer(id: string) {
		const response = await makeRequest<GetApplicantAnswerResponse>({
			url: `answers/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.answer,
		};
	}
}

export default new AnswerService();
