import { ICalendarEventRead } from 'interfaces/entities/ICalendarEvent';

export interface ICalendarEventProps {
	item: ICalendarEventRead;
	isSelected: boolean;
	selectElement: (id: number) => void;
}

export const roleOptions = [
	{
		label: 'Школьник',
		value: 'Школьник',
	},
	{
		label: 'Студент',
		value: 'Студент',
	},
	{
		label: 'Специалист',
		value: 'Специалист',
	},
	{
		label: 'Работодатель',
		value: 'Работодатель',
	},
];
