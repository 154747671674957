import { FC } from 'react';
import clsx from 'clsx';

import { Button } from 'components/UI';

import { IAsyncButtonProps } from './types';

import styles from './AsyncButton.module.scss';

const AsyncButton: FC<IAsyncButtonProps> = ({
	isLoading,
	children,
	disabled,
	className = '',
	...props
}) => {
	const isDisabled = disabled || isLoading;

	const buttonClasses = clsx(styles.button, { [className]: !!className });
	const textClasses = clsx(styles.text, { [styles.hideText]: isLoading });

	return (
		<Button {...props} className={buttonClasses} disabled={isDisabled} theme='filled'>
			<span className={textClasses}>{children}</span>
			{isLoading && <span className={styles.loader}></span>}
		</Button>
	);
};

export default AsyncButton;
