import { EventService } from 'API';
import { IEventRead } from 'interfaces/entities/IEvent';
import { ListStore } from 'store/general/List/lIstStore';

class EventOstrovaStore extends ListStore<IEventRead, FormData> {
	constructor() {
		super({
			APIFetchItems: EventService.fetchEventsOstrova,
			APIFetchItem: EventService.fetchEvent,
			APICreateItem: EventService.createEventOstrova,
			APIUpdateItem: EventService.updateEventOstrova,
			APIRemoveItem: EventService.deleteEvent,
		});
	}
}

export default EventOstrovaStore;
