export const getEngineeringWorksContent = (isWorking: boolean) => {
	const title = isWorking
		? 'Перевести сайт в режим тех. обсулживания'
		: 'Перевести сайт в обычный режим';

	const description = isWorking
		? 'Активация технических работ на сайте. В этом режиме доступ к сайту будет ограничен '
		: 'Завершение технических работ и возврата сайта в обычный режим. Доступ к сайту будет полностью восстановлен';

	return { title, description };
};
