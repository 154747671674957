import makeRequest from 'API/makeRequest';
import { GetEmployersResponse, GetEmployerResponse } from 'interfaces/api/EmployerResponse';

class EmployerService {
	async fetchEmployers(query: string) {
		const response = await makeRequest<GetEmployersResponse>({
			url: `employer_profiles?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.employer_profiles,
			meta: response.data.meta,
		};
	}

	async fetchEmployer(id: string) {
		const response = await makeRequest<GetEmployerResponse>({
			url: `employer_profiles/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.employer_profile,
		};
	}

	async updateEmployerApprovement(id: string, is_approved: boolean | null) {
		const response = await makeRequest<GetEmployerResponse>({
			url: `employer_profiles/${id}`,
			method: 'put',
			auth: true,
			data: { is_approved },
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.employer_profile,
		};
	}

	async updateEmployerARCHK(id: string, is_ARCHK_partner: boolean) {
		const response = await makeRequest<GetEmployerResponse>({
			url: `employer_profiles/${id}`,
			method: 'put',
			auth: true,
			data: { is_ARCHK_partner },
		});
		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.employer_profile,
		};
	}

	async updateEmployerKRSO(id: string, is_krso: boolean) {
		const response = await makeRequest<GetEmployerResponse>({
			url: `employer_profiles/${id}`,
			method: 'put',
			auth: true,
			data: { is_krso },
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.employer_profile,
		};
	}

	async updateEmployerInvestmentProject(id: string, is_investment_project: boolean) {
		const response = await makeRequest<GetEmployerResponse>({
			url: `employer_profiles/${id}`,
			method: 'put',
			auth: true,
			data: { is_investment_project },
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.employer_profile,
		};
	}
}

export default new EmployerService();
