import { FC, useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { wrapUseRoutes } from '@sentry/react';

import { useStore } from 'hooks/useStore';
import { routes } from './routesConfig';
import Auth from 'pages/Auth/Auth';
import SideBar from 'components/Layouts/Sidebar/Sidebar';
import LoaderPage from 'pages/LoaderPage/LoaderPage';

import { staticLinks } from 'config/routingLinks';

const Routes: FC = observer(() => {
	const { auth, questions } = useStore();
	const location = useLocation();
	const navigate = useNavigate();
	const useSentryRoutes = wrapUseRoutes(useRoutes);
	const routing = useSentryRoutes(routes);

	useEffect(() => {
		if (auth.isAuth && location.pathname === '/') {
			navigate(staticLinks.main);
		}
	}, [location.pathname, auth.isAuth]);

	useEffect(() => {
		questions.fetchStatuses();
	}, []);

	if (auth.isAuth === null) {
		return <LoaderPage />;
	}

	if (!auth.isAuth) {
		return <Auth />;
	}

	return (
		<>
			<SideBar />
			{routing}
		</>
	);
});

export default Routes;
