export interface IMarkProps {
	variant: EMarkVariant;
	coords: TMarkCoords | null;
}

export type TMarkCoords = {
	x: number;
	y: number;
};

export enum EMarkVariant {
	RED = 'red',
	BLACK = 'black',
}
