import styles from './InfoList.module.scss';
import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ICareerToolRead } from 'interfaces/entities/ICareerTools';

interface InfoListProps {
	item: ICareerToolRead;
}

const InfoList: FC<InfoListProps> = observer(({ item }) => {
	const items = [
		{ title: 'Тип заявки', value: item?.career_tool_name },
		{ title: 'ФИО', value: item?.applicant_profile?.full_name },
		{ title: 'Возраст', value: item?.applicant_profile?.age },
		{ title: 'Телефон', value: item?.applicant_profile?.phone },
		{ title: 'Почта', value: item?.applicant_profile?.email },
		{ title: 'Статус', value: item?.applicant_profile?.status.name },
		{ title: 'Пол', value: item?.applicant_profile?.sex },
		{ title: 'Дата рождения', value: item?.applicant_profile?.birthday },
		{ title: 'Город', value: item?.applicant_profile?.city },
	];

	return (
		<div className={styles.content}>
			<section className={styles.mainSection}>
				<h4 className={styles.title}>Основное</h4>
				<ul className={styles.list}>
					{items.map((item) => (
						<li key={item.title + item.value} className={styles.item}>
							<span>{item.title}</span>
							<p>{item.value}</p>
						</li>
					))}

					{item?.applicant_profile?.id && (
						<a
							href={`https://rabota-sakhalin.study-traektoria.ru/soiskatel/${item?.applicant_profile?.id}`}
							rel='noreferrer'
							target={'_blank'}
							className={styles.link}
						>
							Перейти к профилю
						</a>
					)}
				</ul>
				<a
					href={`https://rabota-sakhalin.ru/soiskatel/${item.id}`}
					target={'_blank'}
					className={styles.link}
					rel='noreferrer'
				>
					Перейти к профилю
				</a>
			</section>
		</div>
	);
});

export default InfoList;
