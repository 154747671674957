import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import { useFormik } from 'formik';
import styles from './UpdatePage.module.scss';
import { LinkBtn, TextArea, TextField, TextFieldWithPrefix, Validation } from '../../components/UI';
import { AsyncButton, File } from '../../components/Form';
import Document from '../../components/Form/Document/Document';
import { globalFormikConfig } from 'config/globalFormikConfig';

const CreateForm: FC = observer(() => {
	const store = useStore();

	const [isRequesting, setIsRequesting] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const navigate = useNavigate();

	const formik = useFormik({
		...globalFormikConfig,
		initialValues: {
			image_url: '',
			image: '',
			name: '',
			text: '',
			link: '',
			document_url: '',
			document: '',
		},
		onSubmit: async (values) => {
			const formData = new FormData();
			formData.append('name', values.name);
			formData.append('text', values.text);
			formData.append('link', values.link);
			if (typeof values.document_url === 'string' && values.document === null) {
				formData.append('has_document', '0');
			} else {
				if (values.document === values.document_url) {
					formData.append('has_document', '1');
				} else {
					formData.append('document', values.document);
				}
			}

			if (typeof values.image_url === 'string' && values.image === null) {
				formData.append('has_image', '0');
			} else {
				if (values.image === values.image_url) {
					formData.append('has_image', '1');
				} else {
					formData.append('image', values.image);
				}
			}

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return store.supportMeasure.createItem?.(formData).catch((errors: any) => {
				formik.setErrors(errors.errors);
				throw errors;
			});
		},
	});

	const onSaveAndClose = async () => {
		try {
			setIsRequesting(true);
			const response = await formik.submitForm();

			if ('errors' in response) {
				formik.setErrors(response.errors);
			} else {
				navigate('/support_measures');
			}
		} catch (e) {
			console.error(e);
		} finally {
			setIsRequesting(false);
		}
	};
	const openDeleteModal = () => setModalOpen(true);
	const closeDeleteModal = () => setModalOpen(false);

	return (
		<form onSubmit={formik.handleSubmit} className={styles.form}>
			<ul className={styles.list}>
				<li className={styles.item}>
					<File
						onDelete={() => formik.setFieldValue('image', null)}
						url={formik.values.image_url !== null ? formik.values.image_url : ''}
						label={'Изображение'}
						onChange={(file: File) => formik.setFieldValue('image', file)}
					/>
					<Validation touched={formik?.touched?.['image']} errors={formik?.errors?.['image']} />
				</li>
				<li className={styles.item}>
					<TextField
						value={formik.values.name}
						label={'Заголовок'}
						onChange={(event) => formik.setFieldValue('name', event.target.value)}
					/>
					<Validation touched={formik?.touched?.['name']} errors={formik?.errors?.['name']} />
				</li>
				<li className={styles.item}>
					<TextArea
						value={formik.values.text}
						label={'Описание'}
						onChange={(event) => formik.setFieldValue('text', event.target.value)}
					/>
					<Validation touched={formik?.touched?.['text']} errors={formik?.errors?.['text']} />
				</li>
				<li className={styles.item}>
					<TextFieldWithPrefix
						name='link'
						label='Ссылка'
						prefix='https://'
						value={formik.values.link}
						onChange={value => formik.setFieldValue('link', value)}
					/>
					<Validation touched={formik?.touched?.['link']} errors={formik?.errors?.['link']} />
				</li>
				<li className={styles.item}>
					<Document
						onDelete={() => formik.setFieldValue('document', null)}
						url={formik.values.document_url !== null ? formik.values.document_url : ''}
						label={'Документ'}
						onChange={(file: File) => formik.setFieldValue('document', file)}
					/>
					<Validation touched={formik?.touched?.['document']} errors={formik?.errors?.document} />
				</li>
			</ul>
			<div className={styles.btns}>
				<AsyncButton isLoading={isRequesting} type='button' onClick={onSaveAndClose}>
					Сохранить
				</AsyncButton>
				<LinkBtn to={'/support_measures'} theme='outlined'>
					Отмена
				</LinkBtn>
			</div>
		</form>
	);
});
export default CreateForm;
