import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { DNDListPage } from 'components/Layouts';
import Activity from './Activity';

const Activities: FC = observer(() => {
	const store = useStore();

	return <DNDListPage title={'Активности'} store={store.activities} element={Activity} />;
});

export default Activities;
