import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { dynamicLinks } from 'config/routingLinks';

import { CareerGuidanceProps } from './types';

import styles from './CareerGuidances.module.scss';

const CareerGuidance: FC<CareerGuidanceProps> = observer(({ item }) => {
	const navigate = useNavigate();

	const fullName = item.user.first_name + ' ' + item.user.last_name;

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{fullName}</span>
			</div>
			<div className={styles.cell}>
				<span className={'wrap-text limit-line-text'}>{item.test_result}</span>
			</div>
			<div className={styles.cell} onClick={() => navigate(dynamicLinks(item.user.id).users)}>
				<div className='edit-icon-block'>
					<span className='update-text'>Перейти на профиль</span>
				</div>
			</div>
		</div>
	);
});

export default CareerGuidance;
