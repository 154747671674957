import { IProfessionRead } from 'interfaces/entities/IProfession';

export interface ProfessionProps {
	item: IProfessionRead;
	isSelected: boolean;
	selectElement: (id: number) => void;
}

export const educationOptions = [
	{
		label: 'Среднее общее',
		value: 'Среднее общее',
	},
	{
		label: 'Среднее профессиональное',
		value: 'Среднее профессиональное',
	},
	{
		label: 'Высшее',
		value: 'Высшее',
	},
	{
		label: 'Магистр',
		value: 'Магистр',
	},
	{
		label: 'Ученая степень',
		value: 'Ученая степень',
	},
	{
		label: 'Без образования',
		value: 'Без образования',
	},
];
