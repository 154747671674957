import makeRequest from 'API/makeRequest';

class AsyncSelectService {
	fetchEmployers(query?: string) {
		return makeRequest<void>({
			url: `search/employer_profiles?query=${query}`,
			auth: true,
		});
	}

	fetchCalendarEvents(query?: string) {
		return makeRequest<void>({
			url: `search/calendar_events?query=${query}`,
			auth: true,
		});
	}
	fetchEvents(query?: string) {
		return makeRequest<void>({
			url: `search/events?query=${query}`,
			auth: true,
		});
	}
	fetchCities(query?: string) {
		return makeRequest<void>({
			url: `cities?query=${query}`,
			auth: true,
			isAdmin: false
		});
	}
	fetchSpecialities(query?: string) {
		return makeRequest<void>({
			url: `specialities?query=${query}`,
			auth: true,
			isAdmin: false
		});
	}
}

export default new AsyncSelectService();
