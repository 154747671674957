import { FC } from 'react';

import { PageHeader } from 'components/Layouts';
import CreateForm from './CreateForm';

import { staticLinks } from 'config/routingLinks';

import styles from './UpdatePage.module.scss';

const SupportMeasureCreate: FC = () => {
	return (
		<section className={styles.createPage}>
			<PageHeader title='Меры поддержки / Создание' backLink={staticLinks.supportMeasures} />
			<CreateForm />
		</section>
	);
};

export default SupportMeasureCreate;
