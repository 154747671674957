import React, { ChangeEvent, FC, MouseEvent } from 'react';
import clsx from 'clsx';

import { SwitchProps } from './types';

import styles from './Switch.module.scss';

const Switch: FC<SwitchProps> = ({
	id,
	name,
	className = '',
	disabled = false,
	checked,
	onChange,
	label,
	onClick,
}) => {
	const switchStyle: string = clsx({
		[styles.switch]: true,
		[className]: className,
		[styles.disabled]: !!disabled,
	});

	const onChangeEmit = (e: ChangeEvent<HTMLInputElement>) => {
		if (typeof onChange === 'function') {
			onChange(e);
		}
	};

	const onClickEmit = (e: MouseEvent<HTMLInputElement>) => {
		if (typeof onClick === 'function') {
			onClick(e);
		}
	};

	return (
		<label className={switchStyle}>
			{label && <span className={styles.label}>{label}</span>}
			<input
				type='checkbox'
				name={name}
				id={id}
				onChange={onChangeEmit}
				onClick={onClickEmit}
				checked={checked}
			/>
			<span className={styles.checkMark}></span>
		</label>
	);
};

export default Switch;
