import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { CardsPage } from 'components/Layouts';
import MailDistribution from './MailDistribution';

const MailDistributionCards: FC = observer(() => {
	const store = useStore();

	return (
		<CardsPage
			title={'Рассылка писем'}
			store={store.mailDistributions}
			element={MailDistribution}
		/>
	);
});

export default MailDistributionCards;
