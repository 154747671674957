import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import EditorJS from '@editorjs/editorjs';
import Image from '@editorjs/image';

import { useStore } from 'hooks/useStore';

import { EDITOR_TOOLS, EditorLocalization, getCurrentTools } from './config';

import { IEditorProps } from './types';

import './globalEditor.scss';
import styles from './Editor.module.scss';

const Editor = observer(
	({ value: defaultValue, tools, editorType, onChange, label }: IEditorProps) => {
		const editorStore = useStore().editor;
		const ref = useRef<EditorJS | null>(null);

		const toolsConfig = {
			...EDITOR_TOOLS,
			image: {
				class: Image,
				config: {
					tools: [],
					withBorder: false,
					uploader: {
						async uploadByFile(file: File) {
							return await editorStore.uploadByFile(file, editorType);
						},
					},
				},
			},
		};

		const onChangeValue = () => {
			if (ref?.current) {
				ref.current?.save().then((outputData) => {
					if (outputData.blocks.length) onChange(outputData);
					else onChange(null);
				});
			}
		};

		const initializeEditor = async () => {
			if (!ref.current) {
				ref.current = new EditorJS({
					data: defaultValue ?? undefined,
					holder: 'editorjs',
					tools: getCurrentTools(toolsConfig, tools),
					i18n: EditorLocalization,
					onChange: onChangeValue,
				});
			}
		};

		useEffect(() => {
			initializeEditor();

			return () => {
				if (ref.current) ref.current.destroy();
			};
		}, []);

		return (
			<div className={styles.wrapper}>
				{label && <span className={styles.label}>{label}</span>}

				<div className={styles.editorWrapper}>
					<div id='editorjs'></div>
				</div>
			</div>
		);
	},
);

export default Editor;
