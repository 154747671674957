import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { AsyncButton } from 'components/Form';
import { Button, LinkBtn } from 'components/UI';
import { DeletePopup } from 'components/Logic';

import { IPageButtonsProps } from './types';

import { ReactComponent as IcDelete } from 'assets/icons/general/ic_trash.svg';
import styles from './PageButtons.module.scss';

const PageButtons = observer(({ formik, type }: IPageButtonsProps) => {
	const pavilionsStore = useStore().practiceStudents;

	const [isRequesting, setIsRequesting] = useState(false);
	const [modalIsOpen, setModalOpen] = useState(false);

	const { id } = useParams();
	const navigate = useNavigate();

	const backLink =
		type === 'create'
			? location.pathname.slice(0, -7) + location.search
			: location.pathname.split('/').slice(0, -2).join('/') + location.search;

	const onSaveAndClose = async () => {
		try {
			setIsRequesting(true);
			const response = await formik.submitForm();

			if ('errors' in response) {
				formik.setErrors(response.errors);
			} else {
				navigate(backLink);
			}
		} catch (e) {
			console.error(e);
		} finally {
			setIsRequesting(false);
		}
	};

	const openDeleteModal = () => setModalOpen(true);
	const closeDeleteModal = () => setModalOpen(false);

	const onDeleteItem = () => {
		closeDeleteModal();

		pavilionsStore
			.removeItem?.(+id!)
			.then(() => navigate(backLink))
			.catch((errors: any) => {
				formik.setErrors(errors.errors);
				throw errors;
			});
	};

	return (
		<div className={styles.buttons}>
			<div className={styles.left}>
				<AsyncButton isLoading={isRequesting} type='button' onClick={onSaveAndClose} key='save'>
					Сохранить
				</AsyncButton>
				<LinkBtn to={backLink} theme='outlined' key='close'>
					Отмена
				</LinkBtn>
			</div>

			{type === 'update' && (
				<>
					<Button theme='danger' className={styles.delete} type='button' onClick={openDeleteModal}>
						<IcDelete />
					</Button>

					<DeletePopup
						onDeleteItems={onDeleteItem}
						isOpenPopup={modalIsOpen}
						closePopup={closeDeleteModal}
					/>
				</>
			)}
		</div>
	);
});

export default PageButtons;
