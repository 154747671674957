import { FC } from 'react';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

const CareerPracticeCreate: FC = () => {
	const store = useStore();

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'name',
			value: '',
			label: 'Название*',
		},
		{
			type: 'file',
			name: 'image',
			label: 'Изображение*',
			maxSize: 1_048_576,
		},
		{
			type: 'wysiwyg',
			name: 'description',
			value: '',
			label: 'Описание*',
		},
		{
			type: 'phonefield',
			name: 'phone',
			value: '',
			label: 'Номер телефона',
		},
		{
			type: 'textFieldWithPrefix',
			name: 'link',
			prefix: 'https://',
			value: '',
			label: 'Ссылка',
		},
		{
			type: 'switch',
			name: 'is_active',
			value: true,
			label: 'Состояние',
		},
	];
	if (store.careerPractices.createItem === undefined) return null;

	return (
		<CreatePage
			title='Практики профориентации / Создание'
			fields={fields}
			store={store.careerPractices}
		/>
	);
};

export default CareerPracticeCreate;
