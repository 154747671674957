import { createElement, FC, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { PageHead } from 'components/Layouts';
import { Export, Pagination } from 'components/Logic';
import List from './List';

import { ListPageProps } from './types';

import styles from './ListPage.module.scss';

const ListPage: FC<ListPageProps> = observer(
	({
		store,
		title = '',
		backLink,
		tableHeader,
		element,
		hasCreate = true,
		hasDelete = true,
		hasImport = false,
		exportConfig,
		tableClassName,
		tableWrapperClassName,
	}) => {
		const [selectedElements, setSelectedElements] = useState<number[]>([]);
		const [isAllSelected, setAllSelected] = useState(false);
		const { parentId } = useParams();
		const [searchParams] = useSearchParams();

		useEffect(() => {
			// logic for filters
			const filterName = searchParams.get('filter_by');
			const filterValue = searchParams.get('filter_value');
			if (filterName && filterValue) {
				searchParams.set(filterName, filterValue);
			}

			const query = searchParams.toString();

			store.fetchItems(query, parentId);

			return () => {
				store.clearItems();
			};
		}, [searchParams, parentId, store]);

		const selectElement = (id: number) => {
			const index = selectedElements.findIndex((item) => item === id);
			const isAlreadyIncluded = index !== -1;

			if (isAlreadyIncluded) {
				const newSelectedElements = [...selectedElements];
				newSelectedElements.splice(index, 1);
				setSelectedElements(newSelectedElements);
				setAllSelected(false);
			} else {
				setSelectedElements([...selectedElements, id]);
			}
		};

		const selectAllElements = () => {
			setAllSelected((prev) => !prev);

			if (isAllSelected) {
				setSelectedElements([]);
			} else {
				const newSelectedElements = store.list?.map((item: any) => item?.id);
				setSelectedElements(newSelectedElements);
			}
		};

		const HeaderComponent = createElement(tableHeader, {
			isAllSelected,
			selectAllElements,
		});

		const tableWrapperClasses = clsx(styles.tableWrapper, {
			[tableWrapperClassName ?? '']: !!tableWrapperClassName,
		});

		const tableClasses = clsx(styles.table, {
			[tableClassName ?? '']: !!tableClassName,
		});

		return (
			<section className={styles.listPage}>
				<PageHead
					title={title}
					store={store}
					selectedElements={selectedElements}
					hasCreate={hasCreate}
					hasDelete={hasDelete}
					hasImport={hasImport}
					backLink={backLink}
				/>

				<div className={tableWrapperClasses}>
					<div className={tableClasses}>
						<div className={styles.tableHeader}>{HeaderComponent}</div>
						<div className={styles.tableBody}>
							<List
								store={store}
								element={element}
								selectedElements={selectedElements}
								selectElement={selectElement}
							/>
						</div>
					</div>
				</div>

				<div className={styles.bottom}>
					<Pagination currentPage={store.currentPage} lastPage={store.lastPage} />
					{exportConfig && (
						<Export
							exportList={exportConfig.list}
							query={exportConfig.withSearchParams ? `?${searchParams.toString()}` : ''}
						/>
					)}
				</div>
			</section>
		);
	},
);

export default ListPage;
