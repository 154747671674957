import { FC } from 'react';

import { TableHeader, THeaderField } from 'components/Layouts';

import styles from './Pavilions.module.scss';

const PavilionsTableHeader: FC = () => {
	const tableFields: THeaderField[] = [
		{ label: 'Название', name: 'name', type: 'textField', hasSorting: true },
		{ type: null, name: '', label: '' },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default PavilionsTableHeader;
