import { TableHeader, THeaderField, typeOptions } from 'components/Layouts';

import styles from './Reports.module.scss';

const ReportsTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Объект жалобы', name: 'reportable_type', type: 'select', options: typeOptions, hasSorting: true },
		{ label: 'Название обьекта', name: '', type: null },
		{ label: 'Дата создания', name: 'created_at', type: 'textField', hasSorting: true },
		{ label: 'Состояние', name: '', type: null },
		{ label: '', name: '', type: null },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default ReportsTableHeader;
