import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';
import { TEditorType } from 'interfaces/entities/IEditor';
import { typeOptions } from './types';

const ArticleUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.articles.element;

	const fetchData = useCallback(async () => {
		if (id) {
			await store.articles.fetchItem(id);
		}
	}, [id]);

	const articlesFields: FieldsType[] = [
		{
			type: 'textfield',
			label: 'Заголовок',
			name: 'name',
			maxLength: 64,
			value: item?.name ?? '',
		},
		{
			type: 'file',
			label: 'Изображение',
			text: 'Доступны к загрузке PNG, JPG, PDF до',
			name: 'image',
			maxSize: 1048576,
			url: item?.image_url,
		},
		{
			type: 'textfield',
			name: 'description',
			label: 'Описание',
			value: item?.description ?? '',
		},
		{
			type: 'select',
			name: 'type',
			label: 'Расположение',
			options: typeOptions,
			value: typeOptions.find((option) => option.label === item.type),
		},
		{
			type: 'editor',
			name: 'content',
			label: 'Контент',
			tools: ['header', 'list', 'quote', 'image'],
			editorType: 'articles_wysiwyg' as TEditorType,
			value: item?.content && JSON.parse(item.content),
		},
	];

	return (
		<UpdatePage
			title={'Статьи / Изменение'}
			fields={articlesFields}
			store={store.articles}
			fetchData={fetchData}
		/>
	);
});
export default ArticleUpdate;
