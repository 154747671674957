import CareerToolService from 'API/rest/CareerToolService';

import { ListStore } from 'store/general/List/lIstStore';

import { ICareerToolRead, ICareerToolUpdate } from 'interfaces/entities/ICareerTools';

class CareerToolsStore extends ListStore<ICareerToolRead, ICareerToolUpdate> {
	constructor() {
		super({
			APIFetchItems: CareerToolService.fetchCareerTools,
			APIFetchItem: CareerToolService.fetchCareerTool,
			APIUpdateItem: CareerToolService.updateCareerTool,
			APIRemoveItem: CareerToolService.deleteCareerTool,
		});
	}
}

export default CareerToolsStore;
