import makeRequest from 'API/makeRequest';
import {
	GetSoftwareProductsResponse,
	GetSoftwareProductResponse,
} from 'interfaces/api/SoftwareProductResponse';
import { ISoftwareProductCreate } from 'interfaces/entities/ISoftwareProduct';

class SoftwareProductService {
	async fetchSoftwareProducts(query: string) {
		const response = await makeRequest<GetSoftwareProductsResponse>({
			url: `software_products?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.software_products,
			meta: response.data.meta,
		};
	}

	async fetchSoftwareProduct(id: string) {
		const response = await makeRequest<GetSoftwareProductResponse>({
			url: `software_products/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.software_product,
		};
	}

	async createSoftwareProduct(software_product: ISoftwareProductCreate) {
		const response = await makeRequest<GetSoftwareProductResponse>({
			url: 'software_products',
			method: 'post',
			auth: true,
			data: software_product,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.software_product,
		};
	}

	async updateSoftwareProduct(software_product: ISoftwareProductCreate & { id: number }) {
		const response = await makeRequest<GetSoftwareProductResponse>({
			url: `software_products/${software_product.id}`,
			method: 'put',
			auth: true,
			data: software_product,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.software_product,
		};
	}

	deleteSoftwareProduct(id: number) {
		return makeRequest<void>({
			url: `software_products/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new SoftwareProductService();
