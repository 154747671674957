import { MouseEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import clsx from 'clsx';

import { useStore } from 'hooks/useStore';
import { PageHeader } from 'components/Layouts';

import { getAnswersWord } from 'utils/wordFunctions';

import { statusName } from 'store/general/unions';

import { ReactComponent as IcSchooler } from 'assets/icons/formBuilder/ic_schooler.svg';
import { ReactComponent as IcStudent } from 'assets/icons/formBuilder/ic_student.svg';
import { ReactComponent as IcWorker } from 'assets/icons/formBuilder/ic_worker.svg';
import styles from './Formbuilder.module.scss';

export const FormBuilderList = observer(({ field }: any) => {
	const store = useStore();

	useEffect(() => {
		store.questions.fetchStatuses();
	}, []);

	const navigate = useNavigate();
	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>, id: number, name: string) => {
		e.preventDefault();
		navigate(`${id}/update`);
		store.questions.setStatus(name);
	};

	const statusIcons = {
		Школьник: <IcSchooler />,
		Студент: <IcStudent />,
		Трудящийся: <IcWorker />,
	};

	const answerCountStyles = (answers: number) =>
		clsx(styles.answersCount, {
			[styles.inactive]: answers === 0,
		});

	return (
		<div className={styles.formBuilder}>
			<PageHeader title={'Формы запросов'} />

			<div className={styles.formList}>
				{toJS(store.questions.statuses).map(({ name, id, answers_count, updated_at }: any) => {
					return (
						<div className={styles.formCard} onClick={(e) => onLinkToUpdate(e, id, name)} key={id}>
							{statusIcons[name as statusName]}
							<div className={styles.formCardTitle}>{name}</div>
							<span className={answerCountStyles(answers_count)}>
								{answers_count} {getAnswersWord(answers_count)}
							</span>
							<div className={styles.date}>{updated_at && `Изменено ${updated_at}`}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
});

export default FormBuilderList;
