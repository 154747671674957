import { FC } from 'react';

import { useStore } from 'hooks/useStore';
import { CreatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';

const ProfessionalQualityCreate: FC = () => {
	const store = useStore();

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'name',
			value: '',
			label: 'Название чемпионата',
		},
		{
			type: 'switch',
			name: 'is_active',
			value: false,
			label: 'Состояние',
		},
	];

	if (store.championships.createItem === undefined) return null;

	return <CreatePage title='Чемпионаты / Создание' fields={fields} store={store.championships} />;
};

export default ProfessionalQualityCreate;
