import { FC } from 'react';

import { isResumeStatusOptions, TableHeader, THeaderField } from 'components/Layouts';

import styles from './Answers.module.scss';

const AnswersTableHeader: FC = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: '', type: null },
		{ label: 'ФИО', name: '', type: null },
		{ label: 'Телефон', name: '', type: null },
		{ label: 'Почта', name: '', type: null },
		{
			label: 'Статус',
			name: 'status',
			type: 'select',
			options: isResumeStatusOptions,
			hasSorting: true,
		},
		{ label: 'Дата обновления', name: '', type: null },
		{ label: '', name: '', type: null },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default AnswersTableHeader;
