import { roleOptions, TableHeader, THeaderField } from 'components/Layouts';

import styles from './Users.module.scss';

const UsersTableHeader = () => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Имя / название компании', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Почта', name: 'email', type: 'textField', hasSorting: true },
		{ label: 'Роль', name: 'role', type: 'select', options: roleOptions, hasSorting: true },
		{ label: 'Дата регистрации', name: 'created_at', type: 'datePicker', hasSorting: true },
		{ label: '', name: '', type: null },
		{ label: '', name: '', type: null },
		{ label: '', name: '', type: null },
	];

	return <TableHeader className={styles.row} fields={tableFields} />;
};

export default UsersTableHeader;
