import { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';

import { FieldsType } from 'components/Logic/FieldByType/types';
import { TLocationSphere } from 'interfaces/entities/ISphere';
import { locationOptions } from './types';

const SphereUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.spheres.element;

	const fetchData = useCallback(async () => {
		await store.spheres.fetchItem(id);
	}, [id]);

	const schoolShowCondition = {
		name: 'locations',
		value: ['Школьник'] as TLocationSphere[],
	};

	const IslandsAndSchoolShowCondition = {
		name: 'locations',
		value: ['Острова Развития', 'Школьник'] as TLocationSphere[],
	};

	const allShowCondition = {
		name: 'locations',
		value: [
			'Острова Развития',
			'Школьник',
			'Вакансии инвест проектов, Компании, Курсы',
		] as TLocationSphere[],
	};

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'name',
			value: item.name || '',
			label: 'Название',
			maxLength: 48,
		},
		{
			type: 'multiSelect',
			name: 'locations',
			value: item.locations,
			label: 'Расположение',
			placeholder: '',
			options: locationOptions,
		},
		{
			type: 'wysiwyg',
			name: 'description',
			value: item.description || '',
			label: 'Описание',
			showCondition: schoolShowCondition,
		},
		{
			type: 'file',
			name: 'image',
			label: 'Изображение',
			text: 'Доступны к загрузке PNG, JPG, PDF до',
			url: item.image_url || '',
			showCondition: IslandsAndSchoolShowCondition,
			maxSize: 1_048_576,
		},
		{
			type: 'textFieldWithCrumbs',
			name: 'projects',
			value: item.projects,
			label: 'Проекты',
			showCondition: schoolShowCondition,
		},
		{
			type: 'textFieldWithCrumbs',
			name: 'operator_companies',
			value: item.operator_companies,
			label: 'Компании операторы ',
			showCondition: schoolShowCondition,
		},
		{
			type: 'textFieldWithCrumbs',
			name: 'useful_resources',
			value: item.useful_resources,
			label: 'Ссылки на полезные ресурсы',
			placeholder: 'https://example.com',
			showCondition: schoolShowCondition,
		},
		{
			type: 'switch',
			name: 'is_active',
			value: item.is_active,
			label: 'Состояние',
			showCondition: allShowCondition,
		},
	];

	return (
		<UpdatePage
			title='Сферы / Изменение'
			fields={fields}
			store={store.spheres}
			fetchData={fetchData}
		/>
	);
});

export default SphereUpdate;
