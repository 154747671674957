import { FC, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';

import useQuery from 'hooks/useQuery';
import { DeletePopup, SendPopup } from 'components/Logic';
import { Button, LinkBtn } from 'components/UI';

import { PageHeadProps } from './types';

import styles from './PageHead.module.scss';

const PageHead: FC<PageHeadProps> = ({
	store,
	title,
	backLink,
	hasCreate = true,
	hasDelete = true,
	hasImport = false,
	selectedElements = [],
}) => {
	const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);
	const [isOpenSendModal, setIsOpenSendModal] = useState(false);
	const location = useLocation();

	const { parentId } = useParams();
	const query = useQuery();
	const page = query.get('page') ?? 1;
	const hasBtns = hasCreate || hasDelete || hasImport;

	const onDeleteItems = async () => {
		setIsOpenDeletePopup(false);

		for (const id of selectedElements) {
			await store.removeItem?.(id);
		}

		store.fetchItems(page, parentId);
	};

	const deleteButtonStyles = clsx({
		[styles.disabled]: selectedElements.length === 0,
	});

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<h3 className={styles.title}>{title}</h3>

				{backLink && (
					<Link to={backLink} className={styles.backLink}>
						&#8592; Назад
					</Link>
				)}
			</div>

			{hasBtns && (
				<div className={styles.btns}>
					{hasCreate && (
						<LinkBtn theme='filled' to={'create' + location.search}>
							Добавить элемент
						</LinkBtn>
					)}

					{hasDelete && (
						<Button
							theme='outlined'
							className={deleteButtonStyles}
							onClick={() => setIsOpenDeletePopup(true)}
						>
							Удалить выбранное
						</Button>
					)}

					{hasImport && (
						<Button theme='outlined' onClick={() => setIsOpenSendModal(true)}>
							Загрузить данные
						</Button>
					)}
				</div>
			)}

			<DeletePopup
				isOpenPopup={isOpenDeletePopup}
				closePopup={() => setIsOpenDeletePopup(false)}
				onDeleteItems={onDeleteItems}
			/>

			<SendPopup
				isOpenPopup={isOpenSendModal}
				closePopup={() => setIsOpenSendModal(false)}
				onSendItems={() => {
					console.log('o');
				}}
			/>
		</div>
	);
};

export default PageHead;
