import { useState } from 'react';

import Mark from '../Mark/Mark';

import { EMarkVariant } from '../Mark/types';
import { IMapLayerProps } from './types';

import styles from '../Map/Map.module.scss';

const MapLayer = ({ currentMark, setCurrentMark, pavilionsCoords }: IMapLayerProps) => {
	const [isMoved, setIsMoved] = useState(false);
	const [startX, setStartX] = useState(null);
	const [startY, setStartY] = useState(null);

	const handleMouseDown = (e: any) => {
		setIsMoved(true);
		setStartX(e.clientX);
		setStartY(e.clientY);
	};

	const handleMouseUp = () => {
		setIsMoved(false);
	};

	const handleClick = (e: any) => {
		if (
			startX !== null &&
			startY !== null &&
			Math.abs(e.clientX - startX) < 5 &&
			Math.abs(e.clientY - startY) < 5
		) {
			const y = e.nativeEvent.offsetY;
			const x = e.nativeEvent.offsetX;

			if (y > 10 && x > 10) {
				setCurrentMark({ y, x });
			} else {
				setCurrentMark(null);
			}
		}

		setStartX(null);
		setStartY(null);
	};

	const mapLayerStyles = {
		cursor: isMoved ? 'grabbing' : 'pointer',
	};

	return (
		<div
			style={mapLayerStyles}
			className={styles.mapLayer}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onClick={handleClick}
		>
			{pavilionsCoords.map((pavilion, index) => (
				<Mark variant={EMarkVariant.BLACK} coords={pavilion} key={index} />
			))}

			{currentMark && (
				<Mark variant={EMarkVariant.RED} coords={{ x: currentMark.x, y: currentMark.y }} />
			)}
		</div>
	);
};

export default MapLayer;
