import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import ListPage from 'components/Layouts/ListPage/ListPage';
import SchoolUsersTableHeader from './SchoolUsersTableHeader';
import SchoolUser from './SchoolUser';

import styles from './SchoolUsers.module.scss';

const SchoolUsers: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.schoolUsers}
			title='Пользователи'
			tableHeader={SchoolUsersTableHeader}
			tableClassName={styles.table}
			element={SchoolUser}
			hasDelete={false}
			hasCreate={false}
		/>
	);
});

export default SchoolUsers;
