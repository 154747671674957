import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { Button, Loader, Switch } from 'components/UI';
import { Popup } from 'components/Logic';

import styles from './EngireeingWorks.module.scss';
import { getEngineeringWorksContent } from './config';

const EngineeringWorks = observer(() => {
	const appSettingsStore = useStore().appSettings;

	const [isFirstRender, setIsFirstRender] = useState(true);
	const [isOpenPopup, setIsOpenPopup] = useState(false);

	useEffect(() => {
		(async function () {
			await appSettingsStore.fetchAppWorkingStatus();
			setIsFirstRender(false);
		})();
	}, []);

	const onClose = () => setIsOpenPopup(false);

	const onOpenPopup = () => setIsOpenPopup(true);

	const onChangeStatus = async () => {
		await appSettingsStore.updateWorkingStatus(!appSettingsStore.workingStatus);
		setIsOpenPopup(false);
	};

	const content = getEngineeringWorksContent(!!appSettingsStore.workingStatus);

	return (
		<>
			{isFirstRender ? (
				<Loader />
			) : (
				<Switch
					checked={!appSettingsStore.workingStatus}
					onClick={onOpenPopup}
					className={styles.switch}
					label='Тех. обслуживание'
				/>
			)}

			<Popup isOpen={isOpenPopup} onClose={onClose} className={styles.popup}>
				{appSettingsStore.isLoading ? (
					<Loader />
				) : (
					<>
						<div className={styles.content}>
							<h4 className={styles.title}>{content.title}</h4>
							<p className={styles.description}>{content.description}</p>
						</div>

						<div className={styles.buttons}>
							<Button onClick={onClose}>Отмена</Button>
							<Button theme='filled' onClick={onChangeStatus}>
								Перевести
							</Button>
						</div>
					</>
				)}
			</Popup>
		</>
	);
});

export default EngineeringWorks;
