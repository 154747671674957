import makeRequest from '../makeRequest';

class ExportService {
	async exportList(endpoint: string, query: string) {
		const response = await makeRequest<string>({
			url: `export/${endpoint}${query}`,
			auth: true,
			responseType: 'blob',
		});

		if ('errors' in response) {
			throw response;
		}

		return response;
	}
}

export default new ExportService();
