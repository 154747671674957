/* eslint-disable */
import { observer } from "mobx-react-lite";

import { ReactComponent as IcShortTextRounded } from "assets/icons/formBuilder/ic_short-text.svg";
import { ReactComponent as IcTablerCheckbox } from "assets/icons/formBuilder/ic_checkbox.svg";
import { ReactComponent as IcRadioButton } from "assets/icons/formBuilder/ic_radio.svg";
import React, { FC, useEffect, useReducer, useState } from "react";
import styles from "./СalculatorBuilder.module.scss";
import { Button } from "components/UI";
import EditQuestion from "./EditQuestiontsx/EditQuestion";
import ReportQuestion from "./ReportQuestion";
import { useStore } from "hooks/useStore";
import Tabs from "./Tabs";
import FieldQuestion from "./FieldQuestion";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/UI/Loader/Loader";
import Settings from "./Settings/Settings";
import { PageHeader } from "components/Layouts";
import { staticLinks } from "config/routingLinks";

const СalculatorBuilder: FC<any> = observer(() => {
	const store = useStore();
	const { id } = useParams();

	useEffect(() => {
		store.questions.fetchQuestionsCalculators(id);
		store.questions.fetchConditions(id);
		// store.questions.fetchSettingsForm(id)
	}, []);

	useEffect(() => {
		setResponseTemplateTitle(store.questions?.responseTemplate?.title);
		setResponseTemplateText(store.questions?.responseTemplate?.text);
		setResponseTemplateLink(store.questions?.responseTemplate?.link);
	}, [store.questions?.responseTemplate]);

	useEffect(() => {
		if (store.questions.list) {
			dispatchFields({
				type: ActionType.ADD_FIELDS,
				payload: {
					value: store.questions.list
				}
			});
		}
	}, [store?.questions?.list?.length]);

	const [currentQuestion, setCurrentQuestion]: any = useState(null);
	const [isOpen, setIsOpen]: any = useState(false);
	const [isOpenReport, setIsOpenReport]: any = useState(false);
	const [state, dispatchFields] = useReducer(reducer, {
		fields: store.questions.list || [],
		conditions: []
	});

	const [responseTemplateTitle, setResponseTemplateTitle]: any = useState("");
	const [responseTemplateText, setResponseTemplateText]: any = useState("");
	const [responseTemplateLink, setResponseTemplateLink]: any = useState("");
	const navigate = useNavigate();
	// const [page, setPage] = useState("Constructor");

	const openEditDialog = (field: any) => {
		setCurrentQuestion(field);
		setIsOpen(true);
	};

	const openReportDialog = (field: any) => {
		setCurrentQuestion(field);
		setIsOpenReport(true);
	};

	console.log("state", state.condition);
	return (
		<>
			{store.questions.activeTab === "builder" && (
				<div className={styles.formBuilder}>
					<PageHeader title={"Конструктор калькулятора / Изменение"} />

					<Tabs
						activeTab={store.questions.activeTab}
						setActiveTab={(tabName: string) => store.questions.setActiveTab(tabName)}
					/>
					<div className={styles.body}>
						{!store?.questions.isLoading ? (
							<>
								<div className={styles.previewQuestions}>
									{state?.fields?.length === 0 ? (
										<div className={styles.emptyList}>
											Форма пустая
											<span>Перекиньте сюда вопросы для запроса</span>
										</div>
									) : (
										<ul className={styles.list}>
											{state.fields.map((field: any, index: number) => {
												return (
													<FieldQuestion
														field={field}
														key={index}
														dispatchFields={dispatchFields}
														openEditDialog={openEditDialog}
														openReportDialog={openReportDialog}
													/>
												);
											})}
										</ul>
									)}
								</div>
								<div className={styles.selectQuestions}>
									<div className={styles.title}>Варианты вопросов</div>
									<ul className={styles.listQuestions}>
										<li
											onClick={() => {
												dispatchFields({
													type: ActionType.ADD_SELECT_FIELD,
													payload: {
														value: 1
													}
												});
											}}
										>
											<IcRadioButton />
											<span>Выпадающий список</span>
										</li>
										{/* <li onClick={() => {
										dispatchFields({
											type: ActionType.ADD_RADIO,
											payload: {
												value: 4,
											},
										});
									}}
								>
								<IcRadioButton />
								<span>Один ответ</span>
								</li> */}
										<li
											onClick={() => {
												dispatchFields({
													type: ActionType.ADD_CHECKBOX,
													payload: {
														value: 2
													}
												});
											}}
										>
											<IcTablerCheckbox />
											<span>Несколько ответов</span>
										</li>
										<li
											onClick={() => {
												dispatchFields({
													type: ActionType.ADD_TEXT_FIELD,
													payload: {
														value: 3
													}
												});
											}}
										>
											<IcShortTextRounded />
											<span>Короткий текст</span>
										</li>
										{/* <li onClick={() => {
										dispatchFields({
											type: ActionType.ADD_PHONE_FIELD,
											payload: {
												value: 3,
											},
										});
									}}
								>
								<IcPhone />
								<span>Номер телефона</span>
								</li> */}
										{/* <li onClick={() => {
										dispatchFields({
											type: ActionType.ADD_EMAIL_FIELD,
											payload: {
												value: 3,
											},
										});
									}}
								>
								<IcEmail />
								<span>Email</span>
								</li> */}
										{/* <li onClick={() => {
										dispatchFields({
											type: ActionType.ADD_TAGS_FIELD,
											payload: {
												value: 3,
											},
										});
									}}
								>
								<IcTags />
								<span>Тэги</span>
								</li> */}
										{/* <li onClick={() => {
										dispatchFields({
											type: ActionType.ADD_DATE_FIELD,
											payload: {
												value: 3,
											},
										});
									}}
								>
								<IcDate />
								<span>Дата</span>
								</li> */}
										{/* <li onClick={() => {
										dispatchFields({
											type: ActionType.ADD_FILE_ATACHMENT_FIELD,
											payload: {
												value: 3,
											},
										});
									}}
								>
								<IcShortTextRounded />
								<span>Прикрепить файл</span>
								</li> */}
										{/* <li onClick={() => {
										dispatchFields({
											type: ActionType.ADD_DECREMENT_FIELD,
											payload: {
												value: 3,
											},
										});
									}}
								>
								<IcShortTextRounded />
								<span>Декремент</span>
								</li> */}
									</ul>
								</div>
							</>
						) : (
							<Loader />
						)}
					</div>
					<div className={styles.btns}>
						<Button
							theme={"filled"}
							disabled={state.fields.length === 0}
							onClick={() =>
								store.questions.createQuestionsCalculators(
									{
										questions: state.fields
									},
									id
								)
							}
						>
							Сохранить
						</Button>
						<Button
							disabled={state?.fields?.length === 0}
							onClick={() => navigate(staticLinks.calculatorBuilder)}
							theme="outlined"
						>
							Отменить
						</Button>
					</div>
					{isOpen && (
						<>
							<div onClick={() => setIsOpen(false)} className={styles.background} />
							<EditQuestion
								setIsOpen={(isOpen: boolean) => setIsOpen(isOpen)}
								currentQuestion={currentQuestion}
								dispatchFields={dispatchFields}
							/>
						</>
					)}
					{isOpenReport && (
						<>
							<div onClick={() => setIsOpenReport(false)} className={styles.background} />
							<ReportQuestion
								setIsOpenReport={(isOpen: boolean) => setIsOpenReport(isOpen)}
								currentQuestion={currentQuestion}
								fields={state.fields}
								dispatchFields={dispatchFields}
							/>
						</>
					)}
				</div>
			)}
			{store.questions.activeTab === "settings" && (
				<div className={styles.formBuilder}>
					<PageHeader title={"Конструктор калькулятора / Изменение"} />

					<Tabs
						activeTab={store.questions.activeTab}
						setActiveTab={(tabName: string) => store.questions.setActiveTab(tabName)}
					/>
					{!store?.questions.isLoading ? (
						<Settings state={state} dispatch={dispatchFields} id={id} />
					) : (
						<Loader />
					)}
				</div>
			)}
		</>
	);
});

export enum ActionType {
	SET_DEFAULT_ATTRIBUTE = "SET_DEFAULT_ATTRIBUTE",
	RESET_ATTRIBUTE = "RESET_ATTRIBUTE",
	DELETE_OPTION = "DELETE_OPTION",
	ADD_OPTION = "ADD_OPTION",
	EDIT_TEXT_OPTION = "EDIT_TEXT_OPTION",
	EDIT_LINK_OPTION = "EDIT_LINK_OPTION",
	EDIT_TEXT_LABEL = "EDIT_TEXT_LABEL",
	ADD_RADIO = "ADD_RADIO",
	ADD_FIELD = "ADD_FIELD",
	ADD_CHECKBOX = "ADD_CHECKBOX",
	ADD_TEXT_FIELD = "ADD_TEXT_FIELD",
	ADD_EMAIL_FIELD = "ADD_EMAIL_FIELD",
	ADD_TAGS_FIELD = "ADD_TAGS_FIELD",
	ADD_FILE_ATACHMENT_FIELD = "ADD_FILE_ATACHMENT_FIELD",
	ADD_SELECT_FIELD = "ADD_SELECT_FIELD",
	ADD_DATE_FIELD = "ADD_DATE_FIELD",
	ADD_PHONE_FIELD = "ADD_PHONE_FIELD",
	ADD_DECREMENT_FIELD = "ADD_DECREMENT_FIELD",
	DELETE_FIELD = "DELETE_FIELD",
	ADD_FIELDS = "ADD_FIELDS",
	SELECTED_QUESTION = "SELECTED_QUESTION",
	ADD_CONDITION = "ADD_CONDITION",
	EDIT_TITLE_SETTING = "EDIT_TITLE_SETTING",
	EDIT_TEXT_SETTING = "EDIT_TEXT_SETTING",
	EDIT_LINK_SETTING = "EDIT_LINK_SETTING",
	SET_CONDITIONS = "SET_CONDITIONS",
	EDIT_CONDITIONS = "EDIT_CONDITIONS",
	DELETE_CONDITIONS = "DELETE_CONDITIONS",
	EDIT_OPTION_CONDITIONS = "EDIT_OPTION_CONDITIONS",
	DELETE_ALL_CONDITIONS = "DELETE_ALL_CONDITIONS",
	EDIT_CONDITION_TITLE = "EDIT_CONDITION_TITLE",
	ADD_QUESTION = "ADD_QUESTION",
	DELETE_QUESTION = "DELETE_QUESTION",
	EDIT_QUESTION = "EDIT_QUESTION",
	EDIT_QUESTION_OPERATOR = "EDIT_QUESTION_OPERATOR",
	ADD_ANSWER = "ADD_ANSWER",
	DELETE_ANSWER = "DELETE_ANSWER",
	EDIT_ANSWER_FOR_QUESTION = "EDIT_ANSWER_FOR_QUESTION",
	EDIT_ANSWER_FOR_QUESTION_OPERATOR = "EDIT_ANSWER_FOR_QUESTION_OPERATOR",
}

interface UploadingItem {
	fields: any;
	conditions: any;
}

interface IAction {
	type: ActionType;
	payload: {
		itemIndex: number;
		value: number;
		position: number;
	};
}

// const initialState: any = { fields };
function reducer(state: any, action: any): any {
	const { type, payload } = action;
	const { position, name, itemIndex, options, typeField, value, id, optionName, title } = payload;

	const generateUniqId: any = (fields: any) => {
		const randomNumber = Math.floor(Math.random() * 1000000);

		const fieldIds = fields.map((field: any) => {
			return field.id;
		});

		if (fieldIds.includes(randomNumber)) {
			return generateUniqId(fields);
		} else {
			return randomNumber;
		}
	};

	switch (type) {
		case ActionType.ADD_FIELDS:
			return {
				...state,
				fields: value
			};
		case ActionType.ADD_CONDITION:
			return {
				...state,
				fields: state.fields.map((field: any) =>
					field.position === position
						? {
							...field,
							condition: value.condition
						}
						: field
				)
			};
		case ActionType.ADD_FIELD:
			return {
				...state,
				fields: state.fields.map((field: any) => (field.position === position ? value : field))
			};
		case ActionType.ADD_RADIO:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "Radios",
						title: "Вопрос",
						position: generateUniqId(state.fields),
						options: [
							{
								label: "Ответ 1",
								value: 1
							},
							{
								label: "Ответ 2",
								value: 2
							},
							{
								label: "Ответ 3",
								value: 3
							}
						]
					}
				]
			};
		case ActionType.ADD_SELECT_FIELD:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "Select",
						title: "Выпадающий список",
						position: generateUniqId(state.fields),
						options: [
							{
								label: "Ответ 1",
								value: 1
							},
							{
								label: "Ответ 2",
								value: 2
							},
							{
								label: "Ответ 3",
								value: 3
							}
						]
					}
				]
			};
		case ActionType.ADD_CHECKBOX:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "Checkboxes",
						title: "Вопрос",
						position: generateUniqId(state.fields),
						options: [
							{
								label: "Ответ 1",
								value: 1
							},
							{
								label: "Ответ 2",
								value: 2
							},
							{
								label: "Ответ 3",
								value: 3
							}
						]
					}
				]
			};
		case ActionType.ADD_TEXT_FIELD:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "TextArea",
						title: "Вопрос",
						position: generateUniqId(state.fields)
					}
				]
			};
		case ActionType.ADD_EMAIL_FIELD:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "Email",
						title: "Email",
						position: generateUniqId(state.fields)
					}
				]
			};
		case ActionType.ADD_PHONE_FIELD:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "Phone",
						title: "Номер телефона",
						position: generateUniqId(state.fields)
					}
				]
			};
		case ActionType.ADD_TAGS_FIELD:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "Tags",
						title: "Тэги",
						position: generateUniqId(state.fields)
					}
				]
			};
		case ActionType.ADD_DATE_FIELD:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "Date",
						title: "Дата",
						position: generateUniqId(state.fields)
					}
				]
			};
		case ActionType.ADD_FILE_ATACHMENT_FIELD:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "FileAtachment",
						title: "Прикрепить файл",
						position: generateUniqId(state.fields)
					}
				]
			};
		case ActionType.ADD_DECREMENT_FIELD:
			return {
				...state,
				fields: [
					...state.fields,
					{
						element: "Decrement",
						title: "Количество взрослых",
						position: generateUniqId(state.fields)
					}
				]
			};
		case ActionType.DELETE_FIELD:
			return {
				...state,
				fields: state.fields && state.fields.filter((item: any) => item.position !== position)
			};
		case ActionType.DELETE_CONDITIONS:
			return {
				...state,
				conditions:
					state.conditions && state.conditions.filter((item: any) => item.itemIndex !== itemIndex)
			};
		case ActionType.SET_CONDITIONS:
			const test =
				state.conditions && state.conditions.filter((condition: any) => condition.id !== id);
			return {
				...state,
				conditions: [
					...test,
					{
						values: [],
						itemIndex: itemIndex,
						id: id,
						name: name
					}
				]
			};
		case ActionType.EDIT_CONDITIONS:
			return {
				...state,
				conditions: state.conditions.map((attribute: any) =>
					attribute.itemIndex === itemIndex
						? {
							...attribute,
							name: name,
							typeField: typeField,
							options: options,
							id: id,
							optionName: "",
							values: []
						}
						: attribute
				)
			};
		case ActionType.EDIT_CONDITION_TITLE:
			return {
				...state,
				conditions: state.conditions.map((condition: any) =>
					condition.itemIndex === itemIndex
						? {
							...condition,
							title: title
						}
						: condition
				)
			};
		case ActionType.EDIT_OPTION_CONDITIONS:
			return {
				...state,
				conditions: state.conditions.map((attribute: any) =>
					attribute.itemIndex === itemIndex
						? {
							...attribute,
							optionName: optionName,
							values: value
						}
						: attribute
				)
			};
		case ActionType.DELETE_ALL_CONDITIONS:
			return {
				...state,
				conditions: []
			};
		default:
			console.log("default!!!! case");
			return state;
	}
}

export default СalculatorBuilder;
