import { LinkBtn } from 'components/UI';

import { staticLinks } from 'config/routingLinks';

import { ReactComponent as IcNotFound } from 'assets/icons/general/ic_not-found.svg';
import styles from './NotFound.module.scss';

const NotFound = () => {
	return (
		<section className={styles.notFound}>
			<IcNotFound />
			<h1>Что-то пошло не так</h1>
			<p>Возможно, у вас опечатка в адресе страницы, или её просто не существует :(</p>
			<LinkBtn theme='filled' to={staticLinks.main}>
				Вернуться на главную
			</LinkBtn>
		</section>
	);
};

export default NotFound;
