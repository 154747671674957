import makeRequest from '../makeRequest';
import { GetEditorResponse } from 'interfaces/api/EditorResponse';

class EditorService {
	async uploadByFile(formData: FormData) {
		return await makeRequest<GetEditorResponse>({
			url: 'upload_file',
			auth: true,
			method: 'POST',
			data: formData,
		});
	}
}

export default new EditorService();
