import { FC, useState } from 'react';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import ASelect from './AsyncSelect/AsyncSelect';

import { IOption } from 'components/UI/Select/types';
import { AsyncSelectProps } from './types';

import { ReactComponent as IcDelete } from 'assets/icons/general/ic_close.svg';
import styles from './AsyncSelect.module.scss';

const AsyncSelectBreadCrumbs: FC<AsyncSelectProps> = observer(
	({
		name,
		label,
		extra,
		reset,
		qualities,
		type,
		formikSetter,
		placeholder = '',
		wrapperClassname = '',
	}) => {
		const [selectValue, setSelectValue] = useState<IOption | null>(null);

		const onSelectChange = (option: IOption | null) => {
			const newItem = {
				id: option!.value as number,
				name: option!.label,
			};

			const newArray = [newItem, ...qualities];

			formikSetter(newArray);
			setSelectValue(null);
		};

		const onQualityDelete = (qualityId: number) => {
			const newArray = qualities.filter((quality) => quality.id !== qualityId);

			formikSetter(newArray);
		};

		const containerClasses = clsx(styles.container, {
			[wrapperClassname]: !!wrapperClassname,
		});

		return (
			<div className={containerClasses}>
				<ASelect
					type={type}
					name={name}
					label={label}
					placeholder={placeholder}
					onSelectChange={onSelectChange}
					selectValue={selectValue}
					reset={reset}
					idsList={qualities?.map((quality) => quality.id)}
				/>

				{extra && <span className={styles.extra}>{extra}</span>}

				{!!qualities.length && (
					<ul className={styles.list}>
						{qualities.map((quality) => (
							<li className={styles.item} key={quality.id}>
								<span className={styles.title}>{quality.name}</span>

								<button
									className={styles.delete}
									onClick={() => onQualityDelete(quality.id)}
									type='button'
								>
									<IcDelete />
								</button>
							</li>
						))}
					</ul>
				)}
			</div>
		);
	},
);

export default AsyncSelectBreadCrumbs;
