import { FC, useEffect, useState } from 'react';
import {ActionMeta, SingleValue} from 'react-select';

import Select from '../Select/Select';

import AsyncSelectService from 'API/rest/AsyncSelectService';
import useDebounce from './utils';

import { IOption } from 'components/UI/Select/types';
import { TASelectProps, TSimpleEntity } from './types';

export const ASelect: FC<TASelectProps> = ({
	type,
	name,
	label,
	reset = false,
	className,
	onSelectChange,
	selectValue,
	placeholder,
	idsList,
}) => {
	const [inputValue, setInputValue] = useState<string>('');
	const [options, setOptions] = useState<IOption[]>([]);
	const [isSearching, setIsSearching] = useState(false);

	const debouncedValue = useDebounce(inputValue, 500);

	const fetchConfig = {
		employer_profiles: {
			get: (query: string) => AsyncSelectService.fetchEmployers(query),
		},
		calendar_events: {
			get: (query: string) => AsyncSelectService.fetchCalendarEvents(query),
		},
		cities: {
			get: (query: string) => AsyncSelectService.fetchCities(query),
		},
		specialities: {
			get: (query: string) => AsyncSelectService.fetchSpecialities(query),
		},
		events: {
			get: (query: string) => AsyncSelectService.fetchEvents(query),
		}
	};

	const onInputChange = (event: string) => setInputValue(event);

	const convertToSelectOptions = (array: TSimpleEntity[]) =>
		array.map((item) => ({ value: item.id, label: item.name }));
	const convertToSelectOptionsWithoutId = (array: TSimpleEntity[]) =>
		array.map((item) => ({ value: item.name, label: item.name }));
	const getOptions = async (query: string) => {
		if (query) {
			const response = await fetchConfig[type].get(query);

			if ('data' in response) {
				const selectOptions = idsList
					? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
					  // @ts-ignore
					  response.data[type].filter(
							(option: TSimpleEntity) => idsList?.findIndex((id) => id === option.id) === -1,
					  )
					: // eslint-disable-next-line @typescript-eslint/ban-ts-comment
					  // @ts-ignore
					  response.data[type];
				if(type === 'cities') {
					setOptions(convertToSelectOptionsWithoutId(selectOptions));
				}else {
					setOptions(convertToSelectOptions(selectOptions));
				}
			}
		}

		setIsSearching(false);
	};

	useEffect(() => {
		if (debouncedValue) {
			setIsSearching(true);
			getOptions(debouncedValue as string);
		} else {
			setOptions([]);
		}
	}, [debouncedValue]);

	const noOptionsMessage = () => (isSearching ? 'Поиск...' : 'Введите новый запрос');

	const onSelectChangeValue = (newValue: SingleValue<IOption>, actionMeta: ActionMeta<IOption>) => {
		if(newValue?.value !== '') {
			onSelectChange(newValue);
		} else {
			onSelectChange({value: '', label: ''});
		}
	};

	return (
		<Select
			placeholder={placeholder || 'Введите запрос'}
			name={name}
			label={label}
			noOptionsMessage={noOptionsMessage}
			inputValue={inputValue}
			value={selectValue}
			onChange={onSelectChangeValue}
			onInputChange={onInputChange}
			options={selectValue?.value!== '' && reset ? [...options, {value: '', label: 'Сбросить'}] : options}
			className={className}
		/>
	);
};

export default ASelect;
