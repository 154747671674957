import { GetActivitiesResponse, GetActivityResponse } from 'interfaces/api/ActivityResponse';

import makeRequest from '../makeRequest';

export class ActivityService {
	async fetchActivities() {
		const response = await makeRequest<GetActivitiesResponse>({
			url: 'activities',
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.activities,
			meta: response.data.meta,
		};
	}

	async fetchActivity(id: string) {
		const response = await makeRequest<GetActivityResponse>({
			url: `activities/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.activity,
		};
	}

	async createActivity(event: FormData) {
		const response = await makeRequest<GetActivityResponse>({
			url: 'activities',
			method: 'post',
			auth: true,
			data: event,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.activity,
		};
	}

	async updateActivity(event: FormData) {
		const response = await makeRequest<GetActivityResponse>({
			url: `activities/${event.get('id')}`,
			method: 'post',
			auth: true,
			data: event,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.activity,
		};
	}

	async updateActivitiesOrder(ids: number[]) {
		return await makeRequest<void>({
			url: 'order_activity',
			method: 'post',
			auth: true,
			data: { ids },
		});
	}

	deleteActivity(id: number) {
		return makeRequest<void>({
			url: `activities/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new ActivityService();
