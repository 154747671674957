export const getSortDirection = (direction: string | null) => {
	if (direction === 'desc') {
		return 'desc';
	}
	else if (direction === 'asc') {
		return 'asc';
	}
	else {
		return 'default';
	}
};
