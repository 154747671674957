import { FC } from 'react';

import {
	activeOptions,
	ChildTableHeaderProps,
	TableHeader,
	THeaderField,
} from 'components/Layouts';

import styles from './Professions.module.scss';

const ProfessionsTableHeader: FC<ChildTableHeaderProps> = ({
	isAllSelected,
	selectAllElements,
}) => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Профессия', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Состояние', name: 'is_active', type: 'select', options: activeOptions, hasSorting: true },
	];

	return (
		<TableHeader
			className={styles.row}
			fields={tableFields}
			isAllSelected={isAllSelected}
			selectAllElements={selectAllElements}
		/>
	);
};

export default ProfessionsTableHeader;
