import makeRequest from 'API/makeRequest';
import {
	GetResumesResponse,
	GetResumeResponse,
	GetResumeHistoryResponse,
} from 'interfaces/api/ResumeResponse';

class ResumeService {
	async fetchResumes(query: string) {
		const response = await makeRequest<GetResumesResponse>({
			url: `resumes?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.resumes,
			meta: response.data.meta,
		};
	}

	async fetchResume(id: string) {
		const response = await makeRequest<GetResumeResponse>({
			url: `resumes/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.resume,
		};
	}

	async updateResume(id: string, is_approved: boolean | null) {
		const response = await makeRequest<GetResumeResponse>({
			url: `resumes/${id}`,
			method: 'put',
			auth: true,
			data: { is_approved },
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.resume,
		};
	}

	async fetchResumeHistory(id: string) {
		const response = await makeRequest<GetResumeHistoryResponse>({
			url: `resumes/${id}/history`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return response;
	}

	async downloadList() {
		const response = await makeRequest<string>({
			url: 'export/resumes',
			auth: true,
			responseType: 'blob',
		});

		if ('errors' in response) {
			throw response;
		}

		return response;
	}
}

export default new ResumeService();
