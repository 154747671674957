import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts/';
import PavilionsTableHeader from './PavilionsTableHeader';
import Pavilion from './Pavilion';

import styles from './Pavilions.module.scss';

const PavilionsOstrova: FC = observer(() => {
	const store = useStore();
	return (
		<ListPage
			store={store.pavilionsOstrova}
			title='Место проведения'
			hasDelete={false}
			element={Pavilion}
			tableHeader={PavilionsTableHeader}
			tableClassName={styles.table}
		/>
	);
});

export default PavilionsOstrova;
