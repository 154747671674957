import { Button } from 'components/UI';

import { IFriendWorkLinkProps } from './types';

import { ReactComponent as IcLink } from 'assets/icons/general/ic_external-link.svg';

const FriendWorkLink = ({ link }: IFriendWorkLinkProps) => {
	return (
		<a href={link} rel='noreferrer' target='_blank'>
			<Button theme='filled'>
				<IcLink />
				Анкета FriendWork
			</Button>
		</a>
	);
};

export default FriendWorkLink;
