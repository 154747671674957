import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import { Switch } from 'components/UI';

import { ReportProps } from './types';

import styles from './Reports.module.scss';

const Report: FC<ReportProps> = observer(({ item }) => {
	const store = useStore();
	const navigate = useNavigate();
	const location = useLocation();

	const objectName =
		item.vacancy !== undefined
			? item.vacancy.name
			: item.resume !== undefined
			? item.resume.position
			: item.employer_profile !== undefined
			? item.employer_profile.employer_name
			: item.course !== undefined
			? item.course.name
			: null;

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update${location.search}`);
	};

	const changeSwitch = () => {
		item.is_reviewed = !item.is_reviewed;
		store.reports.updateItem(item);
	};

	const typeTitle =
		item.type === 'Vacancy'
			? 'Вакансия'
			: item.type === 'Resume'
			? 'Анкета'
			: item.type === 'EmployerProfile'
			? 'Предприятие'
			: item.type === 'Course'
			? 'Курс'
			: null;

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{typeTitle}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{objectName}</span>
			</div>
			<div className={styles.cell}>
				<span className={styles.time}>{item.created_at}</span>
			</div>
			<div className={styles.switchWrapper} onClick={(e) => e.stopPropagation()}>
				<Switch checked={item.is_reviewed} onChange={changeSwitch} />
			</div>
			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Изменить</span>
				</div>
			</div>
		</div>
	);
});

export default Report;
