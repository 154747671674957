import { FC } from 'react';
import clsx from 'clsx';

import { RadioProps } from './types';

import styles from './CheckFields.module.scss';

const Radio: FC<RadioProps> = ({ label, className = '', type = 'radio', ...props }) => {
	const radioStyle = clsx(styles.radio, {
		[className]: className,
	});

	return (
		<label className={radioStyle} onClick={(event) => event.stopPropagation()}>
			<input type={type} {...props} />

			<span>{label}</span>
		</label>
	);
};

export default Radio;
