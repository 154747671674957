import { ChampionshipService } from 'API';
import { IChampionshipRead, IChampionshipCreate } from 'interfaces/entities/IChampionship';
import { ListStore } from 'store/general/List/lIstStore';

class ChampionshipStore extends ListStore<IChampionshipRead, IChampionshipCreate> {
	constructor() {
		super({
			APIFetchItems: ChampionshipService.fetchChampionships,
			APIFetchItem: ChampionshipService.fetchChampionship,
			APICreateItem: ChampionshipService.createChampionship,
			APIUpdateItem: ChampionshipService.updateChampionship,
			APIRemoveItem: ChampionshipService.deleteChampionship,
		});
	}
}

export default ChampionshipStore;
