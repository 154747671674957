import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import SoftwareProductsGroup from './SoftwareProduct';
import SoftwareProductsTableHeader from './SoftwareProductsTableHeader';

import styles from './SoftwareProducts.module.scss';

const SoftwareProducts: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.softwareProducts}
			title='Программные продукты'
			tableHeader={SoftwareProductsTableHeader}
			tableClassName={styles.table}
			element={SoftwareProductsGroup}
			hasDelete={false}
		/>
	);
});

export default SoftwareProducts;
