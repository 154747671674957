import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts/';
import Profession from './Profession';
import ProfessionsTableHeader from './ProfessionsTableHeader';

const Professions: FC = observer(() => {
	const store = useStore();

	const { sphere_id } = useParams();
	store.professions.setIdCurrentSphere(+sphere_id!);

	const pageTitle = `Профессии / ${store.spheres.element.name || 'Загрузка...'}`;

	useEffect(() => {
		store.spheres.fetchItem(sphere_id);
	}, [sphere_id]);

	return (
		<ListPage
			store={store.professions}
			title={pageTitle}
			tableHeader={ProfessionsTableHeader}
			element={Profession}
		/>
	);
});

export default Professions;
