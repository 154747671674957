import { EventService } from 'API';
import { IEventRead } from 'interfaces/entities/IEvent';
import { ListStore } from 'store/general/List/lIstStore';

class EventStore extends ListStore<IEventRead, FormData> {
	constructor() {
		super({
			APIFetchItems: EventService.fetchEvents,
			APIFetchItem: EventService.fetchEvent,
			APICreateItem: EventService.createEvent,
			APIUpdateItem: EventService.updateEvent,
			APIRemoveItem: EventService.deleteEvent,
		});
	}
}

export default EventStore;
