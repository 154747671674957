import { FieldsType } from 'components/Logic/FieldByType/types';

export const createInitialValues = (fields: FieldsType[]) =>
	fields.reduce((acc: any, field) => {
		let name = field.name;
		let value;

		if ('value' in field) {
			value = field.value;
		} else if ('url' in field) {
			value = field.url;
			name = 'url';
		} else {
			value = '';
		}

		acc[name] = value;

		return acc;
	}, {});

export const createInitialValuesTwoFiles = (fields: FieldsType[]) =>
	fields.reduce((acc: any, field) => {
		const name = field.name;
		let value;

		if ('value' in field) {
			value = field.value;
		} else if ('url' in field) {
			value = field.url;
		} else {
			value = '';
		}

		acc[name] = value;

		return acc;
	}, {});
