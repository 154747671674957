import { FC, useState } from 'react';
import moment from 'moment';

import { Button, Loader, Select } from 'components/UI';
import { IOption } from 'components/UI/Select/types';

import { ExportService } from 'API';

import { ExportProps, TExportItem } from './types';

import styles from './Export.module.scss';

const Export: FC<ExportProps> = ({ exportList, query, theme }) => {
	const [isLoading, setLoading] = useState(false);
	const isSingleItem = exportList.length === 1;

	const downloadList = async (item: TExportItem) => {
		setLoading(true);

		const response = await ExportService.exportList(item.path, query);
		if ('data' in response) {
			const fileContent = response.data;
			const fileName = `${item.fileWord}(${moment().format('DD.MM.YYYY')}).xlsx`;

			const url = window.URL.createObjectURL(new Blob([fileContent]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
		}

		setLoading(false);
	};

	const onChangeOption = (option: IOption | null) => {
		if (!option) return null;

		const findItem = exportList.find((item) => option.value === item.path);

		if (findItem) downloadList(findItem);
	};

	if (!exportList.length) return null;

	if (isLoading)
		return (
			<div className={styles.export}>
				<Loader />
			</div>
		);

	if (isSingleItem)
		return (
			<Button theme={theme || 'filled'} onClick={() => downloadList(exportList[0])}>
				Выгрузить
			</Button>
		);

	return (
		<div className={styles.export}>
			<Select
				placeholder='Выгрузить'
				onChange={onChangeOption}
				options={exportList.map(({ path: value, label }) => ({ value, label }))}
				menuPlacement='top'
			/>
		</div>
	);
};

export default Export;
