import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';

import { useStore } from 'hooks/useStore';
import { TextField, Button, Validation } from 'components/UI';

import { staticLinks } from 'config/routingLinks';
import { globalFormikConfig } from 'config/globalFormikConfig';

import styles from './Auth.module.scss';

const Auth: FC = observer(() => {
	const store = useStore();
	const navigate = useNavigate();

	const formik = useFormik({
		...globalFormikConfig,
		initialValues: {
			email: '',
			password: '',
			errors: '',
		},
		onSubmit: async (values) => {
			const email = values.email;
			const password = values.password;
			const response = await store.auth.login(email, password);

			if ('errors' in response) {
				formik.setErrors(response.errors);

				if (response.status === 401) {
					formik.setErrors({ errors: 'Неверный логин или пароль' });
				}
			} else {
				navigate(staticLinks.main);
			}
		},
	});

	return (
		<form className={styles.auth} onSubmit={formik.handleSubmit}>
			<h1 className={styles.title}>Админ-панель</h1>

			<TextField
				name='email'
				label='Электронная почта'
				value={formik.values.email}
				onChange={formik.handleChange}
				wrapperClassName={styles.field}
			/>
			<Validation errors={formik.errors?.email} touched={formik.touched?.email} />

			<TextField
				name='password'
				label='Пароль'
				type='password'
				value={formik.values.password}
				onChange={formik.handleChange}
				wrapperClassName={styles.field}
			/>
			<Validation errors={formik.errors?.password} touched={formik.touched?.password} />
			<Validation errors={formik.errors?.errors} touched={formik.touched?.errors} />

			<div className={styles.btn}>
				<Button type='submit' theme='filled'>
					Войти в систему
				</Button>
			</div>
		</form>
	);
});

export default Auth;
