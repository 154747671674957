import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts/';
import Sphere from './Sphere';
import SpheresTableHeader from './SpheresTableHeader';

import styles from './Spheres.module.scss';

const Spheres: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.spheres}
			title='Сферы'
			tableHeader={SpheresTableHeader}
			tableClassName={styles.table}
			element={Sphere}
		/>
	);
});

export default Spheres;
