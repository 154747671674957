import {useNavigate} from 'react-router-dom';
import { FC } from 'react';

import { PageHeaderProps } from './types';

import styles from './PageHeader.module.scss';

const PageHeader: FC<PageHeaderProps> = ({ title, backLink }) => {
	const navigate = useNavigate();

	function goBack() {
		if (window.history.length > 1) {
			navigate(-1);
		} else {
			if(backLink) {
				navigate(backLink);
			}
		}
	}

	return (
		<div className={styles.header}>
			<h1 className={styles.title}>{title}</h1>

			{backLink && (
				<a onClick={()=> goBack()} className={styles.backLink}>
					&#8592; Назад
				</a>
			)}
		</div>
	);
};

export default PageHeader;
