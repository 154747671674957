import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { AdditionalApplicationProps } from './types';

import styles from './AdditionalApplications.module.scss';

const Application: FC<AdditionalApplicationProps> = observer(({ item }) => {
	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<span>{item.id}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.email}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.phone}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.created_at}</span>
			</div>
		</div>
	);
});

export default Application;
