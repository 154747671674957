import { ComponentType, createElement, FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { PageHead } from 'components/Layouts';
import { Loader } from 'components/UI';
import {DeletePopup, Pagination} from 'components/Logic';

import { CardsPageProps } from './types';

import styles from './CardsPage.module.scss';
import {useSearchParams} from 'react-router-dom';

const CardsPage: FC<CardsPageProps> = observer(({ title, store, element }) => {
	const [currentDeleteItem, setCurrentDeleteItem] = useState<number | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const { list, isLoading, isEmpty } = store;

	const closeDeleteModal = () => setCurrentDeleteItem(null);
	const openDeleteModal = (id: number) => setCurrentDeleteItem(id);

	const onDelete = () => {
		store.removeItem(currentDeleteItem as number);
		closeDeleteModal();
	};

	useEffect(() => {
		store.fetchItems(searchParams.toString());
	}, [searchParams,store]);

	if (isLoading) {
		return (
			<section className={styles.cardsPage}>
				<PageHead title={title} store={store} hasCreate={true} hasDelete={false} />

				<Loader />
			</section>
		);
	}

	if (isEmpty) {
		return (
			<section className={styles.cardsPage}>
				<PageHead title={title} store={store} hasCreate={true} hasDelete={false} />

				<div className={styles.emptyContainer}>
					<h5>Список пуст</h5>
					<span>Добавьте новые элементы</span>
				</div>
			</section>
		);
	}

	return (
		<section className={styles.cardsPage}>
			<PageHead title={title} store={store} hasCreate={true} hasDelete={false} />

			<ul className={styles.list}>
				{list?.map((item: any) => {
					return createElement(element as ComponentType<any>, {
						key: item.id,
						store: store,
						item: item,
						openDeleteModal: openDeleteModal,
					});
				})}
			</ul>
			<Pagination currentPage={store.currentPage} lastPage={store.lastPage}  />

			<DeletePopup
				onDeleteItems={onDelete}
				isOpenPopup={currentDeleteItem !== null}
				closePopup={closeDeleteModal}
			/>
		</section>
	);
});

export default CardsPage;
