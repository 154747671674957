import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ApprovePage } from 'components/Layouts';

const AnswerRead: FC = observer(() => {
	const store = useStore();
	const [isLoading, setLoading] = useState(true);
	const { id } = useParams();
	const item = store.answers.element;

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			if (id !== undefined) {
				await store.answers.fetchItem(id);
			}
			setLoading(false);
		};
		fetchData();
	}, [id]);

	const mainInfo = item.answers?.map((answer) => {
		return {
			title: answer.question,
			text: answer.answer,
		};
	});

	const secondaryInfo = [
		{
			header: 'Пользователь',
			blocks: [
				[
					{
						title: 'Имя',
						text: item.applicant_profile?.full_name,
					},
					{
						title: 'Статус',
						text: item.applicant_profile?.status.name,
					},
					{
						title: 'Телефон',
						text: item.applicant_profile?.phone,
					},
					{
						title: 'Почта',
						text: item.applicant_profile?.email,
					},
				],
			],
		},
	];

	return (
		<ApprovePage
			isLoading={isLoading}
			title={'Ответы запросов / Просмотр'}
			mainInfo={mainInfo}
			secondaryInfo={secondaryInfo}
		/>
	);
});

export default AnswerRead;
