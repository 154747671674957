import { FC } from 'react';

import { AsideInfoProps } from './types';

import styles from './Documents.module.scss';

const AsideInfo: FC<AsideInfoProps> = ({ id,isApplicant, user }) => {

	const fullName = [user.applicant_profile?.last_name, user.applicant_profile?.first_name, user.applicant_profile?.patronymic_name]
		.filter(Boolean)
		.join(' ');

	return (
		<aside className={styles.aside}>
			<span className={styles.title}>Информация</span>

			<ul className={styles.list}>
				<li className={styles.item}>
					<span>ID</span>
					<p>{id}</p>
				</li>
				{isApplicant
					? <li className={styles.item}>
						<span>ФИО</span>
						<p>{fullName}</p>
					</li>
					:<li className={styles.item}>
						<span>Название компании</span>
						<p>{user.employer_profile?.employer_name}</p>
					</li>

				}

			</ul>
		</aside>
	);
};

export default AsideInfo;
