// eslint-disable-next-line @typescript-eslint/ban-types
const useDebounce = (fn: Function, ms: number) => {
	let timeoutId: NodeJS.Timeout;

	return function (...args: any[]) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => fn(...args), ms);
	};
};

export default useDebounce;
