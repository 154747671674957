import makeRequest from 'API/makeRequest';
import {
	GetCareerToolResponse,
	GetCareerToolsResponse,
} from '../../interfaces/api/CareerToolResponse';
import { ICareerToolRead } from '../../interfaces/entities/ICareerTools';

class CareerToolService {
	async fetchCareerTools(query: string) {
		const response = await makeRequest<GetCareerToolsResponse>({
			url: `career_tools?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.career_tools,
			meta: response.data.meta,
		};
	}

	async fetchCareerTool(id: string) {
		const response = await makeRequest<GetCareerToolResponse>({
			url: `career_tools/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.career_tool,
		};
	}

	async updateCareerTool(careerTool: ICareerToolRead) {
		const response = await makeRequest<GetCareerToolResponse>({
			url: `career_tools/${careerTool.id}`,
			method: 'put',
			auth: true,
			data: { is_reviewed: careerTool.is_reviewed },
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.career_tool,
		};
	}

	deleteCareerTool(id: number) {
		return makeRequest<void>({
			url: `career_tools/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new CareerToolService();
