import makeRequest from 'API/makeRequest';
import { GetProfessionResponse, GetProfessionsResponse } from 'interfaces/api/ProfessionResponse';
import { IProfessionCreate } from 'interfaces/entities/IProfession';

class ProfessionService {
	async fetchProfessions(sphereId: number, query: string) {
		const response = await makeRequest<GetProfessionsResponse>({
			url: `spheres/${sphereId}/professions?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.professions,
			meta: response.data.meta,
		};
	}

	async fetchProfession(sphereId: number, id: string) {
		const response = await makeRequest<GetProfessionResponse>({
			url: `spheres/${sphereId}/professions/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.profession,
		};
	}

	async createProfession(sphereId: number, profession: IProfessionCreate) {
		const response = await makeRequest<GetProfessionResponse>({
			url: `spheres/${sphereId}/professions`,
			method: 'post',
			auth: true,
			data: profession,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.profession,
		};
	}

	async updateProfession(sphereId: number, profession: IProfessionCreate & { id: number }) {
		const response = await makeRequest<GetProfessionResponse>({
			url: `spheres/${sphereId}/professions/${profession.id}`,
			method: 'put',
			auth: true,
			data: profession,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.profession,
		};
	}

	deleteProfession(sphereId: number, id: number) {
		return makeRequest<void>({
			url: `spheres/${sphereId}/professions/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new ProfessionService();
