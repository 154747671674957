import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/UI';

import { StatusCardProps } from './types';

import { ReactComponent as IcHumans } from 'assets/icons/general/ic_humans.svg';
import { ReactComponent as IcColumns } from 'assets/icons/general/ic_columns.svg';
import { ReactComponent as IcDelete } from 'assets/icons/general/ic_trash.svg';
import { ReactComponent as IcPlug } from 'assets/icons/general/ic_card-plug.svg';
import styles from './StatusCard.module.scss';

const StatusCard: FC<StatusCardProps> = observer(({ item, openDeleteModal }) => {
	const navigate = useNavigate();

	const onLinkToUpdate = () => {
		navigate(`${item.id}/update`);
	};

	return (
		<li className={styles.item}>
			{item.image_url ? (
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}/${item.image_url}`}
					alt='image'
					className={styles.image}
				/>
			) : (
				<IcPlug className={styles.imagePlug} />
			)}

			<span className={styles.title}>{item.name}</span>
			<p className={styles.description}>{item.description}</p>

			<ul className={styles.categories}>
				<li className={styles.category}>
					<IcHumans />
					<span>{item.status.name}</span>
				</li>
				<li className={styles.category}>
					<IcColumns />
					<span>{item.tab_name}</span>
				</li>
			</ul>

			<div className={styles.btns}>
				<Button theme='filled' onClick={onLinkToUpdate} size='small' className={styles.edit}>
					Редактировать
				</Button>
				<Button
					theme='danger'
					onClick={() => openDeleteModal(item.id)}
					size='small'
					className={styles.delete}
				>
					<IcDelete />
				</Button>
			</div>
		</li>
	);
});

export default StatusCard;
