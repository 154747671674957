import {ListStore} from '../../general/List/lIstStore';
import CareerGuidanceService from 'API/rest/CareerGuidanceService';
import {ICareerGuidanceRead} from 'interfaces/entities/ICareerGuidance';

class CareerGuidanceStore extends ListStore<ICareerGuidanceRead, FormData> {
	constructor() {
		super({
			APIFetchItems: CareerGuidanceService.fetchCareerGuidances,
		});
	}
}

export default CareerGuidanceStore;
