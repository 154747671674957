import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';

import { Button } from 'components/UI';

import { IActivityProps } from './types';

import { ReactComponent as IcDelete } from 'assets/icons/general/ic_trash.svg';
import { ReactComponent as IcPlug } from 'assets/icons/general/ic_card-plug.svg';
import styles from './Activities.module.scss';

const Activity: FC<IActivityProps> = ({ item, openDeleteModal, index }) => {
	const navigate = useNavigate();

	const onLinkToUpdate = () => {
		navigate(`${item.id}/update`);
	};

	return (
		<Draggable draggableId={item.id.toString()} index={index}>
			{(provided) => (
				<li
					className={styles.item}
					draggable={true}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<div className={styles.imageWrapper}>
						{item.image_url ? (
							<img
								src={`${process.env.REACT_APP_BACKEND_URL}/${item.image_url}`}
								alt='image'
								className={styles.image}
							/>
						) : (
							<IcPlug className={styles.imagePlug} />
						)}
					</div>

					<div className={styles.content}>
						<span className={styles.title}>{item.title}</span>
						<p className={styles.description}>{item.text}</p>

						<div className={styles.btns}>
							<Button theme='filled' onClick={onLinkToUpdate} size='small' className={styles.edit}>
								Редактировать
							</Button>
							<Button
								theme='danger'
								onClick={() => openDeleteModal(item.id)}
								size='small'
								className={styles.delete}
							>
								<IcDelete />
							</Button>
						</div>
					</div>
				</li>
			)}
		</Draggable>
	);
};

export default Activity;
