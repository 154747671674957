import { SpecialityService } from 'API';
import { ISpecialityRead, ISpecialityCreate } from 'interfaces/entities/ISpeciality';
import { ListStore } from 'store/general/List/lIstStore';

class SpecialityStore extends ListStore<ISpecialityRead, ISpecialityCreate> {
	constructor() {
		super({
			APIFetchItems: SpecialityService.fetchSpecialities,
			APIFetchItem: SpecialityService.fetchSpeciality,
			APICreateItem: SpecialityService.createSpeciality,
			APIUpdateItem: SpecialityService.updateSpeciality,
			APIRemoveItem: SpecialityService.deleteSpeciality,
		});
	}
}

export default SpecialityStore;
