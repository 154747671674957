import { action, makeObservable, observable } from 'mobx';

export class PaginationStore {
	currentPage = 1;
	lastPage = 1;

	constructor() {
		makeObservable(this, {
			currentPage: observable,
			lastPage: observable,
			setCurrentPage: action,
			setLastPage: action,
		});
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	setLastPage(page: number) {
		this.lastPage = page;
	}
}
