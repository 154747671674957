/* eslint-disable */
import clsx from "clsx";
import { Button, Radio, Select } from "components/UI";
import React, { useEffect, useReducer, useState } from "react";
import styles from "./Formbuilder.module.scss";
import { ActionType } from "./FormBuilder";
import { IOption } from "../../components/UI/Select/types";

// const initialState: any = { attributes: []};
function reducer(state: any, action: any): any {
	const { type, payload } = action;
	const { value, label, link } = payload;

	const generateUniqOptionId: any = (fields: any) => {
		const randomNumber = Math.floor(Math.random() * 100);

		const optionsIds = fields.map((field: any) => {
			return field.value;
		});

		if (optionsIds.includes(randomNumber)) {
			return generateUniqOptionId(fields);
		} else {
			return randomNumber;
		}
	};

	switch (type) {
		case ActionType.RESET_ATTRIBUTE:
			return {
				...state,
				attributes: []
			};
		case ActionType.EDIT_TEXT_LABEL:
			return {
				...state,
				title: label
			};
		case ActionType.EDIT_TEXT_OPTION:
			console.log("option.value", state.options);
			console.log("option.value", value);
			return {
				...state,
				options: state.options.map((option: any) =>
					option.value === value
						? {
							...option,
							label
						}
						: option
				)
			};

		case ActionType.EDIT_LINK_OPTION:
			return {
				...state,
				options: state.options.map((option: any) =>
					option.value === value
						? {
							...option,
							link
						}
						: option
				)
			};
		case ActionType.ADD_OPTION:
			return {
				...state,
				options: [
					...state.options,
					{
						label: "Ответ",
						value: generateUniqOptionId(state.options)
					}
				]
			};
		case ActionType.DELETE_OPTION:
			return {
				...state,
				options: state.options && state.options.filter((item: any) => item.value !== value)
			};
		default:
			console.log("default case");
			return state;
	}
}

const ReportQuestion = ({ currentQuestion, dispatchFields, fields, setIsOpenReport }: any) => {
	const [state, dispatch] = useReducer(reducer, currentQuestion);
	const [isAlways, setIsAlways] = useState(true);
	const [selectedQuestion, setSelectedQuestion]: any = useState(null);
	const [rule, setRule] = useState("Равно");
	const [selectedAnswer, setSelectedAnswer] = useState("");
	const elementWithCondition = ["Select", "Radios", "Checkboxes"];
	// const [selectedQuestion, setSelectedQuestion] = useState("");

	useEffect(() => {
		if (currentQuestion?.condition?.type === "custom") {
			setIsAlways(false);
			const question = fields.find(
				(field: any) =>
					field.position === currentQuestion.condition.conditions[0].question_position
			);
			setSelectedQuestion(question);
			setRule(currentQuestion.condition.conditions[0].answer_rule);
			setSelectedAnswer(currentQuestion.condition.conditions[0].answer);
		}
	}, []);

	const ruleOptions = [
		{ label: "Равно", value: "equal" },
		{ label: "Не равно", value: "not_equal" }
	];

	const fieldsWithCondition = fields.filter(
		(field: { element: string; position: number }) =>
			elementWithCondition.includes(field.element) && currentQuestion.position !== field.position
	);
	const formatResult = fieldsWithCondition.map(
		(field: { title: string; position: number; element: string }) => {
			return { label: field.title, value: field.position };
		}
	);

	const onChangeQuestion = (option: IOption | null) => {
		const question = fields.find((field: any) => field.position === (option?.label as string));
		setSelectedQuestion(question);
		setSelectedAnswer(question.options[0].label);
	};

	const onChangeRule = (option: IOption | null) => {
		setRule(option?.label as string);
	};

	const onChangeAnswer = (option: IOption | null) => {
		setSelectedAnswer(option?.label as string);
	};

	// 	"condition": {
	// 		"type": "custom",
	// 		"conditions": [
	// 				{
	// 						"question_id": 1,
	// 						"answer_rule": "equal",
	// 						"answer_id": [1]
	// 				}
	// 		]
	// }

	const textFieldClasses = clsx({
		[styles.filterField]: true
		// [styles.filterInactive]: column.name !== filterName && !isMustSelect,
		// [styles.filterSelectInactive]: column.name !== filterName && isMustSelect,
	});

	console.log("selectedQuestion", selectedQuestion);
	return (
		<div className={styles.editQuestion}>
			<div className={styles.reportTitle}>
				Добавить условие
				<span>Вопрос «{currentQuestion.title}»</span>
			</div>

			<div className={styles.reportCheckBox}>
				<span>Будет показан</span>
				<Radio label="Всегда" onChange={() => setIsAlways(true)} checked={isAlways} />
				<Radio label="При условии" checked={!isAlways} onChange={() => setIsAlways(false)} />
			</div>
			{!isAlways && (
				<div className={styles.formGroup}>
					<Select
						options={formatResult}
						onChange={onChangeQuestion}
						placeholder="Выберете вопрос"
						className={styles.select}
						value={{
							label: selectedQuestion?.title || "Выберете вопрос",
							value: selectedQuestion?.title || "Выберете вопрос"
						}}
					/>
					<Select
						options={ruleOptions}
						placeholder="Условие"
						onChange={onChangeRule}
						className={styles.selectRule}
						value={{ label: rule, value: rule }}
					/>
					<Select
						options={selectedQuestion?.options || []}
						placeholder="Выберете ответ"
						onChange={onChangeAnswer}
						className={styles.select}
						disabled={!selectedQuestion}
						value={{
							label: selectedAnswer || "Выберете ответ",
							value: selectedAnswer || "Выберете ответ"
						}}
					/>
				</div>
			)}
			<div className={styles.btnsEdit}>
				<Button
					onClick={() => {
						dispatchFields({
							type: ActionType.ADD_FIELD,
							payload: {
								value: !isAlways
									? {
										...state,
										condition: {
											type: "custom",
											conditions: [
												{
													question_position: selectedQuestion.position,
													answer_rule: rule,
													answer: selectedAnswer
												}
											]
										}
									}
									: state,
								position: currentQuestion.position,
								id: currentQuestion.id
							}
						});
						setIsOpenReport(false);
					}}
					theme="filled"
				>
					Сохранить
				</Button>
				<Button onClick={() => setIsOpenReport(false)} theme="outlined">
					Отменить
				</Button>
			</div>
		</div>
	);
};

export default ReportQuestion;
