import { FC } from 'react';

import { Context } from 'index';
import Store from 'store/store';

export const ContextProvider: FC = ({ children }) => {
	return (
		<Context.Provider
			value={{
				store: new Store(),
			}}
		>
			{children}
		</Context.Provider>
	);
};
