import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'components/UI';

import { ArticleProps } from './types';

import styles from './Articles.module.scss';

const Article: FC<ArticleProps> = observer(({ item, isSelected = false, selectElement }) => {
	const navigate = useNavigate();

	const onLinkToUpdate = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		navigate(`${item.id}/update`);
	};

	const onChangeCheckbox = () => selectElement(item.id);

	return (
		<div className={styles.row}>
			<div className={styles.cell}>
				<Checkbox checked={isSelected} onChange={onChangeCheckbox} />
			</div>
			<div className={styles.cell}>{item.id}</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.name}</span>
			</div>
			<div className={styles.cell}>
				<span className='wrap-text limit-line-text'>{item.type}</span>
			</div>

			<div className={styles.cell} onClick={onLinkToUpdate}>
				<div className='edit-icon-block'>
					<span className='update-text'>Изменить</span>
				</div>
			</div>
		</div>
	);
});

export default Article;
