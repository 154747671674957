import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { FieldsType } from 'components/Logic/FieldByType/types';
import { CreatePage } from 'components/Layouts';

const ActivityCreate: FC = observer(() => {
	const store = useStore();

	const eventsFields: FieldsType[] = [
		{
			type: 'file',
			label: 'Картинка',
			name: 'image',
		},
		{
			type: 'textfield',
			label: 'Заголовок',
			maxLength: 60,
			name: 'title',
		},
		{
			type: 'textfield',
			name: 'text',
			label: 'Текст',
			maxLength: 100,
		},
		{
			type: 'textFieldWithPrefix',
			name: 'link',
			prefix: 'https://',
			label: 'Ссылка',
		},
	];

	return (
		<CreatePage title='Активности / Создание' fields={eventsFields} store={store.activities} />
	);
});

export default ActivityCreate;
