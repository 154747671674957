import makeRequest from '../makeRequest';

import { GetAppStatusResponse } from 'interfaces/api/AppResponse';

class AppSettingService {
	async fetchAppWorkingStatus() {
		return await makeRequest<GetAppStatusResponse>({
			url: 'get_status',
			auth: true,
		});
	}

	async changeWorkingStatus(isWorking: boolean) {
		return await makeRequest<GetAppStatusResponse>({
			url: 'set_status',
			method: 'post',
			data: {
				status: isWorking,
			},
			auth: true,
		});
	}
}

export default new AppSettingService();
