import { FC } from 'react';

import { ChildTableHeaderProps, TableHeader, THeaderField } from 'components/Layouts';

import styles from './CalendarEvents.module.scss';

const CalendarEventsTableHeader: FC<ChildTableHeaderProps> = ({
	isAllSelected,
	selectAllElements,
}) => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Событие', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Дата события', name: 'start_date', type: 'textField', hasSorting: true },
		{ label: 'Состояние', name: '', type: null },
		{ label: '', name: '', type: null },
	];

	return (
		<TableHeader
			className={styles.row}
			fields={tableFields}
			isAllSelected={isAllSelected}
			selectAllElements={selectAllElements}
		/>
	);
};

export default CalendarEventsTableHeader;
