/* eslint-disable */
import clsx from "clsx";
import { Button, Checkbox, TextField } from "components/UI";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useReducer, useState } from "react";
import styles from "../СalculatorBuilder.module.scss";
import { ActionType } from "../СalculatorBuilder";
import Question from "./Question";
import { useStore } from "hooks/useStore";
import { observer } from "mobx-react-lite";

// const initialState: any = { attributes: []};
function reducer(state: any, action: any): any {
	const { type, payload } = action;
	const {
		value,
		label,
		link,
		index,
		questionOptions,
		positionAnswer,
		operatorLabel,
		position,
		operator,
		name,
		element
	} = payload;

	switch (type) {
		case ActionType.ADD_QUESTION:
			return {
				...state,
				questions: [
					...state.questions,
					{
						label: label,
						name,
						operator,
						position: position,
						questionOptions,
						index: uuidv4(),
						answers: []
					}
				]
			};
		case ActionType.DELETE_QUESTION:
			return {
				...state,
				questions:
					state.questions && state.questions.filter((item: any) => item.position !== position)
			};

		// TEST!!!!
		// case ActionType.EDIT_QUESTION:
		// 	return {
		// 		...state,
		// 		questions: state.questions.map((question: any) => (
		// 			question.position === position
		// 				? {
		// 					...question,
		// 					label,
		// 					questionOptions,
		// 					position,
		// 					answers: [
		// 						{
		// 							value: "",
		// 							label: "",
		// 							positionAnswer: uuidv4(),
		// 						}
		// 					]
		// 				}
		// 				: question
		// 		))
		// 	};
		case ActionType.EDIT_QUESTION_OPERATOR:
			return {
				...state,
				questions: state.questions.map((question: any) =>
					question.position === position
						? {
							...question,
							operatorLabel
						}
						: question
				)
			};
		case ActionType.ADD_ANSWER:
			return {
				...state,
				questions: state.questions.map((question: any) =>
					question.position === position
						? {
							...question,
							answers: [
								...question?.answers,
								{
									value: "",
									label: label || "",
									positionAnswer: positionAnswer || uuidv4()
								}
							]
						}
						: question
				)
			};
		case ActionType.EDIT_ANSWER_FOR_QUESTION:
			return {
				...state,
				questions: state.questions.map((question: any) =>
					question.position === position
						? {
							...question,
							answers: question.answers.map((answer: any) =>
								answer.positionAnswer === positionAnswer
									? {
										...answer,
										label
									}
									: answer
							)
						}
						: question
				)
			};
		case ActionType.EDIT_ANSWER_FOR_QUESTION_OPERATOR:
			return {
				...state,
				questions: state.questions.map((question: any) =>
					question.position === position
						? {
							...question,
							answers: question.answers.map((answer: any) =>
								answer.positionAnswer === positionAnswer
									? {
										...answer,
										operatorLabel
									}
									: answer
							)
						}
						: question
				)
			};
		case ActionType.DELETE_ANSWER:
			return {
				...state,
				questions: state.questions.map((question: any) =>
					question.position === position
						? {
							...question,
							answers: question.answers.filter(
								(item: any) => item.positionAnswer !== positionAnswer
							)
						}
						: question
				)
			};
		case ActionType.EDIT_QUESTION:
			return {
				...state,
				questions: state.questions.map((question: any) =>
					question.index === index
						? {
							...question,
							position,
							label,
							questionOptions,
							element
						}
						: question
				)
			};
		case ActionType.RESET_ATTRIBUTE:
			return {
				...state,
				attributes: []
			};
		case ActionType.EDIT_TEXT_LABEL:
			return {
				...state,
				title: label
			};
		case ActionType.EDIT_TEXT_OPTION:
			return {
				...state,
				options: state.options.map((option: any) =>
					option.value === value
						? {
							...option,
							label
						}
						: option
				)
			};

		case ActionType.EDIT_LINK_OPTION:
			return {
				...state,
				options: state.options.map((option: any) =>
					option.value === value
						? {
							...option,
							link
						}
						: option
				)
			};
		case ActionType.ADD_OPTION:
			return {
				...state,
				options: [
					...state.options,
					{
						label: "Ответ",
						value: uuidv4()
					}
				]
			};
		case ActionType.DELETE_OPTION:
			return {
				...state,
				options: state.options && state.options.filter((item: any) => item.value !== value)
			};
		default:
			console.log("default case");
			return state;
	}
}

const EditCondition = observer(({ currentCondition, dispatch, setIsOpen, fields, id }: any) => {
	const [state, dispatchFields]: any = useReducer(reducer, { questions: [] });
	const store = useStore();
	useEffect(() => {
		currentCondition?.conditions?.map((condition: any) => {
			dispatchFields({
				type: ActionType.ADD_QUESTION,
				payload: {
					label: condition.name,
					id: condition.id,
					position: condition.position,
					index: uuidv4(),
					questionOptions: condition.questionOptions
				}
			});

			dispatchFields({
				type: ActionType.EDIT_QUESTION_OPERATOR,
				payload: {
					operatorLabel: condition.operator,
					position: condition.position
				}
			});

			condition?.answers.map((answer: any) => {
				dispatchFields({
					type: ActionType.ADD_ANSWER,
					payload: {
						label: answer.label,
						position: condition.position,
						positionAnswer: answer.positionAnswer
					}
				});
				dispatchFields({
					type: ActionType.EDIT_ANSWER_FOR_QUESTION_OPERATOR,
					payload: {
						operatorLabel: answer.operatorLabel,
						position: condition.position,
						positionAnswer: answer.positionAnswer
					}
				});
			});
		});
	}, []);
	const [isLink, setIsLink] = useState(currentCondition?.link ? !!currentCondition.link : false);
	const [title, setTitle] = useState<string>(currentCondition?.title || "");
	const [text, setText] = useState<string>(currentCondition?.text || "");
	const [name, setName] = useState<string>(currentCondition?.name || "");
	const [linkText, setLinkText] = useState<string>(currentCondition?.link || "");

	const textFieldClasses = clsx({
		[styles.filterField]: true
		// [styles.filterInactive]: column.name !== filterName && !isMustSelect,
		// [styles.filterSelectInactive]: column.name !== filterName && isMustSelect,
	});

	const conditions = state.questions.map((question: any) => {
		return {
			position: question.position,
			name: question.label,
			operator: question?.operatorLabel || "",
			answers: question.answers,
			questionOptions: question.questionOptions
		};
	});

	return (
		<div className={styles.editQuestion}>
			<div className={styles.editTitle}>Редактирование {currentCondition.name}</div>
			<div className={styles.questionCondition}>
				<div className={styles.bodyCondition}>
					<TextField
						className={textFieldClasses}
						label="Название"
						defaultValue={name}
						onChange={(e) => setName(e.target.value)}
					/>
					{state.questions.map((question: any, index: number) => {
						return (
							<Question
								fields={fields}
								question={question}
								dispatchField={dispatchFields}
								isOperator={index > 0}
							/>
						);
					})}
					<Button
						style={{ marginTop: "20px", marginBottom: "40px" }}
						onClick={() =>
							dispatchFields({
								type: ActionType.ADD_QUESTION,
								payload: {}
							})
						}
					>
						Добавить вопрос
					</Button>
				</div>
				<div className={styles.fieldSetting}>
					<TextField
						className={textFieldClasses}
						label={"Заголовок ответа в пути"}
						defaultValue={title}
						onChange={(e) => setTitle(e.target.value)}
						// onChange={(e) => {
						// 	dispatch({
						// 		type: ActionType.EDIT_TEXT_LABEL,
						// 		payload: {
						// 			label: e.target.value,
						// 		}
						// 	});
						// }}
					/>
				</div>
				<div className={styles.fieldSetting}>
					<div className={styles.fieldTitle}>Текст ответа</div>
					<TextField
						className={styles.textAreaSetting}
						defaultValue={text}
						onChange={(e) => setText(e.target.value)}
						// onChange={(e) => {setResponseTemplateText(e.target.value)}}
					/>
				</div>
				<div className={styles.isLink}>
					<Checkbox
						className={styles.answerChekbox}
						checked={isLink}
						onChange={() => setIsLink(!isLink)}
					/>
					Прикрепить ресурс
				</div>
				{isLink && (
					<TextField
						className={textFieldClasses}
						placeholder={"https://"}
						defaultValue={linkText}
						onChange={(e) => setLinkText(e.target.value)}
						// onChange={(e) => {
						// dispatch({
						// 	type: ActionType.EDIT_LINK_OPTION,
						// 	payload: {
						// 		label,
						// 		value,
						// 		link: e.target.value,
						// 	}
						// });
						// }}
					/>
				)}
			</div>
			<div className={styles.btnsEdit}>
				<Button
					onClick={() => {
						// setIsOpen(false)
						if (currentCondition.id) {
							store.questions.updateConditions(
								{
									conditions,
									text: text,
									title: title,
									name: name,
									link: linkText
								},
								id,
								currentCondition.id
							);
							dispatch({
								type: ActionType.SET_CONDITIONS,
								payload: {
									value: "",
									itemIndex: currentCondition.itemIndex,
									id: currentCondition.id,
									name: name
								}
							});
						} else {
							store.questions.postConditions(
								{
									conditions,
									text: text,
									title: title,
									name: name,
									link: linkText
								},
								id
							);
							dispatch({
								type: ActionType.DELETE_CONDITIONS,
								payload: {
									itemIndex: currentCondition.itemIndex
								}
							});
						}
						setIsOpen(false);
					}}
					theme="filled"
					disabled={
						state.questions[0]?.label === undefined || title === "" || text === "" || name === ""
					}
				>
					Сохранить
				</Button>
				<Button onClick={() => setIsOpen(false)} theme="outlined">
					Отменить
				</Button>
			</div>
		</div>
	);
});

export default EditCondition;
