import { ReportService } from 'API';
import { IReportCreate, IReportRead } from 'interfaces/entities/IReport';
import { ListStore } from 'store/general/List/lIstStore';

class ReportStore extends ListStore<IReportRead, IReportCreate> {
	constructor() {
		super({
			APIFetchItems: ReportService.fetchReports,
			APIFetchItem: ReportService.fetchReport,
			APIUpdateItem: ReportService.updateReport,
		});
	}
}

export default ReportStore;
