import makeRequest from 'API/makeRequest';
import { CheckTokenResponse, IAuthService, LoginResponse } from 'interfaces/api/IAuthService';

class AuthService implements IAuthService {
	async login(email: string, password: string) {
		const response = await makeRequest<LoginResponse>({
			url: 'auth/login',
			method: 'POST',
			data: { email, password },
		});

		if ('errors' in response) {
			return response;
		}

		return response.data;
	}

	async check() {
		const response = await makeRequest<CheckTokenResponse>({
			url: 'check_token',
			method: 'GET',
			auth: true,
		});

		return response;
	}

	logout() {
		makeRequest<void>({
			url: 'auth/logout',
			method: 'DELETE',
			auth: true,
		});
	}
}

export default new AuthService();
