import React, { ChangeEvent, FC, useRef, useState } from 'react';
import MaskedInput from 'react-input-mask';
import ReactDatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import clsx from 'clsx';

import { ReactComponent as IcCalendar } from 'assets/icons/form/ic_calendar.svg';
import { DatePickerProps } from './types';
import styles from './DatePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

const DatePicker: FC<DatePickerProps> = ({
	value,
	name,
	label,
	onChange,
	disabled,
	placeholder = '',
	className = '',
	wrapperClassName = '',
	defaultValue,
	...props
}) => {
	const refCalendarIcon = useRef(null);
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState(value);

	const windowWidth = window.innerWidth;
	const isNotMobile = windowWidth > 1000;

	const dateFormatting = (date: Date) => {
		const year = date.getFullYear();
		const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
		const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
		return `${day}.${month}.${year}`;
	};

	const openDatePicker = () => setOpen((open) => !open);

	const clickOutside = (e: any) => {
		const isTargetNotCalendar = !(e.target === refCalendarIcon.current);

		if (isTargetNotCalendar) {
			setOpen(false);
		}
	};

	const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);

		if (typeof onChange === 'function') {
			onChange(e.target.value);
		}
	};

	const selectDate = (date: Date) => {
		setInputValue(dateFormatting(date));
		setOpen(false);

		if (typeof onChange === 'function') {
			onChange(dateFormatting(date));
		}
	};

	const wrapperStyle = clsx(styles.wrapper, {
		[wrapperClassName]: !!wrapperClassName,
		[styles.disabled]: !!disabled,
	});

	const datePickerStyles = clsx({
		[styles.datePickerWrapper]: true,
		[styles.disabled]: disabled,
		[className]: className,
	});

	return (
		<div className={wrapperStyle}>
			{label && (
				<label htmlFor='date' className={styles.label}>
					{label}
				</label>
			)}

			<div className={datePickerStyles}>
				<MaskedInput
					mask='99.99.9999'
					id='date'
					name={name}
					type='text'
					value={inputValue}
					defaultValue={defaultValue}
					onChange={onChangeText}
					placeholder={placeholder}
					className={styles.textfield}
				/>

				{isNotMobile && (
					<>
						<IcCalendar className='calendar' onClick={openDatePicker} ref={refCalendarIcon} />
						<ReactDatePicker
							open={open}
							onClickOutside={clickOutside}
							showYearDropdown
							dateFormatCalendar='MMMM'
							yearDropdownItemNumber={150}
							scrollableYearDropdown
							locale={ru}
							className='datePicker'
							onChange={selectDate}
							popperPlacement='auto-start'
							popperModifiers={[
								{
									name: 'preventOverflow',
									options: {
										mainAxis: false,
										altAxis: false,
									},
								},
							]}
							minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 50))}
							{...props}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default DatePicker;
