import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import ProfessionalQualitiesGroup from './ProfessionalQuality';
import ProfessionalQualitiesTableHeader from './ProfessionalQualitiesTableHeader';

import styles from './ProfessionalQualities.module.scss';

const ProfessionalQualities: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.professionalQualities}
			title='Профессиональные качества'
			tableHeader={ProfessionalQualitiesTableHeader}
			tableClassName={styles.table}
			element={ProfessionalQualitiesGroup}
			hasDelete={false}
		/>
	);
});

export default ProfessionalQualities;
