import { createElement, FC } from 'react';

import {
	Checkbox,
	DatePicker,
	Editor,
	Select,
	Switch,
	TextArea,
	TextField,
	TextFieldWithCrumbs,
	TextFieldWithPrefix,
	Wysiwyg,
} from 'components/UI';
import { File, MultiSelect, PhoneField, TimeField } from 'components/Form';

import { FieldByTypeProps } from './types';

const FieldByType: FC<FieldByTypeProps> = ({ className, field, value, onChange, onDelete }) => {
	const { type, ...fieldProperties } = field;

	const types = {
		textfield: TextField,
		phonefield: PhoneField,
		textarea: TextArea,
		checkbox: Checkbox,
		switch: Switch,
		select: Select,
		file: File,
		document: File,
		datePicker: DatePicker,
		textFieldWithCrumbs: TextFieldWithCrumbs,
		timeField: TimeField,
		multiSelect: MultiSelect,
		wysiwyg: Wysiwyg,
		editor: Editor,
		textFieldWithPrefix: TextFieldWithPrefix,
	};

	const element = types[type];

	if (element === undefined) return null;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return createElement(element, {
		...fieldProperties,
		className,
		checked: value,
		value,
		onChange,
		onDelete,
	});
};

export default FieldByType;
