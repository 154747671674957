import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Draggable from 'react-draggable';
import clsx from 'clsx';

import { useStore } from 'hooks/useStore';
import MapLayer from '../MapLayer/MapLayer';
import Mark from '../Mark/Mark';

import { bounds, defaultPosition, headerConfig } from './config';

import { TMarkCoords } from '../Mark/types';
import { IMapProps } from './types';

import { ReactComponent as IcPlus } from 'assets/icons/map/ic_plus.svg';
import { ReactComponent as IcMinus } from 'assets/icons/map/ic_minus.svg';
import ImgMap from 'assets/image/map.jpg';
import styles from './Map.module.scss';

const Map = observer(({ label, currentMark, setCurrentMark }: IMapProps) => {
	const pavilionsStore = useStore().pavilionsOstrova;

	const { id } = useParams();

	const [scale, setScale] = useState(0.9);

	const onPlusScale = () => {
		if (scale < 1.5) setScale((prev) => +(prev + 0.1).toFixed(1));
	};

	const onMinusScale = () => {
		if (scale > 0.5) setScale((prev) => +(prev - 0.1).toFixed(1));
	};

	console.log(pavilionsStore.items
		);

	const pavilionsCoords: TMarkCoords[] = pavilionsStore.items
		.filter((item) => item.id !== +id!)
		.map((pavilion) => ({
			x: +pavilion.x,
			y: +pavilion.y,
		}));

	const imageStyles = {
		transform: `scale(${scale})`,
	};

	const getButtonClasses = (type: 'plus' | 'minus') =>
		clsx({
			[styles.disabled]: (type === 'plus' && scale >= 1.5) || (type === 'minus' && scale <= 0.5),
		});

	return (
		<section className={styles.wrapper}>
			{label && <span className={styles.label}>{label}</span>}

			<div className={styles.mapWrapper}>
				<ul className={styles.header}>
					{headerConfig.map((item) => (
						<li className={styles.item} key={item.title}>
							<Mark variant={item.type} coords={null} />
							<span className={styles.title}>{item.title}</span>
						</li>
					))}
				</ul>

				<div className={styles.map}>
					<Draggable bounds={bounds(scale)} defaultPosition={defaultPosition} handle='.drag-handle'>
						<div className={clsx(styles.mapDraggable, 'drag-handle')}>
							<div style={imageStyles}>
								<img className={styles.image} src={ImgMap} alt='map' />
								<MapLayer
									currentMark={currentMark}
									setCurrentMark={setCurrentMark}
									pavilionsCoords={pavilionsCoords}
								/>
							</div>
						</div>
					</Draggable>
				</div>

				<div className={styles.resizeButtons}>
					<button onClick={onPlusScale} className={getButtonClasses('plus')} type='button'>
						<IcPlus />
					</button>
					<button onClick={onMinusScale} className={getButtonClasses('minus')} type='button'>
						<IcMinus />
					</button>
				</div>
			</div>
		</section>
	);
});

export default Map;
