import { AdditionalApplicationService } from 'API';
import { IAdditionalApplicationRead } from 'interfaces/entities/IAdditionalApplication';
import { ListStore } from 'store/general/List/lIstStore';

class AdditionalApplicationStore extends ListStore<IAdditionalApplicationRead, void> {
	constructor() {
		super({
			APIFetchItems: AdditionalApplicationService.fetchAdditionalApplications,
			APIFetchItem: AdditionalApplicationService.fetchAdditionalApplication,
		});
	}
}

export default AdditionalApplicationStore;
