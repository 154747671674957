import { ISphereRead } from 'interfaces/entities/ISphere';

export interface SphereProps {
	item: ISphereRead;
	isSelected: boolean;
	selectElement: (id: number) => void;
}

export const locationOptions = [
	{
		label: 'Вакансии инвест проектов, Компании, Курсы',
		value: 'Вакансии инвест проектов, Компании, Курсы',
	},
	{
		label: 'Острова Развития',
		value: 'Острова Развития',
	},
	{
		label: 'Школьник',
		value: 'Школьник',
	},
];
