import makeRequest from '../makeRequest';
import {GetCareerGuidanceService} from 'interfaces/api/CareerGuidanceResponse';

class CareerGuidanceService {
	async fetchCareerGuidances(query: string) {
		const response = await makeRequest<GetCareerGuidanceService>({
			url: `career_guidance?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.career_guidance,
			meta: response.data.meta,
		};
	}
}

export default new CareerGuidanceService();
