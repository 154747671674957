import { useEffect, useState } from 'react';

import { useStore } from 'hooks/useStore';
import { Button, Loader, Prompt } from 'components/UI';
import { Popup } from 'components/Logic';
import FriendWorkLink from './FriendWorkLink';

import { EFriendWorkStatuses, IFriendWorkSyncProps } from './types';

import { ReactComponent as IcSync } from 'assets/icons/general/ic_sync.svg';
import styles from './FriendWork.module.scss';

const FriendWorkSync = ({
	userId,
	friendWorkLink,
	syncBtnDisabled,
	isForList,
}: IFriendWorkSyncProps) => {
	const store = useStore();

	const [isLoading, setIsLoading] = useState(false);
	const [friendWorkData, setFriendWorkData] = useState({
		modalIsOpen: false,
		content:
			'Вы собираетесь синхронизировать данные пользователя. Это обновит существующую информацию или создаст новую анкету в FriendWork. Хотите продолжить?',
		title: 'Синхронизировать анкету с FriendWork',
		status: EFriendWorkStatuses.default,
	});

	const setPopupOpen = (isOpen: boolean) =>
		setFriendWorkData((prev) => {
			return { ...prev, modalIsOpen: isOpen };
		});

	const syncFriendWork = async () => {
		setIsLoading(true);

		const response = await store.users.fetchFriendWork(userId);

		if ('url' in response) {
			store.users.setFriendWorkUrl(response.url);
			setFriendWorkData((prev) => {
				return {
					...prev,
					status: EFriendWorkStatuses.success,
					title: 'Успешная синхронизация',
					content: 'Синхронизация с FriendWork успешно завершена. Информация обновлена',
				};
			});
		} else if ('errors' in response) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setFriendWorkData((prev) => {
				return {
					...prev,
					title: 'Неудачная синхронизация',
					content: response.errors.message,
					status: EFriendWorkStatuses.error,
				};
			});
		}

		setIsLoading(false);
	};

	const getBtnByStatus = (status: EFriendWorkStatuses) => {
		if (status === EFriendWorkStatuses.default) {
			return (
				<Button theme='filled' onClick={syncFriendWork}>
					Синхронизировать
				</Button>
			);
		} else if (status === EFriendWorkStatuses.success && friendWorkLink) {
			return <FriendWorkLink link={friendWorkLink} />;
		} else if (status === EFriendWorkStatuses.error) {
			return (
				<Button theme='filled' onClick={syncFriendWork}>
					Повторить
				</Button>
			);
		}
	};

	useEffect(() => {
		return () => {
			setFriendWorkData({
				modalIsOpen: false,
				title: 'Синхронизировать анкету с FriendWork',
				content:
					'Вы собираетесь синхронизировать данные пользователя. Это обновит существующую информацию или создаст новую анкету в FriendWork. Хотите продолжить?',
				status: EFriendWorkStatuses.default,
			});
		};
	}, []);

	return (
		<>
			{isForList ? (
				<IcSync
					onClick={() => !syncBtnDisabled && setPopupOpen(true)}
					className={syncBtnDisabled ? styles.hidden : styles.icSync}
				/>
			) : (
				<div className={styles.friendWork}>
					<Prompt title='Только для трудящихся с анкетой' />
					<Button onClick={() => !syncBtnDisabled && setPopupOpen(true)} disabled={syncBtnDisabled}>
						Синхронизировать с FriendWork
					</Button>
				</div>
			)}

			<Popup
				isOpen={friendWorkData.modalIsOpen}
				onClose={() => setPopupOpen(false)}
				className={styles.modal}
			>
				<div className={styles.container}>
					<span className={styles.title}>{friendWorkData.title}</span>

					<p className={styles.subtitle}>{friendWorkData.content}</p>

					<div className={styles.modalButtons}>
						{isLoading && <Loader />}
						<Button theme='outlined' onClick={() => setPopupOpen(false)}>
							{friendWorkData.status === 'default' ? 'Отмена' : 'Закрыть'}
						</Button>
						{getBtnByStatus(friendWorkData.status)}
					</div>
				</div>
			</Popup>
		</>
	);
};

export default FriendWorkSync;
