import { FC } from 'react';

import { ChildTableHeaderProps, TableHeader, THeaderField } from 'components/Layouts';

import styles from './PracticeStudents.module.scss';

const ResumesTableHeader: FC<ChildTableHeaderProps> = ({ isAllSelected, selectAllElements }) => {
	const tableFields: THeaderField[] = [
		{ label: 'Специальность', name: 'speciality_name', type: 'asyncSelect', endPoint: 'specialities' },
		{ label: 'Учебное заведение', name: 'institution_name', type: 'textField' },
		{ label: 'Дата начала', name: 'start_date', type: 'textField', hasSorting: true },
		{ label: 'Дата конца', name: 'finish_date', type: 'textField', hasSorting: true },
		{ label: 'Вид практики', name: 'practice_type', type: 'textField' },
		{ label: '', name: '', type: null },
	];

	return (
		<TableHeader
			className={styles.row}
			fields={tableFields}
			isAllSelected={isAllSelected}
			selectAllElements={selectAllElements}
		/>
	);
};

export default ResumesTableHeader;
