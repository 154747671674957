// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import List from '@editorjs/list';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';

import { TEditorTool, TEditorToolsConfig } from './types';

export const EDITOR_TOOLS: TEditorToolsConfig = {
	header: {
		class: Header,
		config: {
			levels: [3],
			defaultLevel: 3,
		},
		inlineToolbar: ['italic', 'link'],
	},
	list: {
		class: List,
		inlineToolbar: true,
	},
	quote: {
		class: Quote,
		inlineToolbar: true,
		config: {
			quotePlaceholder: 'Введите цитату',
			captionPlaceholder: 'Автор цитаты',
		},
	},
};

export const EditorLocalization = {
	messages: {
		blockTunes: {
			delete: {
				Delete: 'Удалить',
				'Click to delete': 'Подтвердить',
			},
			moveUp: { 'Move up': 'Поднять' },
			moveDown: { 'Move down': 'Опустить' },
		},
		toolNames: {
			Text: 'Текст',
			Heading: 'Заголовок',
			List: 'Список',
			Warning: 'Примечание',
			Checklist: 'Чеклист',
			Quote: 'Цитата',
			Code: 'Код',
			Delimiter: 'Разделитель',
			'Raw HTML': 'HTML-фрагмент',
			Table: 'Таблица',
			Link: 'Ссылка',
			Marker: 'Маркер',
			Bold: 'Полужирный',
			Italic: 'Курсив',
			InlineCode: 'Моноширинный',
			Image: 'Картинка',

			Bold: 'Полужирный',
			Italic: 'Курсив',
			Link: 'Ссылка',
		},
		tools: {
			header: {
				'Heading 3': 'Заголовок',
			},
			list: {
				Ordered: 'Нумерованный',
				Unordered: 'Маркированный',
			},
			image: {
				'Select an Image': 'Выберите файл',
				'With border': 'Недоступно',
				'Stretch image': 'Недоступно',
				'With background': 'Недоступно',
				Caption: 'Подпись',
				'Couldn’t upload image. Please try another.':
					'Не получается загрузить изображение. Пожалуйста, попробуйте другое.',
			},
			paragraph: {
				'Enter something': 'Введите текст',
			},
			quote: {
				'Align Left': 'Недоступно',
				'Align Center': 'Недоступно',
			},
		},
		ui: {
			blockTunes: {
				toggler: {
					'Click to tune': 'Кликните для настройки',
					'or drag to move': 'или перетащите для перемещения',
				},
			},
			inlineToolbar: {
				converter: {
					'Convert to': 'Конвертировать в',
				},
			},
			toolbar: {
				toolbox: {
					Add: 'Добавить',
				},
			},
			popover: {
				Filter: 'Поиск',
				'Nothing found': 'Ничего не найдено',
			},
		},
	},
};

export const getCurrentTools = (allTools: TEditorToolsConfig, currentToolsTypes: TEditorTool[]) => {
	const currentTools: TEditorToolsConfig = {};

	currentToolsTypes.forEach((toolType) => (currentTools[toolType] = allTools[toolType]));

	return currentTools;
};

//   // embed: Embed,
//   // table: Table,
//   // warning: Warning,
//   // code: Code,
//   // linkTool: LinkTool,
//   // raw: Raw,
//   // marker: Marker,
//   // checklist: CheckList,
//   // delimiter: Delimiter,
//   // inlineCode: InlineCode,
//   // simpleImage: SimpleImage,
