import makeRequest from 'API/makeRequest';
import { GetSpheresResponse, GetSphereResponse } from 'interfaces/api/SphereResponse';
import { ISphereCreate } from 'interfaces/entities/ISphere';

class SphereService {
	async fetchSpheres(query: string) {
		const response = await makeRequest<GetSpheresResponse>({
			url: `spheres?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.spheres,
			meta: response.data.meta,
		};
	}

	async fetchSphere(id: string) {
		const response = await makeRequest<GetSphereResponse>({
			url: `spheres/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.sphere,
		};
	}

	async createSphere(sphere: ISphereCreate) {
		const response = await makeRequest<GetSphereResponse>({
			url: 'spheres',
			method: 'post',
			auth: true,
			data: sphere,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.sphere,
		};
	}

	async updateSphere(sphere: FormData | (ISphereCreate & { id: number })) {
		const id = 'id' in sphere ? sphere.id : sphere.get('id');
		const response = await makeRequest<GetSphereResponse>({
			url: `spheres/${id}`,
			method: 'post',
			auth: true,
			data: sphere,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.sphere,
		};
	}

	deleteSphere(id: number) {
		return makeRequest<void>({
			url: `spheres/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new SphereService();
