/* eslint-disable */
import { useStore } from "hooks/useStore";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IcDelete } from "assets/icons/general/ic_trash.svg";

import styles from "./СalculatorBuilder.module.scss";
import { PageHeader } from "components/Layouts";
import { Button, Select, TextField } from "components/UI";
import { Export, Popup } from "components/Logic";
import { IOption } from "components/UI/Select/types";

export const СalculatorBuilderList = observer(({ field }: any) => {
	const store = useStore();
	const [isOpen, setIsOpen] = useState(false);
	const [name, setName] = useState("");
	const [place, setPlace] = useState("");
	const [placeValue, seetPlaceValue] = useState("");

	const exportList = [
		{
			path: "calculator_results",
			label: "Конструктор калькулятора",
			fileWord: "Ответы_калькулятора"
		}
	];

	const optionsCalculator = [
		{
			label: "Переезд на острова",
			value: "movingToIslands"
		},
		{
			label: "Студент / Трудящийся — Дом",
			value: "profile/house"
		},
		{
			label: "Студент / Трудящийся — Карьера",
			value: "profile/career"
		},
		{
			label: "Студент / Трудящийся — Семья",
			value: "profile/family"
		},
		{
			label: "Школьник — Активности",
			value: "profile/activity"
		},
		{
			label: "Школьник — Возможности",
			value: "profile/possibilities"
		},
		{
			label: "Школьник — Успех",
			value: "profile/success"
		}
	];

	useEffect(() => {
		store.questions.fetchPagesList();
	}, []);

	const navigate = useNavigate();
	const onLinkToUpdate = (id: number) => {
		navigate(`${id}/update`);
	};

	return (
		<div className={styles.formBuilder}>
			<PageHeader title={"Конструктор калькулятора"} />

			<div className={styles.buttons}>
				<Button className={styles.button} onClick={() => setIsOpen(true)} theme="filled">
					Добавить калькулятор
				</Button>

				<Export exportList={exportList} query={""} theme="dark" />
			</div>
			<Popup isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<div className={styles.wrapperPopap}>
					<div className={styles.headerPopap}>
						Добавить калькулятор
						<span>Выберите название и место где хотите видеть тест</span>
					</div>
					<div className={styles.formGroupPopap}>
						<div>
							<TextField
								name="link"
								label="Название"
								// placeholder="https://"
								onChange={(e: any) => setName(e.target.value)}
								value={name}
							/>
						</div>
						<div>
							<Select
								label="Расположение"
								options={optionsCalculator}
								onChange={(option: IOption | null) => {
									setPlace(option?.label as string);
									seetPlaceValue(option?.value as string);
								}}
								placeholder="Выберите место"
								className={styles.select}
								value={{ label: place || "Выберите место", value: place || "Выберите место" }}
							/>
						</div>
					</div>
					<div className={styles.btnsPopap}>
						<Button
							type="submit"
							theme="filled"
							onClick={() => {
								store.questions.createPage({
									name,
									place
								});
								setIsOpen(false);
							}}
						>
							Сохранить
						</Button>
						<Button
							onClick={() => setIsOpen(false)}
							className={styles.closeBtn}
							type="submit"
							theme="filled"
						>
							Отмена
						</Button>
					</div>
				</div>
			</Popup>
			<div className={styles.formList}>
				{toJS(store.questions.calculators).map(({ name, place, id, updated_at }: any) => {
					return (
						<li className={styles.formCard}>
							<div className={styles.formHeader}>
								<h4 className={styles.formTitle}>{name}</h4>
								<span className={styles.place}>{place}</span>
							</div>

							{updated_at && <p className={styles.description}>Изменено {updated_at}</p>}

							<div className={styles.formButtons}>
								<Button
									theme="filled"
									onClick={() => onLinkToUpdate(id)}
									size="small"
									className={styles.edit}
								>
									Редактировать
								</Button>
								<Button
									theme="danger"
									onClick={() => store.questions.deletePage(id)}
									size="small"
									className={styles.delete}
								>
									<IcDelete />
								</Button>
							</div>
						</li>
					);
				})}
			</div>
		</div>
	);
});

export default СalculatorBuilderList;
