import makeRequest from 'API/makeRequest';
import {
	GetProfessionalQualitiesResponse,
	GetProfessionalQualityResponse,
} from 'interfaces/api/ProfessionalQualityResponse';
import { IProfessionalQualityCreate } from 'interfaces/entities/IProfessionalQuality';

class ProfessionalQualityService {
	async fetchProfessionalQualities(query: string) {
		const response = await makeRequest<GetProfessionalQualitiesResponse>({
			url: `professional_qualities?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.professional_qualities,
			meta: response.data.meta,
		};
	}

	async fetchProfessionalQuality(id: string) {
		const response = await makeRequest<GetProfessionalQualityResponse>({
			url: `professional_qualities/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.professional_quality,
		};
	}

	async createProfessionalQuality(professional_quality: IProfessionalQualityCreate) {
		const response = await makeRequest<GetProfessionalQualityResponse>({
			url: 'professional_qualities',
			method: 'post',
			auth: true,
			data: professional_quality,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.professional_quality,
		};
	}

	async updateProfessionalQuality(
		professional_quality: IProfessionalQualityCreate & { id: number },
	) {
		const response = await makeRequest<GetProfessionalQualityResponse>({
			url: `professional_qualities/${professional_quality.id}`,
			method: 'put',
			auth: true,
			data: professional_quality,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.professional_quality,
		};
	}

	deleteProfessionalQuality(id: number) {
		return makeRequest<void>({
			url: `professional_qualities/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new ProfessionalQualityService();
