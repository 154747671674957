import { SphereService } from 'API';
import { ISphereRead, ISphereCreate } from 'interfaces/entities/ISphere';
import { ListStore } from 'store/general/List/lIstStore';

class SphereStore extends ListStore<ISphereRead, ISphereCreate> {
	constructor() {
		super({
			APIFetchItems: SphereService.fetchSpheres,
			APIFetchItem: SphereService.fetchSphere,
			APICreateItem: SphereService.createSphere,
			APIUpdateItem: SphereService.updateSphere,
			APIRemoveItem: SphereService.deleteSphere,
		});
	}
}

export default SphereStore;
