import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser';

import { useStore } from 'hooks/useStore';
import { ApprovePage } from 'components/Layouts';
import { LinkBtn } from 'components/UI';

import { staticLinks } from 'config/routingLinks';

const VacancyUpdate: FC = observer(() => {
	const store = useStore();
	const [isLoading, setLoading] = useState(true);
	const { id } = useParams();
	const item = store.vacancies.element;

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			if (id !== undefined) {
				await store.vacancies.fetchItem(id);
			}
			setLoading(false);
		};
		fetchData();
	}, [id]);

	const onChangeApproved = (value: boolean | null) => {
		store.vacancies.updateItem(item.id.toString(), value);
	};

	const mainInfo = [
		{ title: 'ID вакансии', text: item.id },
		{ title: 'Дата создания', text: item.created_at },
		{ title: 'Дата обновления', text: item.updated_at },
		{ title: 'Название вакансии', text: item.name },
		{ title: 'Минимальная зарплата', text: item.min_salary },
		{ title: 'Максимальная зарплата', text: item.max_salary },
		{ title: 'Сфера', text: item.sphere?.name },
		{ title: 'Специальность', text: item.speciality?.name },
		{ title: 'Город', text: item.city },
		{ title: 'График работы', text: item.work_schedule_type },
		{ title: 'Опыт работы', text: item.work_experience_type },
		{ title: 'Образование', text: item.education_type },
		{ title: 'Занятость', text: item.employment_type },
		{ title: 'Дата начала проекта', text: item.investment_start_date },
	];

	const secondaryInfo = [
		{
			header: 'Компания',
			blocks: [
				[
					{
						title: 'ID',
						text: item.employer_profile?.id,
					},
					{
						title: 'Название',
						text: item.employer_profile?.employer_name,
					},
				],
			],
		},
		{
			header: 'Подробности вакансии',
			blocks: [
				[
					{
						title: 'Обязанности',
						text: parse(item.responsibilities ?? '') as string,
					},
					{
						title: 'Обязанности',
						text: parse(item.responsibilities ?? '') as string,
					},
					{
						title: 'Требования',
						text: parse(item.requirements ?? '') as string,
					},
					{
						title: 'Условия',
						text: parse(item.conditions ?? '') as string,
					},
				],
			],
		},
	];

	const additionalButtons = (
		<LinkBtn
			to={staticLinks.employers + '/' + item.employer_profile?.id + '/update'}
			theme='outlined'
			rel='noreferrer'
			target='_blank'
		>
			Профиль предприятия
		</LinkBtn>
	);

	return (
		<ApprovePage
			isLoading={isLoading}
			title={'Вакансии / Одобрение'}
			mainInfo={mainInfo}
			secondaryInfo={secondaryInfo}
			onUpdate={onChangeApproved}
			currentValue={item.is_approved}
			additionalButtons={additionalButtons}
		/>
	);
});

export default VacancyUpdate;
