import { computed, makeObservable, observable, toJS, action } from 'mobx';

import { ResumeService } from 'API';
import { PaginationStore } from 'store/general/Pagination/paginationStore';
import { IResumeHistoryItem, IResumeItem, IResumeView } from 'interfaces/entities/IResume';
import { IResumeStore } from './types';

class ResumeStore extends PaginationStore implements IResumeStore {
	isLoading: boolean;
	items: IResumeItem[];
	item = {} as IResumeView;
	historyItem: IResumeHistoryItem | null = null;

	constructor() {
		super();
		this.isLoading = false;
		this.items = [];

		makeObservable(this, {
			isLoading: observable,
			item: observable,
			historyItem: observable,
			items: observable,
			list: computed,
			isEmpty: computed,
			setItem: action,
			setLoading: action,
			clearItems: action,
		});
	}

	// GET
	get element() {
		return toJS(this.item);
	}

	get list() {
		return toJS(this.items);
	}

	get isEmpty() {
		return this.items.length === 0;
	}

	// SET
	setElement(item: IResumeView) {
		this.item = item;
	}

	setHistoryItem(item: IResumeHistoryItem | null) {
		this.historyItem = item;
	}

	setItem(item: IResumeItem) {
		this.items.push(item);
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	changeItem(id: number, newItem: IResumeItem) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as IResumeItem & { id: number };

			return oldItem.id === id;
		});

		this.items[index] = newItem;
	}

	clearItems() {
		this.items = [];
	}

	deleteItem(id: number) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as IResumeItem;

			return oldItem.id === id;
		});

		this.items.splice(index, 1);
	}

	// ASYNC
	async fetchItems(query: string) {
		this.setLoading(true);
		this.clearItems();
		this.setCurrentPage(1);
		this.setLastPage(1);

		const response = await ResumeService.fetchResumes(query);

		if ('errors' in response) {
			this.setLoading(false);
			return;
		}

		if ('meta' in response && response.meta !== undefined) {
			this.setCurrentPage(response.meta.current_page ?? 1);
			this.setLastPage(response.meta.last_page ?? 1);
		}

		response.items?.forEach((item: IResumeItem) => this.setItem(item));

		this.setLoading(false);
	}

	async fetchItem(id: string) {
		const response = await ResumeService.fetchResume(id);

		if ('item' in response) {
			this.setElement(response.item);
		}

		this.setLoading(false);
	}

	async updateItem(id: string, isApproved: boolean | null) {
		const response = await ResumeService.updateResume(id, isApproved);

		if ('errors' in response) {
			throw response;
		}

		if ('item' in response) {
			this.setElement(response.item);
		}
	}

	async fetchItemHistory(id: string) {
		this.setLoading(true);
		this.setHistoryItem(null);

		const response = await ResumeService.fetchResumeHistory(id);

		if ('errors' in response) {
			throw response;
		}

		if ('data' in response) {
			this.setHistoryItem(response.data.resume_history);
		}

		this.setLoading(false);
	}

	async downloadList() {
		return await ResumeService.downloadList();
	}
}

export default ResumeStore;
