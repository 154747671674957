import { FC, createElement } from 'react';
import { observer } from 'mobx-react-lite';

import { Loader } from 'components/UI';

import { ListProps } from './types';

import styles from './ListPage.module.scss';

const List: FC<ListProps> = observer(({ store = {}, element, selectedElements, selectElement }) => {
	const { list, isLoading, isEmpty } = store;

	if (isLoading) {
		return (
			<div className={styles.loaderContainer}>
				<Loader />
			</div>
		);
	}

	if (isEmpty) {
		return (
			<div className={styles.emptyContainer}>
				<h5>Нет информации</h5>
				<span>Мы не смогли найти элементы по такому запросу</span>
			</div>
		);
	}

	return (
		<>
			{list?.map((item: any) => {
				return createElement(element as React.ComponentType<any>, {
					key: item.id,
					store: store,
					item: item,
					isSelected: selectedElements?.includes(item.id),
					selectElement,
				});
			})}
		</>
	);
});

export default List;
