import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ReadPage } from 'components/Layouts';
import { Button, LinkBtn } from 'components/UI';

import { FieldsType } from 'components/Logic/FieldByType/types';

import { staticLinks } from 'config/routingLinks';

const SchoolUserRead: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.users.element;

	const fetchData = useCallback(async () => {
		await store.users.fetchItem(id);
	}, [id]);

	const authAsUser = async () => {
		const response = await store.users.createUserToken(item.id);

		if ('token' in response) {
			window.open(
				`${process.env.REACT_APP_FRONTEND_URL}?admin_auth_token=${response.token}`,
				'_blank',
			);
		}
	};

	const profile = item?.applicant_profile;
	const fullName = [profile?.last_name, profile?.first_name, profile?.patronymic_name]
		.filter(Boolean)
		.join(' ');

	const applicantFields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'full_name',
			value: fullName,
			label: 'ФИО',
		},
		{
			type: 'textfield',
			name: 'city',
			value: item.applicant_profile?.city,
			label: 'Город',
		},
		{
			type: 'textfield',
			name: 'sex',
			value: item.applicant_profile?.sex,
			label: 'Пол',
		},
		{
			type: 'textfield',
			name: 'birthday',
			value: item.applicant_profile?.birthday,
			label: 'Дата рождения',
		},
		{
			type: 'textfield',
			name: 'citizenship',
			value: item.applicant_profile?.citizenship ?? 'Отсутствует',
			label: 'Гражданство',
		},
		{
			type: 'textfield',
			name: 'situation',
			value: item.applicant_profile?.situation ?? 'Отсутствует',
			label: 'Положение',
		},
		{
			type: 'textfield',
			name: 'resume_id',
			value: item.applicant_profile?.resume_id?.toString(),
			label: 'ID резюме',
		},
		{
			type: 'textfield',
			name: 'name_of_the_legal_representative',
			value: item.applicant_profile?.name_of_the_legal_representative,
			label: 'ФИО законного представителя ',
		},
		{
			type: 'textfield',
			name: 'phone_of_the_legal_representative',
			value: item.applicant_profile?.phone_of_the_legal_representative,
			label: 'Телефон законного представителя',
		},
	];

	const resumeLink = staticLinks.resumes + '/' + item.applicant_profile?.resume_id + '/update';

	const additionalButtons = (
		<>
			{item.applicant_profile?.resume_id && (
				<LinkBtn to={resumeLink} theme='outlined' rel='noreferrer' target='_blank'>
					Анкета пользователя
				</LinkBtn>
			)}
			<Button theme='outlined' onClick={authAsUser}>
				Авторизация за пользователя
			</Button>
		</>
	);

	if (store.users.fetchItem === undefined) return null;

	return (
		<ReadPage
			title='Пользователи / Просмотр'
			fields={applicantFields}
			fetchData={fetchData}
			additionalButtons={additionalButtons}
		/>
	);
});

export default SchoolUserRead;
