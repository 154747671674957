import { FC } from 'react';
import clsx from 'clsx';

import { CheckboxProps } from './types';

import styles from './CheckFields.module.scss';

const Checkbox: FC<CheckboxProps> = ({ label, className = '', type = 'checkbox', ...props }) => {
	const checkboxStyle = clsx(styles.checkbox, {
		[className]: className,
	});

	return (
		<label className={checkboxStyle} onClick={(event) => event.stopPropagation()}>
			<input type={type} {...props} />

			<span>{label}</span>
		</label>
	);
};

export default Checkbox;
