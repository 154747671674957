import { FC, useState } from 'react';

import { Select } from 'components/UI';

import { IOption } from 'components/UI/Select/types';
import { TMultiSelectProps } from './types';

import { ReactComponent as IcClose } from 'assets/icons/general/ic_close.svg';
import styles from './MultiSelect.module.scss';

const MultiSelect: FC<TMultiSelectProps> = ({ value, options, onChange, ...props }) => {
	const [selectedItems, setSelectedItems] = useState<string[]>(value);
	const [remainingItems, setRemainingItems] = useState(
		options.filter((option) => !value?.includes(option.value)),
	);

	const onSelectItem = (item: IOption | null) => {
		if (typeof item?.value !== 'string') return;

		const newItems = [item.value, ...selectedItems];

		setRemainingItems((prev) => prev.filter((option) => option.value !== item.value));
		setSelectedItems(newItems);
		onChange(newItems);
	};

	const onDeleteItem = (currentValue: string, currentIndex: number) => {
		const newItems = selectedItems.filter(
			(item, index) => !(item === currentValue && index === currentIndex),
		);

		if (newItems.length !== selectedItems.length) {
			const deletedItem = options.find((option) => option.value === currentValue);

			deletedItem && setRemainingItems((prev) => [...prev, deletedItem]);
			setSelectedItems(newItems);
			onChange(newItems);
		}
	};

	return (
		<div className={styles.wrapper}>
			<Select onChange={onSelectItem} options={remainingItems} {...props} />

			{selectedItems?.length !== 0 && (
				<ul className={styles.list}>
					{selectedItems?.map((item, index) => (
						<li key={index} className={styles.item}>
							<span>{item}</span>
							<button type='button' onClick={() => onDeleteItem(item, index)}>
								<IcClose />
							</button>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default MultiSelect;
