import { computed, makeObservable, observable, toJS, action } from 'mobx';

import { EmployerService } from 'API';
import { PaginationStore } from 'store/general/Pagination/paginationStore';
import { IEmployerItem, IEmployerView } from 'interfaces/entities/IEmployer';
import { IEmployerStore } from './types';

class EmployerStore extends PaginationStore implements IEmployerStore {
	isLoading: boolean;
	items: IEmployerItem[];
	item = {} as IEmployerView;

	constructor() {
		super();
		this.isLoading = false;
		this.items = [];

		makeObservable(this, {
			isLoading: observable,
			item: observable,
			items: observable,
			list: computed,
			isEmpty: computed,
			setItem: action,
			setLoading: action,
			clearItems: action,
		});
	}

	// GET
	get element() {
		return toJS(this.item);
	}

	get list() {
		return toJS(this.items);
	}

	get isEmpty() {
		return this.items.length === 0;
	}

	// SET
	setElement(item: IEmployerView) {
		this.item = item;
	}

	setItem(item: IEmployerItem) {
		this.items.push(item);
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	changeItem(id: number, newItem: IEmployerItem) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as IEmployerItem & { id: number };

			return oldItem.id === id;
		});

		this.items[index] = newItem;
	}

	clearItems() {
		this.items = [];
	}

	deleteItem(id: number) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as IEmployerItem;

			return oldItem.id === id;
		});

		this.items.splice(index, 1);
	}

	// ASYNC
	// FETCH ITEMS
	async fetchItems(query: string) {
		this.setLoading(true);
		this.clearItems();
		this.setCurrentPage(1);
		this.setLastPage(1);

		const response = await EmployerService.fetchEmployers(query);

		if ('errors' in response) {
			this.setLoading(false);
			return;
		}

		if ('meta' in response && response.meta !== undefined) {
			this.setCurrentPage(response.meta.current_page ?? 1);
			this.setLastPage(response.meta.last_page ?? 1);
		}

		response.items?.forEach((item: IEmployerItem) => this.setItem(item));

		this.setLoading(false);
	}

	// FETCH ITEM
	async fetchItem(id: string) {
		const response = await EmployerService.fetchEmployer(id);

		if ('item' in response) {
			this.setElement(response.item);
		}

		this.setLoading(false);
	}

	// UPDATE
	async updateEmployerApprovement(id: string, isApproved: boolean | null) {
		const response = await EmployerService.updateEmployerApprovement(id, isApproved);

		if ('errors' in response) {
			throw response;
		}

		if ('item' in response) {
			this.setElement(response.item);
		}
	}

	async updateEmployerARCHK(id: string, is_ARCHK_partner: boolean) {
		const response = await EmployerService.updateEmployerARCHK(id, is_ARCHK_partner);

		if ('errors' in response) {
			throw response;
		}

		if ('item' in response) {
			this.setElement(response.item);
		}
	}

	async updateEmployerKRSO(id: string, isKRSO: boolean) {
		const response = await EmployerService.updateEmployerKRSO(id, isKRSO);

		if ('errors' in response) {
			throw response;
		}

		if ('item' in response) {
			this.setElement(response.item);
		}
	}

	async updateEmployerInvestmentProject(id: string, isInvestmentProject: boolean) {
		const response = await EmployerService.updateEmployerInvestmentProject(id, isInvestmentProject);

		if ('errors' in response) {
			throw response;
		}

		if ('item' in response) {
			this.setElement(response.item);
		}
	}
}

export default EmployerStore;
