import makeRequest from 'API/makeRequest';
import {
	GetStatusCardResponse,
	GetStatusCardsResponse,
	GetStatusesResponse,
} from 'interfaces/api/StatusCardResponse';

class StatusCardService {
	async fetchStatusCards() {
		const response = await makeRequest<GetStatusCardsResponse>({
			url: 'status_cards',
			auth: true,
		});

		return response;
	}

	async fetchStatuses() {
		const response = await makeRequest<GetStatusesResponse>({
			url: 'statuses',
			auth: true,
		});

		return response;
	}

	async fetchStatusCard(id?: string) {
		const response = await makeRequest<GetStatusCardResponse>({
			url: `status_cards/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return response.data;
	}

	async createStatusCard(statusCard: FormData) {
		const response = await makeRequest<GetStatusCardResponse>({
			url: 'status_cards',
			method: 'post',
			auth: true,
			data: statusCard,
		});

		return response;
	}

	async updateStatusCard(statusCard: FormData) {
		const response = await makeRequest<GetStatusCardResponse>({
			url: `status_cards/${statusCard.get('id')}`,
			method: 'post',
			auth: true,
			data: statusCard,
		});

		return response;
	}

	deleteStatusCard(id: number) {
		return makeRequest<void>({
			url: `status_cards/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new StatusCardService();
