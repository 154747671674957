import { TFieldCondition } from 'components/Logic/FieldByType/types';

interface ICheckConditionFieldArgs {
	condition: TFieldCondition;
	formikValues: any;
}

export const checkConditionField = ({
	condition: { name: conditionName, value: conditionValues },
	formikValues,
}: ICheckConditionFieldArgs) => {
	const valueCurrentField = formikValues[conditionName];

	if (!valueCurrentField) return false;

	const formikValuesArray = Array.isArray(valueCurrentField);

	// несколько значений в поле
	if (formikValuesArray) {
		return valueCurrentField?.find((item: any) =>
			typeof item === 'object' && 'value' in item
				? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
				  // @ts-ignore
				  conditionValues?.includes(item?.value)
				: conditionValues?.includes(item),
		);
	}
	// одно значение в поле
	else {
		return typeof valueCurrentField === 'object' && 'value' in valueCurrentField
			? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
			  // @ts-ignore
			  conditionValues?.includes(valueCurrentField?.value)
			: conditionValues?.includes(valueCurrentField);
	}
};
