import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { DeletePopup } from 'components/Logic';
import { Button } from 'components/UI';
import DocumentItem from './DocumentItem';
import DocumentForm from './DocumentForm';

import { TUserDocumentRead } from 'interfaces/entities/IUser';
import { BLockFormProps } from './types';

import styles from './Documents.module.scss';

const DocumentsBlock: FC<BLockFormProps> = observer(({ userId, config }) => {
	const store = useStore();
	const usersStore = store.users;

	const [currentItem, setCurrentItem] = useState<TUserDocumentRead | null>(null);
	const [isCreating, setIsCreating] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);

	const onCloseCreate = () => {
		setIsCreating(false);
	};

	const onCloseModal = () => {
		setIsOpenModal(false);
		setCurrentItem(null);
	};

	const onAdd = () => {
		setIsCreating(true);
	};

	const onDeleteDocument = async () => {
		if (currentItem) await usersStore.removeDocument(currentItem.id.toString(),usersStore.element.id.toString(), currentItem.type);
		setIsOpenModal(false);
	};

	if (config.isHide) return null;

	return (
		<>
			<div className={styles.block}>
				<span className={styles.title}>{config.title}</span>

				{config.documents.length !== 0 && (
					<ul className={styles.list}>
						{config.documents.map((document) => (
							<DocumentItem
								key={document.id}
								userId={userId}
								document={document}
								onDelete={() => {
									setCurrentItem(document);
									setIsOpenModal(true);
								}}
							/>
						))}
					</ul>
				)}

				{isCreating ? (
					<DocumentForm
						type={config.type}
						userId={userId}
						document={null}
						onClose={onCloseCreate}
					/>
				) : (
					!(config.maxNumberElements && config.documents.length >= config.maxNumberElements) && (
						<Button theme='borderless' size='small' onClick={onAdd} className={styles.addDoc}>
							{config.textCreateButton}
						</Button>
					)
				)}
			</div>

			<DeletePopup
				onDeleteItems={onDeleteDocument}
				isOpenPopup={isOpenModal}
				closePopup={onCloseModal}
			/>
		</>
	);
});
export default DocumentsBlock;
