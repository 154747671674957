import makeRequest from 'API/makeRequest';
import {
	GetAdditionalApplicationsResponse,
	GetAdditionalApplicationResponse,
} from 'interfaces/api/AdditionalApplicationResponse';

class AdditionalApplicationService {
	async fetchAdditionalApplications(query: string) {
		const response = await makeRequest<GetAdditionalApplicationsResponse>({
			url: `additional_applications?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.additional_applications,
			meta: response.data.meta,
		};
	}

	async fetchAdditionalApplication(id: string) {
		const response = await makeRequest<GetAdditionalApplicationResponse>({
			url: `additional_applications/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.additional_application,
		};
	}
}

export default new AdditionalApplicationService();
