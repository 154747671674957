/* eslint-disable */
import clsx from "clsx";
import { Button, TextField } from "components/UI";
import React, { useReducer } from "react";
import styles from "../СalculatorBuilder.module.scss";
import { ActionType } from "../СalculatorBuilder";
import EditField from "./EditField";

// const initialState: any = { attributes: []};
function reducer(state: any, action: any): any {
	const { type, payload } = action;
	const { value, label, link } = payload;

	const generateUniqOptionId: any = (fields: any) => {
		const randomNumber = Math.floor(Math.random() * 100);

		const optionsIds = fields.map((field: any) => {
			return field.value;
		});

		if (optionsIds.includes(randomNumber)) {
			return generateUniqOptionId(fields);
		} else {
			return randomNumber;
		}
	};

	switch (type) {
		case ActionType.RESET_ATTRIBUTE:
			return {
				...state,
				attributes: []
			};
		case ActionType.EDIT_TEXT_LABEL:
			return {
				...state,
				title: label
			};
		case ActionType.EDIT_TEXT_OPTION:
			return {
				...state,
				options: state.options.map((option: any) =>
					option.value === value
						? {
							...option,
							label
						}
						: option
				)
			};

		case ActionType.EDIT_LINK_OPTION:
			return {
				...state,
				options: state.options.map((option: any) =>
					option.value === value
						? {
							...option,
							link
						}
						: option
				)
			};
		case ActionType.ADD_OPTION:
			return {
				...state,
				options: [
					...state.options,
					{
						label: "Ответ",
						value: generateUniqOptionId(state.options)
					}
				]
			};
		case ActionType.DELETE_OPTION:
			return {
				...state,
				options: state.options && state.options.filter((item: any) => item.value !== value)
			};
		default:
			console.log("default case");
			return state;
	}
}

const EditQuestion = ({ currentQuestion, dispatchFields, setIsOpen }: any) => {
	const [state, dispatch] = useReducer(reducer, currentQuestion);
	const textFieldClasses = clsx({
		[styles.filterField]: true
		// [styles.filterInactive]: column.name !== filterName && !isMustSelect,
		// [styles.filterSelectInactive]: column.name !== filterName && isMustSelect,
	});

	return (
		<div className={styles.editQuestion}>
			<div className={styles.editTitle}>Редактирование вопроса</div>
			<div className={styles.question}>
				<TextField
					className={textFieldClasses}
					label={"Вопрос"}
					defaultValue={currentQuestion.title}
					onChange={(e) => {
						dispatch({
							type: ActionType.EDIT_TEXT_LABEL,
							payload: {
								label: e.target.value
							}
						});
					}}
				/>
			</div>
			{state.options && (
				<div className={styles.answers}>
					<div className={styles.answerTitle}>Ответ</div>
					{state.options.map((option: any, index: number) => {
						return (
							<EditField
								dispatch={dispatch}
								value={option.value}
								label={option.label}
								link={option.link}
								key={index}
							/>
						);
					})}
					<div
						className={styles.addField}
						onClick={() => {
							dispatch({
								type: ActionType.ADD_OPTION,
								payload: {}
							});
						}}
					>
						Добавить вариант
					</div>
				</div>
			)}
			<div className={styles.btnsEdit}>
				<Button
					onClick={() => {
						dispatchFields({
							type: ActionType.ADD_FIELD,
							payload: {
								value: state,
								position: currentQuestion.position
							}
						});
						setIsOpen(false);
					}}
					theme="filled"
				>
					Сохранить
				</Button>
				<Button onClick={() => setIsOpen(false)} theme="outlined">
					Отменить
				</Button>
			</div>
		</div>
	);
};

export default EditQuestion;
