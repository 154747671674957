import { GetEventResponse, GetEventsResponse } from '../../interfaces/api/EventResponse';

import makeRequest from '../makeRequest';

export class EventService {
	async fetchEvents() {
		const response = await makeRequest<GetEventsResponse>({
			url: 'events',
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.events,
			meta: response.data.meta,
		};
	}

	async fetchEventsOstrova(query: string) {
		const response = await makeRequest<GetEventsResponse>({
			url: `events?is_islands=1${query && `&${query}`}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.events,
			meta: response.data.meta,
		};
	}

	async fetchEvent(id: string) {
		const response = await makeRequest<GetEventResponse>({
			url: `events/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event,
		};
	}

	async createEvent(event: FormData) {
		const response = await makeRequest<GetEventResponse>({
			url: 'events',
			method: 'post',
			auth: true,
			data: event,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event,
		};
	}
	async createEventOstrova(event: FormData) {
		event.set('type', 'Острова');
		const response = await makeRequest<GetEventResponse>({
			url: 'events',
			method: 'post',
			auth: true,
			data: event,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event,
		};
	}

	async updateEvent(event: FormData) {
		const response = await makeRequest<GetEventResponse>({
			url: `events/${event.get('id')}`,
			method: 'post',
			auth: true,
			data: event,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event,
		};
	}

	async updateEventOstrova(event: FormData) {
		event.set('type', 'Острова');
		const response = await makeRequest<GetEventResponse>({
			url: `events/${event.get('id')}`,
			method: 'post',
			auth: true,
			data: event,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			item: response.data.event,
		};
	}

	deleteEvent(id: number) {
		return makeRequest<void>({
			url: `events/${id}`,
			method: 'delete',
			auth: true,
		});
	}
}

export default new EventService();
