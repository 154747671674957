import { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { UpdatePage } from 'components/Layouts';
import { LinkBtn } from 'components/UI';

import { FieldsType } from 'components/Logic/FieldByType/types';

const ReportUpdate: FC = observer(() => {
	const store = useStore();
	const { id } = useParams();
	const item = store.reports.element;

	const pageLinkType =
		item.vacancy !== undefined
			? 'vacancies'
			: item.resume !== undefined
			? 'resumes'
			: item.employer_profile !== undefined
			? 'employers'
			: item.course !== undefined
			? 'courses'
			: null;

	const itemType =
		item.vacancy !== undefined
			? 'vacancy'
			: item.resume !== undefined
			? 'resume'
			: item.employer_profile !== undefined
			? 'employer_profile'
			: item.course !== undefined
			? 'course'
			: null;

	const objectName =
		item.vacancy !== undefined
			? item.vacancy.name
			: item.resume !== undefined
			? item.resume.position
			: item.employer_profile !== undefined
			? item.employer_profile.employer_name
			: item.course !== undefined
			? item.course.name
			: null;

	const fetchData = useCallback(async () => {
		await store.reports.fetchItem(id);
	}, [id]);

	const fields: FieldsType[] = [
		{
			type: 'textfield',
			name: 'id',
			value: item.id?.toString(),
			label: 'ID',
			disabled: true,
		},
		{
			type: 'textfield',
			name: 'type',
			value: item.type,
			label: 'Объект жалобы',
			disabled: true,
		},
		{
			type: 'textfield',
			name: 'objectName',
			value: objectName ?? '',
			label: 'Название обьекта',
			disabled: true,
		},
		{
			type: 'textarea',
			name: 'message',
			value: item.message,
			label: 'Текст жалобы',
			disabled: true,
		},
		{
			type: 'switch',
			name: 'is_reviewed',
			value: item.is_reviewed,
			label: 'Просмотрено',
		},
	];

	const pageLink = '/' + pageLinkType + `/${item?.[itemType ?? 'vacancy']?.id}/update`;

	const additionalButtons = (
		<LinkBtn to={pageLink} rel='noreferrer' target='_blank' theme='outlined'>
			Перейти к объекту
		</LinkBtn>
	);

	return (
		<UpdatePage
			title='Жалобы'
			fields={fields}
			store={store.reports}
			fetchData={fetchData}
			additionalButtons={additionalButtons}
		/>
	);
});

export default ReportUpdate;
