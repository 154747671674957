import { FieldOnChangEventType } from 'components/Layouts/FieldList/types';
import { FieldsType } from 'components/Logic/FieldByType/types';

export const fieldOnChange = (event: FieldOnChangEventType, field: FieldsType, formik: any) => {
	if (
		[
			'phonefield',
			'select',
			'datePicker',
			'file',
			'textFieldWithCrumbs',
			'textFieldWithPrefix',
			'wysiwyg',
			'multiSelect',
			'editor',
		].includes(field.type)
	) {
		formik.setFieldValue(field.name, event);
	} else if (['textfield', 'textarea', 'timeField'].includes(field.type)) {
		if ('target' in event) formik.setFieldValue(field.name, event.target.value);
		else throw new Error('Field not registered');
	} else if (['checkbox', 'switch'].includes(field.type)) {
		if ('target' in event) formik.setFieldValue(field.name, event.target.checked);
		else throw new Error('Field not registered');
	} else {
		throw new Error('Field not registered');
	}
};
