import { CSSProperties } from 'react';
import clsx from 'clsx';

import { EMarkVariant, IMarkProps } from './types';

import styles from './Mark.module.scss';

const Mark = ({ variant, coords }: IMarkProps) => {
	let wrapperStyles: CSSProperties = {};

	if (coords) {
		if (variant === EMarkVariant.BLACK) {
			wrapperStyles = {
				position: 'absolute',
				top: coords.y - 18,
				left: coords.x - 18,
				padding: 10,
				borderRadius: '50%',
				cursor: 'default',
			};
		} else if (variant === EMarkVariant.RED) {
			wrapperStyles = {
				position: 'absolute',
				top: coords.y - 8,
				left: coords.x - 8,
			};
		}
	}

	const markClasses = clsx(styles.mark, {
		[styles.red]: variant === EMarkVariant.RED,
		[styles.black]: variant === EMarkVariant.BLACK,
	});

	return (
		<div style={wrapperStyles} className={styles.wrapper}>
			<div className={markClasses}></div>
		</div>
	);
};

export default Mark;
