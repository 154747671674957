import { makeAutoObservable, toJS } from 'mobx';
import { IStatusCardStore } from './types';
import { StatusCardService } from 'API';
import { IStatusCardRead } from 'interfaces/entities/IStatusCard';

class StatusCardStore implements IStatusCardStore {
	isLoading = false;
	item = {} as IStatusCardRead;
	items = [] as IStatusCardRead[];

	constructor() {
		makeAutoObservable(this);
	}

	get element() {
		return toJS(this.item);
	}

	get list() {
		return toJS(this.items);
	}

	get isEmpty() {
		return this.items.length === 0;
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}
	setItem(item: IStatusCardRead) {
		this.item = item;
	}
	setElement(item: IStatusCardRead) {
		this.items.push(item);
	}

	changeItem(id: number, newItem: IStatusCardRead) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as IStatusCardRead & { id: number };

			return oldItem.id === id;
		});

		this.items[index] = newItem;
	}

	clearItems() {
		this.items = [];
	}

	deleteItem(id: number) {
		const index = this.items.findIndex((item) => {
			const oldItem = item as IStatusCardRead;

			return oldItem.id === id;
		});

		this.items.splice(index, 1);
	}

	// GET

	// ASYNC
	async fetchItems() {
		this.setLoading(true);
		this.clearItems();

		const response = await StatusCardService.fetchStatusCards();

		if ('errors' in response) {
			this.setLoading(false);
			return;
		}

		if ('data' in response) {
			response.data.status_cards.forEach((item: IStatusCardRead) => this.setElement(item));
		}

		this.setLoading(false);
	}

	// FETCH ITEM
	async fetchItem(id?: string) {
		this.setLoading(true);

		const response = await StatusCardService.fetchStatusCard(id);

		if ('status_card' in response) {
			this.setItem(response.status_card);
		}

		this.setLoading(false);
	}

	async createItem(statusCard: FormData) {
		const response = await StatusCardService.createStatusCard(statusCard);
		return response;
	}

	async updateItem(statusCard: FormData) {
		const response = await StatusCardService.updateStatusCard(statusCard);
		return response;
	}

	async removeItem(id: number) {
		const response = await StatusCardService.deleteStatusCard(id);
		if (response.status === 200) {
			this.deleteItem(id);
		}
	}
}

export default StatusCardStore;
