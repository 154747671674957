import { FC } from 'react';

import {
	activeOptions,
	ChildTableHeaderProps,
	TableHeader,
	THeaderField,
} from 'components/Layouts';

import styles from './Spheres.module.scss';

const SpheresTableHeader: FC<ChildTableHeaderProps> = ({ isAllSelected, selectAllElements }) => {
	const tableFields: THeaderField[] = [
		{ label: 'ID', name: 'id', type: 'textField', hasSorting: true },
		{ label: 'Сфера', name: 'name', type: 'textField', hasSorting: true },
		{ label: 'Состояние', name: 'is_active', type: 'select', options: activeOptions },
		{ label: '', name: '', type: null },
	];

	return (
		<TableHeader
			className={styles.row}
			fields={tableFields}
			isAllSelected={isAllSelected}
			selectAllElements={selectAllElements}
		/>
	);
};

export default SpheresTableHeader;
