import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { ListPage } from 'components/Layouts';
import ChampionshipsGroup from './Championship';
import ChampionshipsTableHeader from './ChampionshipsTableHeader';

import styles from './Championships.module.scss';

const Championships: FC = observer(() => {
	const store = useStore();

	return (
		<ListPage
			store={store.championships}
			title='Чемпионаты'
			tableHeader={ChampionshipsTableHeader}
			element={ChampionshipsGroup}
			hasDelete={false}
			tableClassName={styles.table}
		/>
	);
});

export default Championships;
