import PavilionService from 'API/rest/PavilionService';

import { ListStore } from 'store/general/List/lIstStore';
import {IPavilionCreate, IPavilionOstrovaCreate, IPavilionRead} from 'interfaces/entities/IPavilion';

class PavilionOstrovaStore extends ListStore<IPavilionRead, IPavilionOstrovaCreate> {
	constructor() {
		super({
			APIFetchItems: PavilionService.fetchPavilionsOstrova,
			APIFetchItem: PavilionService.fetchPavilion,
			APICreateItem: PavilionService.createPavilionOstrova,
			APIUpdateItem: PavilionService.updatePavilionOstrova,
			APIRemoveItem: PavilionService.deletePavilion,
		});
	}
}

export default PavilionOstrovaStore;
