import makeRequest from 'API/makeRequest';
import { GetCoursesResponse, GetCourseResponse } from 'interfaces/api/CourseResponse';

class CourseService {
	async fetchCourses(query: string) {
		const response = await makeRequest<GetCoursesResponse>({
			url: `courses?${query}`,
			auth: true,
		});

		if ('errors' in response) {
			return response;
		}

		return {
			items: response.data.courses,
			meta: response.data.meta,
		};
	}

	async fetchCourse(id: string) {
		const response = await makeRequest<GetCourseResponse>({
			url: `courses/${id}`,
			auth: true,
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.course,
		};
	}

	async updateCourse(id: string, is_approved: boolean | null) {
		const response = await makeRequest<GetCourseResponse>({
			url: `courses/${id}`,
			method: 'put',
			auth: true,
			data: { is_approved },
		});

		if ('errors' in response) {
			throw response;
		}

		return {
			item: response.data.course,
		};
	}
}

export default new CourseService();
